import { ILineTemplatesTypes } from '@features/odds-templates/api/oddsTemplatesApi.type';
import { ITemplate6SchemaData } from '@features/odds-templates/api/template6.type';
import isNumber from 'lodash.isnumber';

export const TEAM_NAME_KEYS = [ '[:team1]', '[:team2]' ];
export const INVALID_ODD_PLACEHOLDER = '--';

export function formatOdd(value: string = '', interestsValue?: number) {
  let floatOdd = parseFloat(value);
  if (isNaN(floatOdd)) {
    return INVALID_ODD_PLACEHOLDER;
  }
  if (interestsValue && isNumber(interestsValue)) {
    floatOdd = floatOdd + interestsValue;
  }
  if (floatOdd > 1) {
    return floatOdd.toFixed(2);
  }
  return '1.01';
}

export function createTemplateTitle(
  schema: ILineTemplatesTypes,
  opponents: [ string, string ],
  odds?: Record<string, string>,
) {
  let title = schema.title;
  if ('replace' in schema && schema.replace) {
    title = formatOddLabel(title, schema, opponents);
  }
  if (odds) {
    if (
      ('replaceParam' in schema && schema.replaceParam) &&
      ('info' in schema && schema.info)
    ) {
      const keys = title.match(/\[:(.*?)]/g);
      if (keys !== null && keys.length) {
        if (Array.isArray(schema.info.relations) && schema.info.relations) {
          schema.info.relations.forEach((value) => {
            title = title.replace(keys[0], odds[`${schema.info.S}_${value}`]);
          });
        } else {
          title = title.replace(keys[0], odds[`${schema.info.S}_${schema.info.I}`]);
        }
      }
    }
  }
  return title;
}

export function formatOddLabel(string: string, schema: ILineTemplatesTypes | ITemplate6SchemaData, opponents: [ string, string ]) {
  let label = string;
  if ('replace' in schema && schema.replace) {
    const [ host, guest ] = opponents;
    if (label.includes(TEAM_NAME_KEYS[0])) {
      label = label.replace(TEAM_NAME_KEYS[0], host);
    }
    if (label.includes(TEAM_NAME_KEYS[1])) {
      label = label.replace(TEAM_NAME_KEYS[1], guest);
    }
  }
  return label;
}
