import { DialogState } from '@common/components/PopupAlert/index';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import InfoIcon from './icons/InfoIcon';
import SuccessIcon from './icons/SuccessIcon';

interface DialogPopupProps extends DialogState {
  onPopupClose?: () => void;
}

let timeout: NodeJS.Timeout;
const icons = {
  info: <InfoIcon/>,
  success: <SuccessIcon bgColor="#383838"/>,
  warning: '',
};
const StyledDialog = styled(MuiDialog)`
  .MuiPaper-root {
    animation: swal2-show .3s;
  }

  @keyframes swal2-show {
    0% {
      transform: scale(.7);
    }

    45% {
      transform: scale(1.05);
    }

    80% {
      transform: scale(.95);
    }

    100% {
      transform: scale(1);
    }
  }
`;

export default function DialogPopup({
  icon,
  timer,
  title,
  content,
  showCloseButton,
  showConfirmButton,
  onPopupClose,
  onConfirm,
  ...dialogProps
}: DialogPopupProps) {
  const handleClose = useCallback(() => {
    if (dialogProps.onClose) {
      dialogProps.onClose();
    }
    if (onPopupClose) {
      onPopupClose();
    }
  }, [ onPopupClose, dialogProps ]);

  useEffect(() => {
    if (timer) {
      timeout = setTimeout(() => {
        handleClose();
      }, timer);

      return () => clearTimeout(timeout);
    }
  }, [ handleClose, timer ]);

  return (
    <StyledDialog {...dialogProps} onClose={handleClose}>
      {icon && icons[icon]}
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      {(showCloseButton || showConfirmButton) && (
        <DialogActions>
          {showCloseButton && (
            <Button onClick={handleClose}>
              <FormattedMessage id="CLOSE"/>
            </Button>
          )}
          {showConfirmButton && (
            <Button onClick={handleClose}>
              <FormattedMessage id="CONFIRM"/>
            </Button>
          )}
        </DialogActions>
      )}
    </StyledDialog>
  );
}
