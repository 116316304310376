import { useAppSelector } from '@common/hooks/store';
import callAndroidBridgeInterface from '@common/utils/androidBridge';
import { iosBridgeGetAppVersion } from '@common/utils/iosBridge';
import { selectAppPlatform } from '@features/app/slice/appSlice';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

export default function MobileAppVersion() {
  const platform = useAppSelector(selectAppPlatform);
  const [ version, setVersion ] = useState('');

  useEffect(() => {
    if (platform === 'pma') {
      const apkVersion = callAndroidBridgeInterface('appVersion');
      if (apkVersion) {
        setVersion(apkVersion);
      }
    } else if (platform === 'pmi') {
      iosBridgeGetAppVersion((iosVersion) => {
        setVersion(iosVersion);
      });
    }
  }, [ platform ]);

  if (!version) {
    return null;
  }
  return (
    <Typography
      variant="overline"
      padding={1}
      textAlign="right"
      lineHeight="initial"
      textTransform="initial"
      className="mobile-app-version"
    >
      v{version}
    </Typography>
  );
}
