import { CampaignChip } from '@common/components/Sidebar/styled';
import { useAppSelector } from '@common/hooks/store';
import styled from '@emotion/styled';
import { getBonusesCount, getFreeSpinBonusesCount } from '@features/my-bonuses/slice/admiralUserBonusesSlice';
import { getUserFreeBetsCount } from '@features/my-bonuses/slice/userBonusCardsSlice';
import BonusIcon from '@mui/icons-material/Redeem';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const StyledCountBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const MyBonusesMenu = () => {
  const bonusesCount = useAppSelector(getBonusesCount);
  const freeSpinBonusesCount = useAppSelector(getFreeSpinBonusesCount);
  const freeBetsCount = useAppSelector(getUserFreeBetsCount);
  let totalBonusesCount = 0;
  totalBonusesCount += bonusesCount;
  totalBonusesCount += freeSpinBonusesCount;
  totalBonusesCount += freeBetsCount;
  return (
    <MenuItem to="/my-bonuses" component={Link}>
      <ListItemIcon>
        <BonusIcon color="warning"/>
      </ListItemIcon>
      <StyledCountBox>
        <FormattedMessage id="my_bonuses"/>
        <CampaignChip label={totalBonusesCount} color="error"/>
      </StyledCountBox>
    </MenuItem>
  );
};

export default MyBonusesMenu;