type IWindowAndroidBridge = Required<Window>['AndroidBridge']

export default function callAndroidBridgeInterface<
  TInterfaceName extends keyof IWindowAndroidBridge,
  TInterfaceParams extends Parameters<IWindowAndroidBridge[TInterfaceName]>,
  TInterfaceResult extends ReturnType<IWindowAndroidBridge[TInterfaceName]>
>(interfaceName: TInterfaceName, ...interfaceParams: TInterfaceParams): TInterfaceResult | undefined {
  try {
    if (
      window.AndroidBridge &&
      interfaceName in window.AndroidBridge &&
      typeof window.AndroidBridge[interfaceName] === 'function'
    ) {
      // @ts-ignore
      return window.AndroidBridge[interfaceName](...interfaceParams) as TInterfaceResult;
    }
  } catch (e) {
  }
}
