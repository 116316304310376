import app_store_image from '@assets/images/app-store.png';
import { iosBridgeGetAppVersion } from '@common/utils/iosBridge';
import { getCountryCode } from '@common/utils/lang';
import config from '@config';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { compareVersions, validate } from 'compare-versions';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 350px;

    & > .app-store-dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 8px;

      & > .app-store-dialog-content-text {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: initial;
        text-align: center;
      }

      & > a {
        display: block;
        width: 250px;

        & > img {
          width: 100%;
          vertical-align: middle;
        }
      }
    }
  }
`;

export default function CheckerIosVersion() {
  const countryCode = getCountryCode().toLowerCase();
  const [ open, setOpen ] = useState(false);
  const appStoreUrl = config.itunesLinks[countryCode];

  useEffect(() => {
    iosBridgeGetAppVersion((iosVersion) => {
      if (validate(config.iosVersion) && validate(iosVersion)) {
        const compare = compareVersions(config.iosVersion, iosVersion);
        if (compare === 1) {
          setOpen(true);
        }
      }
    });
  }, []);

  return (
    <StyledDialog open={open} className="app-store-dialog">
      <DialogContent className="app-store-dialog-content">
        <Typography className="app-store-dialog-content-text" component="div">
          <FormattedMessage id="typo.new-version-available"/>
        </Typography>
        <a href={appStoreUrl}>
          <img alt="App Store" src={app_store_image}/>
        </a>
      </DialogContent>
    </StyledDialog>
  );
}
