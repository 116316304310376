import Image from '@common/components/Image/Image';
import config from '@config';
import { IDepositCampaigns, IFortuneWheelCampaign } from '@features/freespin/api/freespin.type';
import FreespinCampaignCountdown from '@features/freespin/components/FreespinCampaignCountdown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface IFreespinCampaignPreviewProps {
  campaign: IDepositCampaigns | IFortuneWheelCampaign;
  type: string;
}

const FreespinCampaignPreview: FC<IFreespinCampaignPreviewProps> = ({ campaign, type }) => {
  const navigate = useNavigate();
  const [ disabled, setDisabled ] = useState(false);

  const handleDisabled = useCallback(() => {
    setDisabled(true);
  }, []);

  const handleClickInfo = useCallback(() => {
    navigate(`/admiral/campaigns/${campaign.id}/${type}`);
  }, [ navigate, campaign.id, type ]);
  return (
    <Card className={`admiral-campaign-preview ${disabled && 'disabled'}`} sx={{ py: 3 }}>
      <Box display="flex" justifyContent="center">
        <Image
          alt={campaign.title}
          src={`${config.cdn}/epicboom/${campaign.image}`}
          width="80"
        />
      </Box>
      <CardContent>
        <Typography variant="h5" fontSize="1.2em" fontWeight={700} textAlign="center">
          {campaign.title}
        </Typography>
        <Typography textAlign="center" mt={1} fontSize="0.8em">
          {campaign.brief}
        </Typography>
        <FreespinCampaignCountdown
          date={new Date(campaign.endTime * 1000)}
          handleDisabled={handleDisabled}
        />
      </CardContent>
      <CardActions sx={{ justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={handleClickInfo}
          disabled={disabled}
          className="admiral-campaign-preview-btn"
        >
          <Typography fontSize=".6em" fontWeight={700}>
            <FormattedMessage id="participate"/>
          </Typography>
        </Button>
        <Button
          onClick={handleClickInfo}
          disabled={disabled}
          className="admiral-campaign-preview-btn-info"
          sx={{ minWidth: 'max-content', p: 0 }}
        >
          <InfoOutlinedIcon style={{
            width: '20px',
            height: '20px',
            color: '#d5d5d5',
          }}/>
        </Button>
      </CardActions>
    </Card>
  );
};

export default FreespinCampaignPreview;

