import useBackdrop from '@common/components/backdrop/useBackdrop';
import IsRenderResult from '@common/components/IsRenderResult/IsRenderResult';
import { useGetAdmiralCampaignsQuery } from '@features/freespin/api/freespinApi';
import MyFortuneResults from '@features/my-bonuses/fortune/components/MyFortuneResults';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';

const MyFortune = () => {
  const {
    data: admiralCompaignsData,
    isFetching: isFetchingAdmiralCampaigns,
    isError: isErrorAdmiralCampaigns,
    isLoading: isLoadingAdmiralCampaigns,
    isSuccess: isSuccessAdmiralCampaigns,
    refetch: refetchAdmiralCampaigns,
  } = useGetAdmiralCampaignsQuery();

  const backdrop = useBackdrop();

  useEffect(() => {
    backdrop(isLoadingAdmiralCampaigns);
  }, [ backdrop, isLoadingAdmiralCampaigns ]);

  return (
    <IsRenderResult
      isLoading={isLoadingAdmiralCampaigns}
      isFetching={isFetchingAdmiralCampaigns}
      isSuccess={isSuccessAdmiralCampaigns}
      isError={isErrorAdmiralCampaigns}
      data={admiralCompaignsData}
      onRetry={refetchAdmiralCampaigns}
      renderResult={(res) => (
        <Box>
          {res.fortuneWheelCampaigns && (
            <MyFortuneResults fortuneWheel={res.fortuneWheelCampaigns}
                              status={{ isLoading: isLoadingAdmiralCampaigns }} refetch={refetchAdmiralCampaigns}/>
          )}
        </Box>
      )}/>
  );
};
export default MyFortune;