import { RootState } from '@app/store';
import { IGetAdmiralUserBonuses } from '@features/freespin/api/freespin.type';
import { freespinApi } from '@features/freespin/api/freespinApi';
import { createSlice } from '@reduxjs/toolkit';

interface IAdmiralUserBonusesInitialState {
  data: IGetAdmiralUserBonuses[],
}

const initialState: IAdmiralUserBonusesInitialState = {
  data: [],
};

const admiralUserBonusesSlice = createSlice({
  name: 'admiralUserBonuses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(freespinApi.endpoints.getAdmiralUserBonuses.matchFulfilled, (state, { payload }) => {
        state.data = payload;
      });

  },
});

export const selectFreeSpinBonuses = (state: RootState) => state.admiralUserBonuses.data.filter((item) => item.freeSpinCount === 0);
export const selectFreeSpinActiveBonuses = (state: RootState) => state.admiralUserBonuses.data.filter((item) => item.freeSpinCount !== 0);
export const getBonusBalance = (state: RootState) => state.admiralUserBonuses.data
  .filter((item) => item.freeSpinCount === 0)
  .reduce((total, item) => total + item.balance, 0);
export const getBonusesCount = (state: RootState): number => {
  return state.admiralUserBonuses.data.filter((item) => item.freeSpinCount === 0).length;
};

export const selectAvailableBonus = (state: RootState) => {
  return state.admiralUserBonuses.data.find((item) => item.freeSpinCount === 0 && item.balance > 1);
};
export const selectActiveFreeSpinBonus = (state: RootState) => {
  return state.admiralUserBonuses.data.find((item) => item.freeSpinCount === 0 && item.isPrimary === 1);
};
export const getFreeSpinBonusesCount = (state: RootState): number => {
  return state.admiralUserBonuses.data.filter((item) => item.freeSpinCount !== 0).length;
};
export default admiralUserBonusesSlice.reducer;
