import { RootState } from '@app/store';
import { IDepositCampaigns, IFortuneWheelCampaign } from '@features/freespin/api/freespin.type';
import { freespinApi } from '@features/freespin/api/freespinApi';
import { createSlice } from '@reduxjs/toolkit';

interface IFreespinState {
  data: {
    depositCampaigns?: IDepositCampaigns[],
    fortuneWheelCampaigns?: IFortuneWheelCampaign[],
  };
}

const initialState: IFreespinState = {
  data: {
    depositCampaigns: [],
    fortuneWheelCampaigns: [],
  },
};

const admiralCampaignsSlice = createSlice({
  name: 'admiralCampaigns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(freespinApi.endpoints.getAdmiralCampaigns.matchFulfilled, (state, { payload }) => {
        state.data = {
          depositCampaigns: payload.depositCampaigns,
          fortuneWheelCampaigns: payload.fortuneWheelCampaigns,
        };
      });
  },
});

export const selectAdmiralCampaigns = (state: RootState) => state.admiralCampaigns;
export const selectAdmiralDepositCampaigns = (state: RootState) => ({
  ...state.admiralCampaigns,
  data: state.admiralCampaigns.data.depositCampaigns,
});
export const selectAdmiralFortuneWheelCampaigns = (state: RootState) => ({
  ...state.admiralCampaigns,
  data: state.admiralCampaigns.data.fortuneWheelCampaigns,
});
export const selectAdmiralDepositCampaignById = (id?: string) => (state: RootState) => ({
  ...state.admiralCampaigns,
  data: state.admiralCampaigns.data.depositCampaigns?.find((item) => item.id.toString() === (id?.toString())),
});
export const selectAdmiralFortuneWheelCampaignById = (id?: string) => (state: RootState) => ({
  ...state.admiralCampaigns,
  data: state.admiralCampaigns.data.fortuneWheelCampaigns?.find((item) => item.id.toString() === (id?.toString())),
});
export const getFortuneWheelsCount = (state: RootState) => {
  if (state.admiralCampaigns.data.fortuneWheelCampaigns?.length) {
    return state.admiralCampaigns.data.fortuneWheelCampaigns.reduce((total, item) => {
      total += item.total;
      return total;
    }, 0);
  }
  return 0;
};
export default admiralCampaignsSlice.reducer;
