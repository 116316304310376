import { APP_TRANSLATIONS } from '@common/constants';
import { commonApi } from '@common/utils/baseQuery';
import { decompress } from '@common/utils/pako';
import {
  IStoredTranslations,
  ITranslations,
  ITranslationsParams,
} from '@features/translations/api/translationsApi.type';
import { reactLocalStorage } from 'reactjs-localstorage';

export const translationsApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    fetchTranslations: build.query<ITranslations, ITranslationsParams>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const storedData = reactLocalStorage.getObject(APP_TRANSLATIONS) as IStoredTranslations;
          if (
            storedData &&
            storedData.version === arg.version &&
            storedData.locale === arg.locale &&
            storedData.data
          ) {
            return { data: decompress(storedData.data) };
          }
          const url = `${window.location.origin}/translations/tj_mobile_${arg.locale}.json`;
          const result = await fetchWithBQ(url);
          if (result.data && typeof result.data === 'string') {
            const decompressed = decompress(result.data);
            reactLocalStorage.setObject(APP_TRANSLATIONS, {
              data: result.data,
              locale: arg.locale,
              version: arg.version,
            });
            return { data: decompressed };
          }
        } catch (_e) {
        }
        return {
          error: {
            error: `Failed to fetch tj_mobile_${arg.locale}.json`,
            status: 'FETCH_ERROR',
          },
        };
      },
      extraOptions: { useToken: false },
    }),
  }),
});

export const { useFetchTranslationsQuery } = translationsApi;
