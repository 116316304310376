import { Error } from '@common/components/Form/index.types';
import { ICustomFetchBaseQueryError } from '@common/utils/baseQuery';
import { SerializedError } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';

export const formatFetchBaseQueryError = <T extends unknown = {}>(error: ICustomFetchBaseQueryError | SerializedError) => {
  if ('status' in error) {
    if (error.status === 'VALIDATION_ERROR') {
      if (error.data && !Array.isArray(error.data) && typeof error.data === 'object') {
        const fields = Object.keys(error.data);
        if (fields.length) {
          return fields.map<Error<keyof T>>((field) => [
            field as keyof T,
            Array.isArray(error.data[field])
              ? error.data[field].join('. ')
              : error.data[field].toString(),
          ]);
        }
      }
      if (typeof error.data === 'string') {
        return error.data;
      }
    }
    // TODO: Стандартизировать все ответы от api
    try {
      const errorData = error.data as any;
      if (errorData && errorData?.message) {
        return errorData.message.toString() as string;
      }
    } catch (e) {
    }
    return error.status.toString();
  } else {
    return error.message || error.code || error.name || 'SOMETHING_WRONG';
  }
};

export const formatSubmitError = (error: ICustomFetchBaseQueryError | SerializedError) => {
  if ('status' in error) {
    if (error.status === 'VALIDATION_ERROR') {
      if (error.data !== null && typeof error.data === 'object') {
        return Object.keys(error.data).map((field) => ({
          field,
          message: error.data[field].join('. '),
        }));
      } else {
        return error.data;
      }
    } else {
      return error.status.toString();
    }
  } else {
    return error.message || 'SOMETHING_WRONG';
  }
};

export const formatFieldError = (message: string | { id: string, values: Record<string, string | number> }) => {
  if (typeof message === 'string') {
    return message;
  }
  return <FormattedMessage {...message}/>;
};
