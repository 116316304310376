import { useAppSelector } from '@common/hooks/store';
import config from '@config';
import { useUpdateUserGeolocationMutation } from '@features/auth/api/authApi';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import React, { Fragment, PropsWithChildren, useEffect } from 'react';
import { useCookies } from 'react-cookie';

export default function UserGeolocation(props: PropsWithChildren) {
  const isAuth = useAppSelector(userLoggedIn);
  const user = useAppSelector((state) => state.auth.user);
  const [ cookies, , removeCookie ] = useCookies([ 'country', 'code' ]);
  const [ updateUserGeolocation, { isSuccess } ] = useUpdateUserGeolocationMutation();

  useEffect(() => {
    if (isAuth && user && !isSuccess) {
      const userCountryCode = user.country?.code;
      if (cookies.code && cookies.country && userCountryCode) {
        if (cookies.code !== userCountryCode) {
          const currentCountry = config.gpsAvailableCountries.find((item) => item.code === cookies.code);
          if (currentCountry) {
            const data = {
              id: user.id,
              login: user.login,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              birthDay: user.birthDay,
              phone: user.phone,
              phoneCode: user.phoneCode,
              passportNo: user.passportNo,
              cityId: user.cityId,
              country: user.country,
              gpsCountry: {
                id: currentCountry.id,
                title: currentCountry.title,
                code: currentCountry.code,
              },
              gpsUpdatedAt: Math.floor(Date.now() / 1000),
            };
            updateUserGeolocation(data).unwrap().then(() => {
              removeCookie('country');
              removeCookie('code');
            });
          }
        }
      }
    }
  }, [ cookies.code, cookies.country, isAuth, isSuccess, removeCookie, updateUserGeolocation, user ]);

  return <Fragment>{props.children}</Fragment>;
}
