import { IUserVerificationStatus } from '@features/auth/api/authApi.type';
import { PassportType } from '@features/verification/api/verificationApi.type';
import { IVerificationState, IVerificationStep } from '@features/verification/slice/verificationSlice.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { verificationApi } from '../api/verificationApi';

export const verificationSteps: Record<PassportType, IVerificationStep[]> = {
  [PassportType.ID_CARD]: [
    {
      label: 'ID_CARD_FRONT_SIDE',
      value: 1,
      completed: false,
      fieldName: 'fileName2',
    },
    {
      label: 'ID_CARD_BACK_SIDE',
      value: 2,
      completed: false,
      fieldName: 'fileName3',
    },
    {
      label: 'SELFIE_WITH_ID_CARD',
      value: 3,
      completed: false,
      fieldName: 'fileName1',
    },
  ],
  [PassportType.PAPER]: [
    {
      label: 'PAPER_PASSPORT_FRONT_SIDE',
      value: 1,
      completed: false,
      fieldName: 'fileName2',
    },
    {
      label: 'PAPER_PASSPORT_RESIDENCE_SIDE',
      value: 2,
      completed: false,
      fieldName: 'fileName3',
    },
    {
      label: 'PAPER_PASSPORT_INN_SIDE',
      value: 3,
      completed: false,
      fieldName: 'fileName4',
    },
    {
      label: 'SELFIE_WITH_PAPER_PASSPORT',
      value: 4,
      completed: false,
      fieldName: 'fileName1',
    },
  ],
};
const initialState: IVerificationState = {
  steps: [],
  status: IUserVerificationStatus.UNVERIFIED,
  passportType: null,
};
const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    setPassportType: (state, action: PayloadAction<PassportType | null>) => {
      if (state.passportType !== action.payload) {
        state.steps = action.payload === null ? [] : verificationSteps[action.payload];
        state.status = IUserVerificationStatus.UNVERIFIED;
        state.passportType = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(verificationApi.endpoints.fetchVerificationData.matchFulfilled, (state, { payload }) => {
        if (payload) {
          state.steps = verificationSteps[payload.passportType].map((item) => ({
            ...item,
            completed: !!payload[item.fieldName],
          }));
          state.status = payload.status;
          state.passportType = payload.passportType;
        }
      })
      .addMatcher(verificationApi.endpoints.saveVerifyStep.matchFulfilled, (state, { payload, meta }) => {
        const params = meta.arg.originalArgs;
        state.passportType = params.passportType;
        if (state.passportType !== params.passportType) {
          state.steps = verificationSteps[params.passportType];
        }
        const step = state.steps.find((item) => item.fieldName in params);
        if (step) {
          step.completed = true;
        }
      })
      .addMatcher(verificationApi.endpoints.verifyConfirm.matchFulfilled, (state) => {
        state.status = IUserVerificationStatus.PENDING;
      });
  },
});

export const { setPassportType } = verificationSlice.actions;

export default verificationSlice.reducer;
