import { USER_SESSION_TOKEN } from '@common/constants';
import { useAppDispatch, useAppSelector } from '@common/hooks/store';
import { logout, userLoggedIn } from '@features/auth/slice/authSlice';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import NavigationItems from './NavigationItems';
import { MenuList } from './styled';

export default function NavigationMenu() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(userLoggedIn);
  const handleLogout = () => {
    reactLocalStorage.remove(USER_SESSION_TOKEN);
    dispatch(logout());
    navigate('/');
  };

  const items = NavigationItems();

  return (
    <MenuList>
      {items
        .filter((item) => item.hide !== true)
        .map((item) => (
          <MenuItem to={item.to} key={item.to} component={NavLink}>
            <ListItemIcon>{item.icon} </ListItemIcon>
            <Typography>{item.title}</Typography>
            {item.chip}
          </MenuItem>
        ))}
      {isAuth && (
        <MenuItem onClick={handleLogout} disableTouchRipple disableRipple>
          <ListItemIcon>
            <LogoutIcon color="error"/>
          </ListItemIcon>
          <Typography color="error">
            <FormattedMessage id="SIGNOUT"/>
          </Typography>
        </MenuItem>
      )}
    </MenuList>
  );
}
