import Image from '@common/components/Image/Image';
import styled from '@emotion/styled';
import { MoneyGateBank } from '@features/deposit/screens/money-gate/api/moneyGate.type';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback } from 'react';

interface IMoneyGateBanksProps {
  banks: MoneyGateBank[];
  orderId: string;
  onSelectBank: ({ orderId, bank, type }: { orderId: string, bank: string, type: string }) => void;
  handleChangeActiveStep: ({ step, bank }: { step: number, bank: MoneyGateBank }) => void;
  disabled: boolean;
}

const StyledBankCard = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  border: 2px solid #fee900;
  justify-content: center;
  background: #2e3031;
  padding: 14px 10px;
  border-radius: 14px;
  color: #ffffff;

  &:hover {
    background: #3e3e36;
  }
`;

interface IHandleSelectBank {
  step: number,
  orderId: string,
  bank: MoneyGateBank,
  bankName: string,
  type: string
}

const MoneyGateBanks: FC<IMoneyGateBanksProps> = ({
  banks,
  onSelectBank,
  orderId,
  handleChangeActiveStep,
  disabled,
}) => {

  const handleSelectBank = useCallback(({ step, orderId, bank, bankName, type }: IHandleSelectBank) => {
    handleChangeActiveStep({ step, bank });
    onSelectBank({ orderId, bank: bankName, type });
  }, [ handleChangeActiveStep, onSelectBank ]);

  return (
    <Box mt={5} display="grid"
         gridTemplateColumns={{
           xs: 'repeat(1, 1fr)',
           sm: 'repeat(2, 1fr)',
           md: 'repeat(3, 1fr)',
         }}
         gap={2}
         padding={1.5}>
      {banks.map((bank, index) => (
        <StyledBankCard key={index} onClick={() => handleSelectBank({
          step: 1,
          orderId,
          bank: bank,
          bankName: bank.bank,
          type: bank.paymentType,
        })}>
          <Image alt={bank.bankName} src={`/deposit/money-gate/${bank.bank}.png`} width={80}/>
          <Box>
            <Typography fontSize="1.2em" fontWeight={500} whiteSpace={'pre'}>
              {bank.bankName}
            </Typography>
            <Typography fontSize="0.8em" fontWeight={500} whiteSpace={'pre'}>
              {bank.paymentTypeName}
            </Typography>
          </Box>
        </StyledBankCard>
      ))}
    </Box>
  );
};

export default MoneyGateBanks;