import useBackdrop from '@common/components/backdrop/useBackdrop';
import { useSaveUserDepositLimitsMutation } from '@features/my-limits/api/myLimitsApi';
import { IGetUserDepositLimitsResponse, SaveUserDepositLimitsProps } from '@features/my-limits/api/myLimitsApi.type';
import MyLimitsForm from '@features/my-limits/components/MyLimitsForm';
import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

interface IMyLimitsResultsProps {
  defaultLimits?: IGetUserDepositLimitsResponse[];
  refetch: () => void;
}

const MyLimitsResults: FC<IMyLimitsResultsProps> = ({ defaultLimits, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const backdrop = useBackdrop();
  const [ saveUserDepositLimits, { isLoading } ] = useSaveUserDepositLimitsMutation();

  const handleSubmit = useCallback(
    (values: SaveUserDepositLimitsProps[]) => {
      saveUserDepositLimits(values).unwrap().then(() => {
        enqueueSnackbar(
          <FormattedMessage id="DEPOSIT_LIMIT_SAVES"/>,
          { variant: 'success' },
        );
        refetch();
      }).catch((e) => {
        enqueueSnackbar(e.data, { variant: 'error' });
      });
    },
    [ enqueueSnackbar, refetch, saveUserDepositLimits ],
  );

  useEffect(() => {
    backdrop(isLoading);
  }, [ backdrop, isLoading ]);
  return (
    <Box p={1}>
      <Alert severity="warning">
        <AlertTitle>Важно!</AlertTitle>
        Внесенные изменения не могут быть отменены в течении 24 часов.
      </Alert>

      <MyLimitsForm defaultLimits={defaultLimits} onSubmit={handleSubmit}/>

    </Box>
  );
};

export default MyLimitsResults;