import useBackdrop from '@common/components/backdrop/useBackdrop';
import Headline from '@common/components/headline/Headline';
import { useGetUserDepositLimitsQuery } from '@features/my-limits/api/myLimitsApi';
import MyLimitsResults from '@features/my-limits/components/MyLimitsResults';
import React, { Fragment, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

const MyLimits = () => {
  const backdrop = useBackdrop();
  const { data, isLoading, refetch } = useGetUserDepositLimitsQuery();

  useEffect(() => {
    backdrop(isLoading);
  }, [ backdrop, isLoading ]);

  const handleRefetch = useCallback(() => refetch(), [ refetch ]);

  return (
    <Fragment>

      <Headline
        title={<FormattedMessage id="LIMIT_PLAYER"/>}
      />
      <MyLimitsResults defaultLimits={data} refetch={handleRefetch}/>
    </Fragment>
  );
};

export default MyLimits;