import SidebarMobileAppLinks from '@common/components/mobile-app-links/SidebarMobileAppLinks';
import MobileAppVersion from '@common/components/Sidebar/MobileAppVersion';
import { useAppSelector } from '@common/hooks/store';
import useAppPlatform from '@common/hooks/useAppPlatform';
import { isDomainZone } from '@common/utils/lang';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import VerificationStatus from '@features/verification/VerificationStatus';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import NavigationMenu from './NavigationMenu';
import { SidebarContext } from './SidebarProvider';
import UserNavigation from './UserNavigation';

const drawerWidth = 250;

export default function SidebarContainer() {
  const { isWeb, isMobile } = useAppPlatform();
  const { open, toggleSidebar } = useContext(SidebarContext);
  const isAuth = useAppSelector(userLoggedIn);

  return (
    <SwipeableDrawer
      open={open}
      PaperProps={{
        sx: {
          overflow: 'auto',
          ...(isDomainZone('boxbet') && {
            background: (theme) => theme.palette.midnightBlue?.main,
          }),
        },
      }}
      onOpen={toggleSidebar}
      onClose={toggleSidebar}
      variant="temporary"
    >
      <Box
        flex="1 1 auto"
        width={drawerWidth}
        display="flex"
        onClick={toggleSidebar}
        onKeyDown={toggleSidebar}
        flexDirection="column"
      >
        <Box flex="1 1 auto">
          {isAuth ? (
            <Paper
              square
              className="no-shadow"
              elevation={6}
              sx={{
                ...(isDomainZone('boxbet') && {
                  background: (theme) => theme.palette.midnightBlue?.dark,
                }),
              }}
            >
              <VerificationStatus/>
              <UserNavigation/>
            </Paper>
          ) : (
            <Box padding="10px">
              <Button to="/sign-up" color="warning" variant="contained" component={Link} fullWidth>
                <FormattedMessage id="SIGNUP"/>
              </Button>
            </Box>
          )}
          <NavigationMenu/>
          {isWeb && <SidebarMobileAppLinks/>}
        </Box>
        {isMobile && <MobileAppVersion/>}
      </Box>
    </SwipeableDrawer>
  );
}
