import FormTextField from '@common/components/Form/FormTextField';
import useAppForm from '@common/components/Form/useAppForm';
import { IGetUserDepositLimitsResponse } from '@features/my-limits/api/myLimitsApi.type';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { addHours, addMilliseconds, differenceInHours, differenceInMilliseconds, format, getUnixTime } from 'date-fns';
import { ru } from 'date-fns/locale';
import lodashSortBy from 'lodash/sortBy';
import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

interface IValueState {
  amount: number | string | null;
  disabled: boolean;
  type: number;
  setDate?: number;
}

interface IMyLimitsForm {
  limits: IFormValues[];
}

interface IFormValues {
  amount: string | number | null,
}

interface IMyLimitsFormProps {
  onSubmit: (values: { amount: string | number | null; type: number }[]) => void;
  defaultLimits?: IGetUserDepositLimitsResponse[];

}

const MyLimitsForm: FC<IMyLimitsFormProps> = ({ onSubmit, defaultLimits }) => {
  const [ values, setValues ] = useState<IValueState[]>([
    {
      amount: null,
      disabled: false,
      type: 1,
    },
    {
      amount: null,
      disabled: false,
      type: 2,
    },
    {
      amount: null,
      disabled: false,
      type: 3,
    },
  ]);

  const valueSchema = Yup.object().shape({
    amount: Yup.string().required().nullable(true),
    type: Yup.number().nullable(true),
  });

  const myLimitsSchema = Yup.object({
    limits: Yup.array().of(valueSchema).required(),
  });

  const schema = myLimitsSchema.pick([ 'limits' ]);

  const { control, handleSubmit } = useAppForm<IMyLimitsForm>({
    resolver: yupResolver(schema),
  });

  const getDateDistance = (date?: number): string => {
    if (date) {
      const ms = differenceInMilliseconds(addMilliseconds(new Date(date * 1000), 0), new Date());
      const hours = differenceInHours(new Date(0), new Date(ms));
      const minutes = format(new Date(ms), 'mm', { locale: ru }); // Get minutes from the duration
      return `${hours} часа ${minutes} минуты`;
    }
    return '';

  };

  const submit: SubmitHandler<IMyLimitsForm> = (values) => {
    const limits = values.limits.map((item, index) => ({
      ...item,
      type: index + 1,
    }));

    onSubmit(limits);
  };

  useEffect(() => {
    if (defaultLimits && defaultLimits.length > 0) {
      const now = Date.now();
      const values = lodashSortBy(defaultLimits, [ 'type' ]).map((item) => {
        const setDate = getUnixTime(addHours(new Date(item.setDate * 1000), 24));
        return {
          ...item,
          setDate,
          disabled: (setDate * 1000) > now,
        };
      });
      setValues(values);
    }
  }, [ defaultLimits ]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box mt={5}>
        <Box display="flex" gap={2}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <FormTextField
              name={`limits.${0}.amount`}
              label={<FormattedMessage id="DAY_DEPOSIT_LIMIT"/>}
              control={control}
              disabled={values[0].disabled}
              defaultValue={!values[0].disabled ? values[0].amount : undefined}
              TextFieldProps={{
                InputLabelProps: {
                  style: {
                    transform: values[0].disabled ? 'translate(12px, 7px) scale(0.75)' : '',
                    maxWidth: values[0].disabled ? 'calc(133% - 24px)' : '',
                  },
                },
                InputProps: {
                  value: values[0].disabled ? values[0].amount : undefined,
                  endAdornment: (
                    <InputAdornment position="end">
                      SM
                    </InputAdornment>
                  ),
                },

              }}
            />
          </FormControl>
          {
            (values[0].disabled) && (
              <Typography width="100%">
                <FormattedMessage id="CAN_BE_CHANGED"/>
                {getDateDistance(values[0]?.setDate)}
              </Typography>
            )
          }
        </Box>
        <Box display="flex" gap={2}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <FormTextField
              name={`limits.${1}.amount`}
              label={<FormattedMessage id="WEEK_DEPOSIT_LIMIT"/>}
              control={control}
              disabled={values[1].disabled}
              defaultValue={!values[1].disabled ? values[1].amount : undefined}
              TextFieldProps={{
                InputLabelProps: {
                  style: {
                    transform: values[1].disabled ? 'translate(12px, 7px) scale(0.75)' : '',
                    maxWidth: values[1].disabled ? 'calc(133% - 24px)' : '',
                  },
                },
                InputProps: {
                  value: values[1].disabled ? values[1].amount : undefined,
                  endAdornment: (
                    <InputAdornment position="end">
                      SM
                    </InputAdornment>
                  ),
                },
              }}
            />
          </FormControl>

          {
            (values[1].disabled) && (
              <Typography width="100%">
                <FormattedMessage id="CAN_BE_CHANGED"/>
                {getDateDistance(values[1]?.setDate)}
              </Typography>
            )
          }
        </Box>
        <Box display="flex" gap={2}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <FormTextField
              name={`limits.${2}.amount`}
              label={<FormattedMessage id="MONTH_DEPOSIT_LIMIT"/>}
              control={control}
              disabled={values[2].disabled}
              defaultValue={!values[2].disabled ? values[2].amount : undefined}
              TextFieldProps={{
                InputLabelProps: {
                  style: {
                    transform: values[2].disabled ? 'translate(12px, 7px) scale(0.75)' : '',
                    maxWidth: values[2].disabled ? 'calc(133% - 24px)' : '',
                  },
                },
                InputProps: {
                  value: values[2].disabled ? values[2].amount : undefined,
                  endAdornment: (
                    <InputAdornment position="end">
                      SM
                    </InputAdornment>
                  ),
                },
              }}
            />
          </FormControl>

          {
            (values[2].disabled) && (
              <Typography width="100%">
                <FormattedMessage id="CAN_BE_CHANGED"/>
                {getDateDistance(values[2]?.setDate)}
              </Typography>
            )
          }
        </Box>
        <Button type="submit" variant="contained" fullWidth
                disabled={values.some((item) => item.disabled)}>
          <FormattedMessage id="SAVE"/>
        </Button>
      </Box>
    </form>
  );
};

export default MyLimitsForm;
