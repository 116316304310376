import { oddsTemplatesApi } from '@features/odds-templates/api/oddsTemplatesApi';
import { IOddsSliceState } from '@features/odds-templates/slice/oddsTemplatesSlice.type';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IOddsSliceState = {
  lineAbbrs: {},
  lineTemplates: {},
  liveAbbrs: {},
  liveTemplates: {},
};
const oddsTemplatesSlice = createSlice({
  name: 'oddsSchema',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(oddsTemplatesApi.endpoints.fetchLineOddsAbbrsTemplates.matchFulfilled, (state, { payload }) => {
        state.lineAbbrs = payload;
      })
      .addMatcher(oddsTemplatesApi.endpoints.fetchLineExtraOddsTemplates.matchFulfilled, (state, { payload }) => {
        state.lineTemplates = payload;
      })
      .addMatcher(oddsTemplatesApi.endpoints.fetchLiveOddsAbbrsTemplates.matchFulfilled, (state, { payload }) => {
        state.liveAbbrs = payload;
      })
      .addMatcher(oddsTemplatesApi.endpoints.fetchLiveExtraOddsTemplates.matchFulfilled, (state, { payload }) => {
        state.liveTemplates = payload;
      });
  },
});

export default oddsTemplatesSlice.reducer;
