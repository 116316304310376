export enum BasketStatus {
  EMPTY = 'BASKET_EMPTY',
  CONFIRMED = 'BASKET_CONFIRMED',
  REQUEST = 'BASKET_REQUEST',
  CANCELLED = 'BASKET_CANCELLED',
  UNPROCESSED = 'BASKET_UNPROCESSED',
  INSUFFICIENT_FUNDS = 'BASKET_INSUFFICIENT_FUNDS',
  LINE_CHANGE = 'BASKET_LINE_CHANGE',
  REQUEST_BAN = 'BASKET_REQUEST_BAN',
  EMPTY_PLACE_BET = 'BASKET_EMPTY_PLACE_BET',
  USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
  BONUS_CARD_LOW_RATE = 'BONUS_CARD_LOW_RATE',
  BONUS_MIN_ITEMS = 'BONUS_MIN_ITEMS',
  SUM_CHANGE = 'BASKET_SUM_CHANGE',
  BET_NOT_ALLOWED = 'BASKET_BET_NOT_ALLOWED',
  VALIDATION_BASKET_ADD = 'VALIDATION_BASKET_ADD'
}

export enum BasketBetType {
  EXPRESS = 'express',
  SINGLE = 'single',
}

export interface IBasketItemEvent {
  id: number;
  no: number;
  isLive: number;
  date: number;
  lock: boolean;
  hide: boolean;
  started: boolean;
}

export interface IdName {
  id: number;
  name: string;
}

export interface IBasketItemSport {
  name: string;
  type: string;
}

export interface IBasketItem {
  id: string;
  value: string;
  oldValue?: any;
  sum: number | string;
  outcomeSportId: number;
  outcomeItemId: number;
  event: IBasketItemEvent;
  sport: IBasketItemSport;
  host: IdName;
  guest: IdName;
  championship: IdName;
  description: string;
  infoId: string;
  infoValue: string;
  deleteItem: (id: string | number) => void;
}

export type IBasket = {
  items: IBasketItem[];
  betType: BasketBetType;
  express_available: boolean;
} & Partial<IBasketStatus>

export interface IAddToBasket {
  eventId: number;
  outcomeItemId: number;
  outcomeSportId: number;
  value: string;
  info?: Omit<IAddToBasket, 'eventId' | 'info'>[];
}

export type IPlaceBet = {
  items: Record<string, string | number>
  betType: BasketBetType;
  cardNo?: number;
}

export interface IBasketStatus {
  status: BasketStatus;
  message: string;
}
