import { commonApi } from '@common/utils/baseQuery';
import { IFullMessage, IMessageType, IShortMessage } from '@features/messages/api/messagesApi.type';

export const messagesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchMessages: builder.query<IShortMessage[], void>({
      query: () => '/message',
    }),
    fetchMessageById: builder.query<IFullMessage, { id: number, type: IMessageType }>({
      query: ({ id, type }) => ({
        url: `/message/${id}`,
        params: { type },
      }),
    }),
    fetchMessageCount: builder.query<number, void>({
      query: () => `/counters?messages`,
      transformResponse: (response: { messages: number }) => response?.messages || 0,
    }),
  }),
});

export const {
  useFetchMessagesQuery,
  useFetchMessageByIdQuery,
  useFetchMessageCountQuery,
} = messagesApi;
