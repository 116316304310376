import styled from '@emotion/styled';
import pinImg from './assets/pin.png';

const PinImg = styled('img')`
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
`;

const SpinWheelPin = () => {
  return (
    <PinImg alt="Wheel Pin" src={pinImg}/>
  );
};
export default SpinWheelPin;