import { useAppSelector } from '@common/hooks/store';
import { IUserBonusCard } from '@features/auth/api/authApi.type';
import { selectUserCurrencyCode } from '@features/auth/slice/authSlice';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IMyFreeBetsResults {
  freebets: any;
}

const MyFreeBetsResults: FC<IMyFreeBetsResults> = ({ freebets }) => {
  const currencyCode = useAppSelector(selectUserCurrencyCode);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>
                <FormattedMessage id="TITLE"/>
              </TableCell>
              <TableCell>
                <FormattedMessage id="MIN_ODD_RATE"/>
              </TableCell>
              <TableCell>
                <FormattedMessage id="EXPRESS_MIN_ITEMS"/>
              </TableCell>
              <TableCell>
                <FormattedMessage id="EXPRESS_MIN_ODD_RATE"/>
              </TableCell>
              <TableCell>
                <FormattedMessage id="TR_TYPE_BONUS"/>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {freebets && freebets.map((item: IUserBonusCard) => (
              <TableRow key={item.no}>
                <TableCell className="text-center">{item.no}</TableCell>
                <TableCell className="text-center">{item.title}</TableCell>
                <TableCell className="text-center">{item.minOddRate}</TableCell>
                <TableCell className="text-center">{item.expressMinItems}</TableCell>
                <TableCell className="text-center">{item.expressMinOddRate}</TableCell>
                <TableCell className="text-center">
                  {item.sum} {currencyCode}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default MyFreeBetsResults;