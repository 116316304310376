import useBackdrop from '@common/components/backdrop/useBackdrop';
import IsRenderResult from '@common/components/IsRenderResult/IsRenderResult';
import { useAppSelector } from '@common/hooks/store';
import { useDeleteFreeSpinBonusMutation, useGetAdmiralUserBonusesQuery } from '@features/freespin/api/freespinApi';
import MyFreeSpinsResults from '@features/my-bonuses/freespins/components/MyFreeSpinsResults';
import { selectFreeSpinActiveBonuses } from '@features/my-bonuses/slice/admiralUserBonusesSlice';
import { useCallback, useEffect } from 'react';

const MyFreeSpins = () => {
  const {
    data: admiralUserBonuses,
    isFetching,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchGetAdmiralUserBonuses,
  } = useGetAdmiralUserBonusesQuery();
  const data = useAppSelector(selectFreeSpinActiveBonuses);
  const [ deleteFreeSpinBonus, {
    isLoading: isLoadingDeleteFreeSpinBonus,
  } ] = useDeleteFreeSpinBonusMutation();

  const backdrop = useBackdrop();

  const handleDelete = useCallback((id: number) => {
    deleteFreeSpinBonus({ id })
      .then(async () => {
        await refetchGetAdmiralUserBonuses();
      });
  }, []);
  useEffect(() => {
    backdrop(isLoading || isLoadingDeleteFreeSpinBonus);
  }, [ backdrop, isLoading, isLoadingDeleteFreeSpinBonus ]);
  return (
    <IsRenderResult
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      isError={isError}
      data={admiralUserBonuses}
      onRetry={refetchGetAdmiralUserBonuses}
    >
      {data &&
				<MyFreeSpinsResults freespins={data} handleDelete={handleDelete}/>}
    </IsRenderResult>
  );
};
export default MyFreeSpins;