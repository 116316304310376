import { RootState } from '@app/store';
import { campaignsApi } from '@features/campaigns/api/campaignsApi';
import { CampaignsSliceState } from '@features/campaigns/slice/campaignsSlice.type';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CampaignsSliceState = {};
const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(campaignsApi.endpoints.fetchCampaigns.matchFulfilled, (state, { payload }) => {
        state.data = payload;
      })
      .addMatcher(campaignsApi.endpoints.fetchCampaignsRefill.matchFulfilled, (state, { payload }) => {
        state.data = payload;
      });
  },
});

export const hasCampaign = (state: RootState) => !!state.campaigns?.data?.id;

export default campaignsSlice.reducer;
