import boxbetLogo from '@assets/images/logo/boxbet_logo.webp';
import epicboomLogo from '@assets/images/logo/epicboom_logo.webp';
// import formula55Logo from '@assets/images/logo/formula55_short_logo.png';
import f55Logo from '@assets/images/logo/logo.png';
import { isDomainZone } from '@common/utils/lang';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import React, { memo } from 'react';
import Image from '../Image/Image';

const logo = isDomainZone('ua') ? epicboomLogo : isDomainZone('boxbet') ? boxbetLogo : f55Logo;

export default memo(function SplashScreen() {
  return (
    <Stack
      sx={{ height: '100vh' }}
      alignItems="center"
      direction="row"
      justifyContent="center"
    >
      <Stack
        sx={{ width: isDomainZone('tj') ? 200 : '50%' }}
        spacing={2}
      >
        <Image
          alt="AppOld Logo"
          src={logo}
          style={{ objectFit: 'contain' }}
        />
        <Box>
          <LinearProgress/>
        </Box>
      </Stack>
    </Stack>
  );
});
