import { useAppSelector } from '@common/hooks/store';
import useAppPlatform from '@common/hooks/useAppPlatform';
import { isDomainZone } from '@common/utils/lang';
import config from '@config';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import { hasCampaign } from '@features/campaigns/slice/campaignsSlice';
import { useFetchAllSurveyQuery } from '@features/survey/api/surveyApi';
import SurveyIcon from '@mui/icons-material/Article';
import RegulationIcon from '@mui/icons-material/Assignment';
import ScheduleIcon from '@mui/icons-material/CalendarMonth';
import CampaignIcon from '@mui/icons-material/Campaign';
import CasinoIcon from '@mui/icons-material/Casino';
import CeoMailIcon from '@mui/icons-material/ContactMail';
import SuperExpressIcon from '@mui/icons-material/Explicit';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HomeIcon from '@mui/icons-material/HomeRounded';
import StreamIcon from '@mui/icons-material/LiveTv';
import ResultsIcon from '@mui/icons-material/LocalConvenienceStore';
import TopMatchesIcon from '@mui/icons-material/LocalFireDepartment';
import EventSearchIcon from '@mui/icons-material/ManageSearch';
import NewsIcon from '@mui/icons-material/Newspaper';
import TicketIcon from '@mui/icons-material/Pageview';
import FeedbackIcon from '@mui/icons-material/PhoneInTalk';
import PreviewIcon from '@mui/icons-material/Preview';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import SettingIcon from '@mui/icons-material/Settings';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { reactLocalStorage } from 'reactjs-localstorage';
import { CampaignChip, FlipText } from './styled';

interface NavItem {
  id: number;
  to: string;
  icon: React.ReactElement;
  title: React.ReactElement;
  hide?: boolean;
  chip?: React.ReactElement;
}

const NavigationItems = (): NavItem[] => {
  const { isIos } = useAppPlatform();
  const isAuth = useAppSelector(userLoggedIn);
  const campaign = useAppSelector(hasCampaign);
  const surveyQuery = useFetchAllSurveyQuery(undefined, {
    skip: !isAuth,
    selectFromResult: (res) => ({
      data: res.data?.length,
    }),
  });
  const [ menuIds, setMenuIds ] = useState<number[]>();
  
  useEffect(() => {
    const ids = reactLocalStorage.getObject('f55_navigation_ids', [] as number[]);
    if (Array.isArray(ids)) {
      setMenuIds(ids);
    }
  }, []);
  
  const menus = [
    {
      id: 1,
      to: '/',
      icon: <HomeIcon color="warning"/>,
      title: <FormattedMessage id="MAIN"/>,
    },
    {
      id: 24,
      to: '/admiral/campaigns',
      hide: !isDomainZone('ua'),
      icon: <HolidayVillageIcon color="warning"/>,
      title: <FormattedMessage id="promotions_bonuses"/>,
    },
    {
      id: 2,
      to: '/survey',
      icon: <SurveyIcon color="warning"/>,
      hide: !surveyQuery.data || isDomainZone('ua'),
      chip: <CampaignChip label="NEW" color="error"/>,
      title: <FormattedMessage id="title.survey"/>,
    },
    {
      id: 12,
      to: '/bonus-previews',
      icon: <PreviewIcon color="warning"/>,
      title: <FormattedMessage id="bonus_preview"/>,
    },
    {
      id: 3,
      to: '/campaigns',
      icon: <CampaignIcon color="warning"/>,
      hide: !campaign,
      chip: <CampaignChip label="NEW" color="error"/>,
      title: <FormattedMessage id="CAMPAIGN"/>,
    },
    {
      id: 24,
      to: '/cyberzone',
      icon: <SportsEsportsIcon color="warning"/>,
      hide: !isAuth || !isDomainZone('ua'),
      chip: <CampaignChip label="NEW" color="error"/>,
      title: <Fragment>CYBERZONE</Fragment>,
    },
    {
      id: 25,
      to: '/table-tennis',
      icon: <SportsEsportsIcon color="warning"/>,
      hide: (isIos && !config.iosShowKeno) || !isAuth || !isDomainZone('tj'),
      chip: <CampaignChip label="NEW" color="error"/>,
      title: <Fragment>Table tennis</Fragment>,
    },
    {
      id: 5,
      to: '/admiral',
      icon: <CasinoIcon color="warning"/>,
      hide: !(isDomainZone('boxbet') || isDomainZone('ua')) || (isIos && !config.iosShowKeno) || config.hideCasino,
      title: (
        <FlipText>
          <FormattedMessage id="title.casino"/>
        </FlipText>
      ),
    },
    {
      id: 6,
      to: '/live-schedule',
      icon: <ScheduleIcon color="warning"/>,
      hide: !isAuth,
      title: <FormattedMessage id="SCHEDULE_LIVE"/>,
    },
    {
      id: 7,
      to: '/event-search',
      icon: <EventSearchIcon color="warning"/>,
      hide: !isAuth,
      title: <FormattedMessage id="EVENT_SEARCH"/>,
    },
    {
      id: 8,
      to: '/top-games',
      icon: <TopMatchesIcon color="warning"/>,
      hide: !isAuth,
      title: <FormattedMessage id="TOPMATCHES"/>,
    },
    {
      id: 9,
      to: '/super-express',
      icon: <SuperExpressIcon color="warning"/>,
      hide: !isAuth,
      title: <FormattedMessage id="SUPEREXPRESS"/>,
    },
    {
      id: 10,
      to: '/super-express/mma',
      icon: <SportsMmaIcon color="warning"/>,
      hide: !isAuth,
      chip: <CampaignChip label="NEW" color="error"/>,
      title: <FormattedMessage id="FORECAST"/>,
    },
    {
      id: 11,
      to: '/exact-score',
      icon: <ScoreboardIcon color="warning"/>,
      hide: !isAuth,
      title: <FormattedMessage id="EXACTSCORE"/>,
    },
    {
      id: 13,
      to: '/stream',
      icon: <StreamIcon color="warning"/>,
      title: <FormattedMessage id="STREAM_CHANNELS"/>,
    },
    {
      id: 14,
      to: '/search-ticket',
      icon: <TicketIcon color="warning"/>,
      title: <FormattedMessage id="SEARCH_TICKET"/>,
    },
    {
      id: 15,
      to: '/results',
      icon: <ResultsIcon color="warning"/>,
      title: <FormattedMessage id="RESULTS"/>,
    },
    {
      id: 16,
      to: '/news',
      hide: isDomainZone('ua') || isDomainZone('boxbet'),
      icon: <NewsIcon color="warning"/>,
      title: <FormattedMessage id="NEWS"/>,
    },
    {
      id: 17,
      to: '/pages/become_partner',
      icon: <HandshakeIcon color="warning"/>,
      hide: !isAuth || isDomainZone('ru') || isDomainZone('tj'),
      title: <FormattedMessage id="BECOME_PARTNER"/>,
    },
    {
      id: 18,
      to: '/settings',
      icon: <SettingIcon color="warning"/>,
      title: <FormattedMessage id="SETTINGS"/>,
    },
    {
      id: 20,
      to: '/message-ceo',
      icon: <CeoMailIcon color="warning"/>,
      hide: !isAuth || isDomainZone('ua'),
      title: <FormattedMessage id="WRITE_TO_CEO"/>,
    },
    {
      id: 21,
      to: '/addiction',
      icon: <CeoMailIcon color="warning"/>,
      hide: !isAuth || isDomainZone('ua'),
      title: <FormattedMessage id="ADDICTION"/>,
    },
    {
      id: 22,
      to: '/feedback',
      icon: <FeedbackIcon color="warning"/>,
      title: <FormattedMessage id="FEEDBACK"/>,
    },
    {
      id: 23,
      to: '/regulations',
      icon: <RegulationIcon color="warning"/>,
      title: <FormattedMessage id="REGULATIONS"/>,
    },
  ];
  
  if (menuIds) {
    return menus.sort((a, b) => {
      const indexA = menuIds.findIndex(id => id === a.id);
      const indexB = menuIds.findIndex(id => id === b.id);
      return indexA - indexB;
    });
  } else {
    return menus;
  }
};

export default NavigationItems;