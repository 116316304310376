import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { addHours, fromUnixTime } from 'date-fns';
import React, { FC } from 'react';
import Countdown from 'react-countdown';
import { FormattedMessage } from 'react-intl';

interface IRendererProps {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  completed?: boolean;
  handleDisabled?: () => void;
  isView?: boolean;
}

const renderer = ({ days, hours, minutes, seconds, completed, handleDisabled, isView }: IRendererProps) => {
  if (completed) {
    handleDisabled && handleDisabled();
    return null;
  } else {
    return (
      <Box marginTop={2}>
        <Typography fontWeight={400} fontSize={isView ? '1em' : '0.6em'} textAlign="center" color="#dedede">
          <FormattedMessage id="campaign_end_countdown"/>:
        </Typography>
        <Box display="flex" alignItems="flex-start" justifyContent="center" gap={isView ? 0.2 : 0.2} textAlign="center">
          <Box paddingY={1}>
            <Typography fontSize={isView ? '1.2em' : '0.8em'} fontWeight={700} color="primary">
              {days}
            </Typography>
            <Typography fontSize={isView ? '0.8em' : '0.5em'} fontWeight={500} color="#dedede">
              <FormattedMessage id="DAYS"/>
            </Typography>
          </Box>

          <Typography fontSize={isView ? '1.2em' : '0.8em'} fontWeight={700} pt={0.8} color="primary">
            {':'}
          </Typography>

          <Box paddingY={1}>
            <Typography fontSize={isView ? '1.2em' : '0.8em'} fontWeight={700} color="primary">
              {hours}
            </Typography>
            <Typography fontSize={isView ? '0.8em' : '0.5em'} fontWeight={500} color="#dedede">
              <FormattedMessage id="HOURS"/>
            </Typography>
          </Box>

          <Typography fontSize={isView ? '1.2em' : '0.8em'} fontWeight={700} pt={0.8} color="primary">
            {':'}
          </Typography>

          <Box paddingY={1}>
            <Typography fontSize={isView ? '1.2em' : '0.8em'} fontWeight={700} color="primary">
              {minutes}
            </Typography>
            <Typography fontSize={isView ? '0.8em' : '0.5em'} fontWeight={500} color="#dedede">
              <FormattedMessage id="MINUTES"/>
            </Typography>
          </Box>

          <Typography fontSize={isView ? '1.2em' : '0.8em'} fontWeight={700} pt={0.8} color="primary">
            {':'}
          </Typography>

          <Box paddingY={1}>
            <Typography fontSize={isView ? '1.2em' : '0.8em'} fontWeight={700} color="primary">
              {seconds}
            </Typography>
            <Typography fontSize={isView ? '0.8em' : '0.5em'} fontWeight={500} color="#dedede">
              <FormattedMessage id="SECONDS"/>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
};

interface IMyBonusesCountdownProps {
  date: number;
  handleDisabled?: () => void;
  isView?: boolean;
  wageringTime: number;
}

const MyBonusesCountdown: FC<IMyBonusesCountdownProps> = ({ date, handleDisabled, isView, wageringTime }) => {
  const countDownDate = addHours(fromUnixTime(date), wageringTime);

  return <Countdown date={countDownDate}
                    renderer={(props) => renderer({ ...props, handleDisabled: handleDisabled, isView })}/>;
};

export default MyBonusesCountdown;
