import useBackdrop from '@common/components/backdrop/useBackdrop';
import styled from '@emotion/styled';
import { IFortuneWheelCampaign } from '@features/freespin/api/freespin.type';
import { useGetAdmiralFortuneWheelPrizeMutation } from '@features/freespin/api/freespinApi';
import MyFortunePrizeModal from '@features/my-bonuses/fortune/components/MyFortunePrizeModal';
import MyFortuneWheel from '@features/my-bonuses/fortune/components/MyFortuneWheel';
import Box from '@mui/material/Box';
import { FC, useCallback, useEffect, useState } from 'react';

interface IMyFortuneResults {
  fortuneWheel: IFortuneWheelCampaign[];
  status: {
    isLoading: boolean
  };
  refetch: () => void;
}

const StyledBox = styled(Box)`
  margin-top: 1em;
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  transform: scale(0.6);
  transform-origin: top;
`;
const MyFortuneResults: FC<IMyFortuneResults> = ({ fortuneWheel, status, refetch }) => {
  const [ admiralFortuneWheelPrize, {
    isLoading: isLoadingAdmiralFortuneWheelPrize,
    isSuccess: isSuccessLoadingAdmiralFortuneWheelPrize,
    data: bonusCard,
  } ] = useGetAdmiralFortuneWheelPrizeMutation();
  const backdrop = useBackdrop();

  const [ modal, setModal ] = useState<boolean>(false);
  const [ fortuneProccess, setFortuneProccess ] = useState<boolean>(false);

  const handleCloseModal = useCallback(() => {
    setFortuneProccess(false);
    refetch();
    setModal(false);
  }, [ refetch ]);

  const handleStartSpinning = useCallback((value: IFortuneWheelCampaign) => {
    setFortuneProccess(true);
    admiralFortuneWheelPrize({ id: value.id });
  }, [ admiralFortuneWheelPrize ]);

  const handleStopSpinning = useCallback(() => {
    setFortuneProccess(false);
    setModal(true);
  }, []);

  useEffect(() => {
    backdrop(isLoadingAdmiralFortuneWheelPrize);
  }, [ backdrop, isLoadingAdmiralFortuneWheelPrize ]);

  return (
    <StyledBox>
      <MyFortuneWheel
        data={fortuneWheel}
        mustSpin={fortuneProccess}
        prizeNumber={0}
        onStartSpinning={handleStartSpinning}
        onStopSpinning={handleStopSpinning}
      />
      {(isSuccessLoadingAdmiralFortuneWheelPrize && bonusCard) && (
        <MyFortunePrizeModal
          open={modal}
          data={bonusCard}
          onCloseModal={handleCloseModal}
        />
      )}
    </StyledBox>
  );
};
export default MyFortuneResults;