import { useAppSelector } from '@common/hooks/store';
import styled from '@emotion/styled';

import { IUserVerificationStatus as IVerificationStatus } from '@features/auth/api/authApi.type';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import VerifiedIcon from '@mui/icons-material/Verified';
import Box from '@mui/material/Box';
import Chip, { ChipProps } from '@mui/material/Chip';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const StyledChip = styled(Chip)`
  width: 100%;
  justify-content: left;
  border-radius: 4px;

  &.MuiChip-colorError {
    animation: pulse 2s infinite;
  }

  & > .MuiSvgIcon-root {
    margin-left: 8px;
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
      box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
      box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
      box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
    }
  }
`;
const chipProps: Record<IVerificationStatus, Pick<ChipProps, 'icon' | 'label' | 'color'>> = {
  [IVerificationStatus.UNVERIFIED]: {
    icon: <ErrorOutlineIcon/>,
    label: <FormattedMessage id="UNVERIFIED"/>,
    color: 'error',
  },
  [IVerificationStatus.PENDING]: {
    icon: <QueryBuilderIcon/>,
    label: <FormattedMessage id="VERIFICATION_PENDING"/>,
    color: 'info',
  },
  [IVerificationStatus.VERIFIED]: {
    icon: <VerifiedIcon/>,
    label: <FormattedMessage id="VERIFIED"/>,
    color: 'success',
  },
  [IVerificationStatus.REJECTED]: {
    icon: <ErrorOutlineIcon/>,
    label: <FormattedMessage id="VERIFICATION_REJECTED"/>,
    color: 'error',
  },
};

export default function VerificationStatus() {
  const navigate = useNavigate();
  const loggedIn = useAppSelector(userLoggedIn);
  const status = useAppSelector(({ auth }) => auth.user?.verifyStatus);
  const handleClick = () => {
    navigate('/verification');
  };

  if (!loggedIn || status === undefined || !chipProps[status]) {
    return null;
  }
  return (
    <Box p={1} pb={0}>
      <StyledChip
        {...chipProps[status]}
        {...(status === IVerificationStatus.UNVERIFIED || status === IVerificationStatus.REJECTED) && ({
          clickable: true,
          onClick: handleClick,
        })}
      />
    </Box>
  );
}
