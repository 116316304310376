import { TransitionProps } from '@mui/material/transitions';
import Zoom from '@mui/material/Zoom';
import React, { forwardRef, ReactElement } from 'react';

const TransitionZoom = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Zoom ref={ref} {...props}>
      {props.children}
    </Zoom>
  );
});

export default TransitionZoom;
