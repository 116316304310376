import styled from '@emotion/styled';
import WalletIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { StepIconProps } from '@mui/material';
import Box from '@mui/material/Box';

const ColorlibStepIconRoot = styled(Box)<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(238 242 33) 0%, rgb(233 208 64) 50%, rgb(138 107 35) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(238 242 33) 0%, rgb(233 208 64) 50%, rgb(138 107 35) 100%)',
  }),
}));
const MoneyGateStepperIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <WalletIcon/>,
    2: <ChangeCircleIcon/>,
    3: <CheckBoxIcon/>,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};
export default MoneyGateStepperIcon;