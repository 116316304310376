import Image from '@common/components/Image/Image';
import styled from '@emotion/styled';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import copy from 'copy-to-clipboard';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const MoneyGateRequisitesContainer = styled(Box)`
  padding: 20px;
  width: 100%;
  margin-top: 2em;

  .content {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    .left {
      .header {
        font-family: RobotoCondensedBold, serif;
        text-align: center;

        h1 {
          margin: 0;
          font-size: 2em;
        }

        p {
          margin: 0;
          font-size: 18px;
          font-weight: bold;
        }
      }

      div.content {
        display: flex;
      }
    }

    div.right {
      margin-top: 2em;
    }
  }

  div.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5em;
    gap: 1em;
    margin-inline: 1.2em;
    margin-bottom: 1em;
  }
`;
const CreditCard = styled(Box)`
  position: relative;

  &::before {
    content: ' ';
    display: block;
    height: 80%;
    width: 100%;
    position: absolute;
    bottom: -5px;
    border-radius: 18px;
    background-color: #22272a;
  }

  div.form {
    position: relative;
    width: 280px;
    margin: 10px 15px;
    padding: 20px 15px;
    border-radius: 18px;
    background-color: #222b34;

    p {
      font-weight: 400;
      padding-bottom: 0.4em;

    }

    div.requisites {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;
      padding: 10px;
      font-size: 1em;
      font-weight: bold;
      border-radius: 5px;
      background-color: #22272a;
    }
  }
`;

const StyledTypography = styled(Typography)`
  text-align: center;
  font-size: 1.1em;
  margin-top: 2em;
  text-decoration: underline;
`;

interface IMoneyGateRequisitesProps {
  data: any;
  handleOnConfirm: () => void;
}

const MoneyGateRequisites: FC<IMoneyGateRequisitesProps> = ({ data, handleOnConfirm }) => {
  const navigate = useNavigate();
  const [ isCopy, setIsCopy ] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    navigate('/deposit');
  }, [ navigate ]);

  const handleCopyAmount = useCallback(() => {
    if (data.requisites.cardInfo) {
      copy(data.requisites.cardInfo);
      setIsCopy(true);
      setTimeout(() => {
        setIsCopy(false);
      }, 3500);
    }
  }, [ data ]);

  const handleCopyPhone = useCallback(() => {
    if (data.requisites.phone) {
      copy(data.requisites.phone);
      setIsCopy(true);
      setTimeout(() => {
        setIsCopy(false);
      }, 3500);
    }
  }, [ data ]);

  if (!data) return null;
  return (
    <MoneyGateRequisitesContainer>
      <Box className="content">
        <Box className="left">
          <Box className="header">
            <Typography variant="h1" className="brand-color">
              <FormattedMessage id="DEPOSIT"/>
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1.5}>
              {data.requisites && (
                <Fragment>
                  {data.requisites.cardInfo ?
                    <Typography variant="body1">На карту</Typography> :
                    <Typography variant="body1">Перевод по номеру</Typography>
                  }
                  <Image
                    src={`/deposit/money-gate/${data.requisites.bank}.png`}
                    alt={data.requisites.bank}
                    height={60}
                  />
                </Fragment>
              )}
            </Box>
          </Box>
          <Box className="content mt-4">
            <CreditCard>
              <Box className="form">
                {data.requisites && (
                  data.requisites.cardInfo ? (

                      <Fragment>
                        <Typography variant="body1">
                          Переведите {data.amount} {data.currency} с приложения <br/> Вашего банка по реквизитам:
                        </Typography>

                        <Box className="requisites">
                          <Box>
                            <Typography variant="body1" color="primary" fontSize="1em">
                              {data.requisites.cardInfo && data.requisites.cardInfo.match(/.{1,4}/g).join(' ')}
                            </Typography>
                            <Typography variant="body1" fontSize="0.8em">
                              {data.requisites.cardholder && data.requisites.cardholder}
                            </Typography>
                          </Box>

                          <Box display="flex" alignItems="center">
                            {isCopy ? (
                              <IconButton
                                sx={{ color: 'green', paddingRight: 0 }}>
                                <CheckCircle fontSize="small"/>
                              </IconButton>
                            ) : (
                              <IconButton
                                sx={{ color: 'green', paddingRight: 0 }}
                                onClick={handleCopyAmount}>
                                <ContentCopy fontSize="small"/>
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Fragment>
                    ) :
                    (
                      <Fragment>
                        <Typography variant="body1">
                          Переведите {data.amount} {data.currency} с приложения <br/> Вашего банка на номер телефона:
                        </Typography>

                        <Box className="requisites">
                          <Box>
                            <Typography variant="body1" color="primary" fontSize="1em">
                              {data.requisites.phone && data.requisites.phone}
                            </Typography>
                            <Typography variant="body1" fontSize="0.8em">
                              {data.requisites.cardholder && data.requisites.cardholder}
                            </Typography>
                          </Box>

                          <Box display="flex" alignItems="center">
                            {isCopy ? (
                              <IconButton
                                sx={{ color: 'green', paddingRight: 0 }}>
                                <CheckCircle fontSize="small"/>
                              </IconButton>
                            ) : (
                              <IconButton
                                sx={{ color: 'green', paddingRight: 0 }}
                                onClick={handleCopyPhone}>
                                <ContentCopy fontSize="small"/>
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Fragment>
                    )

                )}
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Typography variant="body1" color="error">
                    Перевод строго на СБЕРБАНК, иначе средства будут утеряны
                  </Typography>
                  <Typography variant="body1">Скопируйте реквизиты</Typography>
                  <Typography>Сделайте перевод ровно{'\u00A0'}
                    <Typography variant="overline" color="primary">
                      {data.amount} {data.currency}
                    </Typography>
                  </Typography>
                  <Typography variant="body1">Нажмите на кнопку
                    <Typography variant="overline" textTransform="uppercase" fontWeight={700}> Перевел</Typography>
                  </Typography>
                </Box>
              </Box>
              <Box className="actions">
                <Button type="button" variant="contained" className="btn" color="error" onClick={handleCancel}
                        fullWidth>
                  <FormattedMessage id="CANCEL"/>
                </Button>
                <Button type="button" variant="contained" className="btn" color="primary" onClick={handleOnConfirm}
                        fullWidth>
                  Перевел
                </Button>
              </Box>
            </CreditCard>
          </Box>
        </Box>
        <Box>
          <a href="https://www.youtube.com/watch?v=VY5f3CjwHr4" target="_blank" rel="noopener noreferrer">
            <StyledTypography color="error">
              Как пополнить баланс ?
            </StyledTypography>
          </a>
        </Box>
      </Box>

    </MoneyGateRequisitesContainer>
  );
};

export default MoneyGateRequisites;