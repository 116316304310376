import styled from '@emotion/styled';

export const IconContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto 0.6em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
`;
