import useAuthDialog from '@common/components/auth-dialog/useAuthDialog';
import useBackdrop from '@common/components/backdrop/useBackdrop';
import { USER_SESSION_TOKEN } from '@common/constants';
import { useAppSelector } from '@common/hooks/store';
import config from '@config';
import { IAdmiralGameProps } from '@features/admiral/api/admiralApi.type';
import AdmiralGameBonuses from '@features/admiral/components/games/AdmiralGameBonuses';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import { IGetAdmiralUserBonuses } from '@features/freespin/api/freespin.type';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

const AdmiralGameIframe = () => {
  const [ frameKey, setFrameKey ] = useState<number>(Date.now());
  const backdrop = useBackdrop();
  const { game_id } = useParams<{ game_id: string }>();
  const [ gamesParams ] = useSearchParams();
  const admiralUserBonuses = useAppSelector((state) => state.admiralUserBonuses.data);
  const [ bonusCard, setBonusCard ] = useState<IGetAdmiralUserBonuses>();
  const isAuth = useAppSelector(userLoggedIn);
  const openAuthDialog = useAuthDialog();
  const { enqueueSnackbar } = useSnackbar();
  const [ game, setGame ] = useState<IAdmiralGameProps>();

  const refreshIframe = useCallback(() => {
    setFrameKey(Date.now());
  }, []);
  const handleOpenGame = useCallback((game_id: string) => {

    backdrop(true);
    const gameId = parseInt(game_id);
    const authToken = reactLocalStorage.get<string>(USER_SESSION_TOKEN);
    fetch(`${config?.admiral.host}/game`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ gameId: gameId, exitUrl: window.location.origin }),
    }).then(async (res) => {
      const data = await res.json() as { game: IAdmiralGameProps };
      setGame(data.game);
    }).catch(() => {
      enqueueSnackbar(<FormattedMessage id="error.request-failed"/>, { variant: 'error' });
    }).finally(() => {
      backdrop(false);
    });

    return () => {
      const btnBalance = document.getElementById('btn-balance');
      if (btnBalance !== null) {
        btnBalance.style.display = 'block';
      }
    };
  }, [ backdrop, enqueueSnackbar ]);

  useEffect(() => {
    if (!isAuth) {
      openAuthDialog(true);
    } else {
      const btnBalance = document.getElementById('btn-balance');
      if (btnBalance !== null) {
        btnBalance.style.display = 'none';
      }
    }
    if (game_id) {
      handleOpenGame(game_id);
    }
  }, [ game_id, handleOpenGame, isAuth, openAuthDialog ]);

  useEffect(() => {
    if (window.AndroidBridge) {
      window.AndroidBridge.layoutRefreshCommand(1);
    }
  }, []);

  useEffect(() => {
    if (gamesParams.has('bonusCard')) {
      const bonusCardId = gamesParams.get('bonusCard');
      const card = admiralUserBonuses.find((item) => item.id.toString() === bonusCardId);
      if (card) {
        setBonusCard(card);
      }
    }
  }, [ admiralUserBonuses, gamesParams ]);

  if (!game) {
    return null;
  }
  return (
    <Fragment>
      <Box bgcolor="black" height={'100vh'}>
        <iframe
          key={frameKey}
          src={game.url}
          title="sgames"
          width="100%"
          height="100%"
          allow="autoplay"
          style={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            border: '0',
          }}
        />
      </Box>
      {(game?.bonus === '1' && !bonusCard) && <AdmiralGameBonuses onReload={refreshIframe}/>}
    </Fragment>
  );
};
export default AdmiralGameIframe;