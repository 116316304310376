import { IUserVerificationStatus } from '@features/auth/api/authApi.type';

export enum PassportType {ID_CARD = 1, PAPER = 2}

export interface IVerificationData {
  id: number;
  userId: number;
  status: IUserVerificationStatus;
  passportType: PassportType;
  fileName1: string | null;
  fileName2: string | null;
  fileName3: string | null;
  fileName4: string | null;
  staffId: number | null;
  createdAt: number | null;
  updatedAt: number | null;
  deletedAt: number | null;
}

export type ISaveVerifyStep = {
  fileName1: string;
  passportType: PassportType;
} | {
  fileName2: string;
  passportType: PassportType;
} | {
  fileName3: string;
  passportType: PassportType;
} | {
  fileName4: string;
  passportType: PassportType.PAPER;
}

export interface IUploadIdentityDocument {
  files: {
    key: string;
    error: number;
    fileName: string;
  };
}