import { commonApi } from '@common/utils/baseQuery';
import { ILiveEvent, ILiveEventsResponse } from '@features/live/api/liveApi.type';

export const liveApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchLiveShorts: builder.query<ILiveEvent[], void>({
      query: () => '/lives/mainlines?short',
      extraOptions: { useToken: false },
    }),
    fetchLiveEvents: builder.query<ILiveEventsResponse, void>({
      query: () => '/lives/mainlines',
      extraOptions: { useToken: false },
    }),
    fetchLiveEventById: builder.query<ILiveEvent, number>({
      query: (eventId) => `/lives/${eventId}/mainlines&additional`,
      extraOptions: { useToken: false },
    }),
  }),
});

export const { useFetchLiveEventsQuery, useFetchLiveShortsQuery } = liveApi;
