import { logout } from '@features/auth/slice/authSlice';
import { messagesApi } from '@features/messages/api/messagesApi';
import { IShortMessage } from '@features/messages/api/messagesApi.type';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IShortMessage[] = [];
const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => {
        return [];
      })
      .addMatcher(messagesApi.endpoints.fetchMessages.matchFulfilled, (state, { payload }) => {
        return payload;
      })
      .addMatcher(messagesApi.endpoints.fetchMessageById.matchFulfilled, (state, { payload }) => {
        const index = state.findIndex((message) => message.id === payload.id);
        if (index !== -1) {
          state[index].body = payload.body;
          state[index].isRead = 1;
          state[index].sender = payload.sender;
          state[index].subject = payload.subject;
        }
      });
  },
});

export default messagesSlice.reducer;
