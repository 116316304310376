import useBackdrop from '@common/components/backdrop/useBackdrop';
import { useAppDispatch } from '@common/hooks/store';
import { IAdmiralGame } from '@features/admiral/api/admiralApi.type';
import { FreeSpinGame, IGetAdmiralUserBonuses } from '@features/freespin/api/freespin.type';
import { freespinApi } from '@features/freespin/api/freespinApi';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface MyFreeSpinGamesModalProps {
  data: {
    bonusData: IGetAdmiralUserBonuses,
    games: FreeSpinGame[];
  };
  open: boolean;
  onClose: () => void;
}

const MyFreeSpinGamesModal: React.FC<MyFreeSpinGamesModalProps> = ({ data, open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const backdrop = useBackdrop();
  const handleOpenGame = useCallback((game: IAdmiralGame) => {
    if (data.bonusData.isPrimary) {
      navigate(`/admiral/game/${game.id}?bonusCard=${data.bonusData.id}`);
    } else {
      backdrop(true);
      dispatch(freespinApi.endpoints.toggleUserBalance.initiate({
        type: 'bonus',
        id: data.bonusData.id,
      })).then(() => {
        backdrop(false);
        navigate(`/admiral/game/${game.id}?bonusCard=${data.bonusData.id}`);
      }).catch(() => {
        backdrop(false);
      });
    }
  }, [ backdrop, data.bonusData.id, data.bonusData.isPrimary, dispatch, navigate ]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <FormattedMessage id="GAME_LIST"/>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon/>
      </IconButton>
      <DialogContent dividers sx={{ paddingX: 2 }}>
        <Box
          gap={1}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(4, 1fr)',
            lg: 'repeat(6, 1fr)',
          }}
        >
          {data.games.map((game) => (
            <Card>
              <CardActionArea onClick={() => handleOpenGame(game)} disableRipple>
                <CardMedia
                  sx={{ objectFit: 'contain' }}
                  alt={game.name}
                  image={game.img}
                  component="img"
                />
                <Typography
                  px={2}
                  pt={1}

                  textAlign="center"
                  component="p"

                  gutterBottom
                >
                  {game.name}
                </Typography>
              </CardActionArea>
            </Card>
          ))}

        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MyFreeSpinGamesModal;
