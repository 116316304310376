import ClickableHeadline from '@common/components/headline/ClickableHeadline';
import MoneyGateResults from '@features/deposit/screens/money-gate/components/MoneyGateResults';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

const MoneyGate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handlePrevious = () => {
    navigate(-1);
  };

  if (!id) return null;

  return (
    <Fragment>
      <ClickableHeadline onClick={handlePrevious}>
        <FormattedMessage id="money_gate_headline" defaultMessage="ПОПОЛНЕНИЕ СЧЕТА: MONEY GATE"/>
      </ClickableHeadline>

      <MoneyGateResults orderId={id}/>
    </Fragment>
  );

};

export default MoneyGate;