import { APP_LINE_ABBRS, APP_LINE_TEMPLATES, APP_LIVE_ABBRS, APP_LIVE_TEMPLATES } from '@common/constants';
import { commonApi } from '@common/utils/baseQuery';
import { decompress } from '@common/utils/pako';
import {
  IExtraOddsTemplates,
  ILineAbbrsTemplates,
  ILiveAbbrsTemplates,
  IOddsTemplatesParams,
  IStoredOddsTemplates,
} from '@features/odds-templates/api/oddsTemplatesApi.type';
import { reactLocalStorage } from 'reactjs-localstorage';

export const oddsTemplatesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    fetchLineOddsAbbrsTemplates: build.query<ILineAbbrsTemplates, IOddsTemplatesParams>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const storedData = reactLocalStorage.getObject(APP_LINE_ABBRS) as IStoredOddsTemplates;
          if (storedData && storedData.version === arg.version && storedData.data) {
            return { data: decompress(storedData.data) };
          }
          const url = `${window.location.origin}/templates/prematch_abbrs_mobile_${arg.locale}.json`;
          const result = await fetchWithBQ(url);
          if (result.data && typeof result.data === 'string') {
            const decompressed = decompress(result.data);
            reactLocalStorage.setObject(APP_LINE_ABBRS, {
              data: result.data,
              version: arg.version,
            });
            return { data: decompressed };
          }
        } catch (_e) {
        }
        return {
          error: {
            error: `Failed to fetch prematch_abbrs_mobile_${arg.locale}.json`,
            status: 'FETCH_ERROR',
          },
        };
      },
      extraOptions: { useToken: false },
    }),
    fetchLineExtraOddsTemplates: build.query<IExtraOddsTemplates, IOddsTemplatesParams>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const storedData = reactLocalStorage.getObject(APP_LINE_TEMPLATES) as IStoredOddsTemplates;
          if (storedData && storedData.version === arg.version && storedData.data) {
            return { data: decompress(storedData.data) };
          }
          const url = `${window.location.origin}/templates/prematch_mobile_${arg.locale}.json`;
          const result = await fetchWithBQ(url);
          if (result.data && typeof result.data === 'string') {
            const decompressed = decompress(result.data);
            reactLocalStorage.setObject(APP_LINE_TEMPLATES, {
              data: result.data,
              version: arg.version,
            });
            return { data: decompressed };
          }
        } catch (_e) {
        }
        return {
          error: {
            error: `Failed to fetch prematch_mobile_${arg.locale}.json`,
            status: 'FETCH_ERROR',
          },
        };
      },
      extraOptions: { useToken: false },
    }),
    fetchLiveOddsAbbrsTemplates: build.query<ILiveAbbrsTemplates, IOddsTemplatesParams>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const storedData = reactLocalStorage.getObject(APP_LIVE_ABBRS) as IStoredOddsTemplates;
          if (storedData && storedData.version === arg.version && storedData.data) {
            return { data: decompress(storedData.data) };
          }
          const url = `${window.location.origin}/templates/live_abbrs_mobile_${arg.locale}.json`;
          const result = await fetchWithBQ(url);
          if (result.data && typeof result.data === 'string') {
            const decompressed = decompress(result.data);
            reactLocalStorage.setObject(APP_LIVE_ABBRS, {
              data: result.data,
              version: arg.version,
            });
            return { data: decompressed };
          }
        } catch (_e) {
        }
        return {
          error: {
            error: `Failed to fetch live_abbrs_mobile_${arg.locale}.json`,
            status: 'FETCH_ERROR',
          },
        };
      },
      extraOptions: { useToken: false },
    }),
    fetchLiveExtraOddsTemplates: build.query<IExtraOddsTemplates, IOddsTemplatesParams>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const storedData = reactLocalStorage.getObject(APP_LIVE_TEMPLATES) as IStoredOddsTemplates;
          if (storedData && storedData.version === arg.version && storedData.data) {
            return { data: decompress(storedData.data) };
          }
          const url = `${window.location.origin}/templates/live_mobile_${arg.locale}.json`;
          const result = await fetchWithBQ(url);
          if (result.data && typeof result.data === 'string') {
            const decompressed = decompress(result.data);
            reactLocalStorage.setObject(APP_LIVE_TEMPLATES, {
              data: result.data,
              version: arg.version,
            });
            return { data: decompressed };
          }
        } catch (_e) {
        }
        return {
          error: {
            error: `Failed to fetch live_mobile_${arg.locale}.json`,
            status: 'FETCH_ERROR',
          },
        };
      },
      extraOptions: { useToken: false },
    }),
  }),
});

export const {
  useFetchLineOddsAbbrsTemplatesQuery,
  useFetchLineExtraOddsTemplatesQuery,
  useFetchLiveOddsAbbrsTemplatesQuery,
  useFetchLiveExtraOddsTemplatesQuery,
} = oddsTemplatesApi;
