import { wheelColors } from '@common/components/SpinWheel/Wheel';
import styled from '@emotion/styled';
import { IFortuneWheelCampaign } from '@features/freespin/api/freespin.type';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

interface FortuneWheelSelectorProps {
  data: IFortuneWheelCampaign[];
  value: number;
  disabled?: boolean;
  onChange: (data: IFortuneWheelCampaign) => void;
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0 40px;
  transform: scale(1.3);

  & > button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    padding: 0 2px 0 20px;
    color: #dedede;
    border-radius: 19px;
    border: 2px solid #ff7f24;
    cursor: pointer;
    background: transparent;
    font-weight: 700;
    text-transform: uppercase;

    & > .MuiTypography-root {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      color: #0b0b0b;
      border-radius: 15px;
      background: #ff7f24;
    }

    &:hover,
    &.selected {
      color: #1b1c1d;
      background: #ff7f24;

      & > .MuiTypography-root {
        color: #dedede;
        background: #1b1c1d !important;
      }
    }

    &.gold {
      border-color: #ffc501;

      & > .MuiTypography-root {
        background: #ffc501;
      }

      &:hover,
      &.selected {
        background: #ffc501;
      }
    }

    &.platinum {
      border-color: #a4a6a8;

      & > .MuiTypography-root {
        background: #a4a6a8;
      }

      &:hover,
      &.selected {
        background: #a4a6a8;
      }
    }
  }
`;

const MyFortuneWheelSelector: React.FC<FortuneWheelSelectorProps> = ({
  data = [],
  value,
  disabled = false,
  onChange,
}) => {
  const handleClick = (selectedData: IFortuneWheelCampaign) => () => {
    onChange(selectedData);
  };

  return (
    <StyledContainer>
      {data.map((item) => {
        const className = [ wheelColors[item.type] ]; // Provide a fallback value if undefined
        if (value === item.id) {
          className.push('selected');
        }
        return (
          <Button
            key={item.id}
            onClick={handleClick(item)}
            disabled={disabled}
            className={className.join(' ')}
          >
            {item.title}
            <Typography variant="overline">{item.total}</Typography>
          </Button>
        );
      })}
    </StyledContainer>
  );
};

export default MyFortuneWheelSelector;
