import useBackdrop from '@common/components/backdrop/useBackdrop';
import { FreeSpinGame, IGetAdmiralUserBonuses } from '@features/freespin/api/freespin.type';
import { useToggleUserBalanceMutation } from '@features/freespin/api/freespinApi';
import MyFreeSpinCard from '@features/my-bonuses/freespins/components/MyFreeSpinCard';
import MyFreeSpinGamesModal from '@features/my-bonuses/freespins/components/MyFreeSpinGamesModal';
import Box from '@mui/material/Box';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IMyFreeSpinsResults {
  freespins: IGetAdmiralUserBonuses[];
  handleDelete: (id: number) => void;
}

interface IFreeSpinBonusData {
  bonusData: IGetAdmiralUserBonuses,
  games: FreeSpinGame[];
}

const MyFreeSpinsResults: FC<IMyFreeSpinsResults> = ({ freespins, handleDelete }) => {
  const navigate = useNavigate();
  const backdrop = useBackdrop();
  const [ freeSpinGames, setFreeSpinGames ] = useState<IFreeSpinBonusData | undefined>();

  const [ toggleUserBalance, {
    isLoading,
  } ] = useToggleUserBalanceMutation();
  const handleCloseModal = useCallback(() => {
    setFreeSpinGames(undefined);
  }, []);

  const handleClick = useCallback((freespin: IFreeSpinBonusData) => {
    if (Array.isArray(freespin.games) && freespin.games.length) {
      setFreeSpinGames({ bonusData: freespin.bonusData, games: freespin.games });
    } else {
      toggleUserBalance({ type: 'bonus', id: freespin.bonusData.id }).then(async () => {
        navigate(`/casino?bonusCard=${freespin.bonusData.id}&all=${true}&bm=1`);
      });
    }
  }, [ navigate, toggleUserBalance ]);

  useEffect(() => {
    backdrop(isLoading);
  }, [ backdrop, isLoading ]);

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)',
      }} mt={2} px={1.5} gap={1.5}>
      {freespins.map((freespin, index) => (
        <MyFreeSpinCard data={freespin} key={index} onClick={handleClick} onDelete={handleDelete}/>
      ))}
      {freeSpinGames && (
        <MyFreeSpinGamesModal
          open={!!freeSpinGames}
          data={freeSpinGames}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};
export default MyFreeSpinsResults;