import React, { Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { IRenderResult } from './IsRenderResult.type';
import IsRenderResultRetryButton from './IsRenderResultRetryButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const IsRenderResult = <TData, TError>({
  data,
  isLoading,
  isFetching,
  isSuccess,
  isError,
  isUninitialized,
  emptyResultMessage = 'empty_result_message',
  emptyResultComponent,
  errorMessage = 'error_message',
  errorComponent,
  loadingMessage = 'loading_message',
  loadingComponent,
  renderResult,
  showOnLoading = true,
  retryButtonText = 'retry_button_text',
  onRetry,
  refetches = [],
  children,
}: Omit<IRenderResult<TData, TError>, 'successComponent'> & { renderResult?: (data: TData) => ReactNode }) => {

  if (isLoading || isFetching) {
    if (showOnLoading) {
      return (
        <Fragment>
          {loadingComponent || (
            <Box
              p={1}
              flex="1 1 auto"
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="overline">
                <FormattedMessage id={loadingMessage} />
              </Typography>
            </Box>
          )}
        </Fragment>
      );
    }
    return null;
  }

  if (isError) {
    return (
      <Fragment>
        {errorComponent || (
          <Box p={1} flex="1 1 auto" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
            <Typography variant="overline">
              <FormattedMessage id={errorMessage} />
            </Typography>
            <IsRenderResultRetryButton onRetry={onRetry} refetches={refetches} retryButtonText={retryButtonText} />
          </Box>
        )}
      </Fragment>
    );
  }

  if (isUninitialized || !isSuccess || !data || (Array.isArray(data) && data.length === 0)) {
    return (
      <Fragment>
        {emptyResultComponent || (
          <Box p={1} flex="1 1 auto" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
            <Typography variant="overline">
              <FormattedMessage id={emptyResultMessage} />
            </Typography>
            <IsRenderResultRetryButton onRetry={onRetry} refetches={refetches} retryButtonText={retryButtonText} />
          </Box>
        )}
      </Fragment>
    );
  }

  return <Fragment>{renderResult ? renderResult(data) : children}</Fragment>;
};

export default IsRenderResult;
