import { BackdropContext } from '@common/components/backdrop/BackdropContext';
import { useCallback, useContext, useRef } from 'react';

export default function useBackdrop() {
  const key = useRef(Date.now());
  const toggleBackdrop = useContext(BackdropContext);

  return useCallback((status: boolean) => {
    toggleBackdrop(status, key.current.toString());
  }, [ toggleBackdrop ]);
}
