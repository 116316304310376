import TransitionZoom from '@common/components/Transitions/TransitionZoom';
import { ConfirmProvider } from 'material-ui-confirm';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

export default function DialogConfirmProvider(props: PropsWithChildren) {
  const { formatMessage } = useIntl();

  return (
    <ConfirmProvider
      defaultOptions={{
        title: formatMessage({ id: 'typography.dialog.sure' }),
        titleProps: {
          textAlign: 'center',
        },
        allowClose: false,
        dialogProps: {
          PaperProps: {
            elevation: 2,
          },
          TransitionComponent: TransitionZoom,
        },
        cancellationText: formatMessage({ id: 'button.cancel' }),
        confirmationText: formatMessage({ id: 'button.confirm' }),
        cancellationButtonProps: {
          sx: { boxShadow: 'none' },
          color: 'error',
          variant: 'contained',
          fullWidth: true,
        },
        confirmationButtonProps: {
          sx: { boxShadow: 'none' },
          color: 'success',
          variant: 'contained',
          fullWidth: true,
        },
      }}
    >
      {props.children}
    </ConfirmProvider>
  );
}
