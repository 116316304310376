import useBackdrop from '@common/components/backdrop/useBackdrop';
import IsRenderResult from '@common/components/IsRenderResult/IsRenderResult';
import { useAppSelector } from '@common/hooks/store';
import { useGetUserBonusCardsQuery } from '@features/freespin/api/freespinApi';
import MyFreeBetsResults from '@features/my-bonuses/freebets/components/MyFreeBetsResults';
import { selectUserBonusCards } from '@features/my-bonuses/slice/userBonusCardsSlice';
import { useEffect } from 'react';

const MyFreeBets = () => {
  const { data, isFetching, isError, isLoading, isSuccess, refetch } = useGetUserBonusCardsQuery();
  const backdrop = useBackdrop();
  const userBonusCard = useAppSelector(selectUserBonusCards);
  useEffect(() => {
    backdrop(isLoading);
  }, [ backdrop, isLoading ]);

  return (
    <IsRenderResult
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      isError={isError}
      data={data}
      onRetry={refetch}
      renderResult={(res) => <MyFreeBetsResults freebets={userBonusCard}/>}/>
  );
};
export default MyFreeBets;