import { ALLOW_TEST_MODE, APP_DOMAIN_ZONE, APP_LANGUAGE } from '@common/constants';
import config from '@config';
import { reactLocalStorage } from 'reactjs-localstorage';

export const isDomainZone = (zone: keyof typeof config.domains) => {
  if (ALLOW_TEST_MODE) {
    const stored = reactLocalStorage.get<string>(APP_DOMAIN_ZONE);
    if (stored) {
      return zone === stored;
    }
  }
  return config.domains[zone].includes(window.location.hostname);
};

export const getCountryCode = () => {
  if (ALLOW_TEST_MODE) {
    const stored = reactLocalStorage.get<any>(APP_DOMAIN_ZONE);
    if (stored && typeof stored === 'string' && stored in config.domains) {
      return stored.toUpperCase();
    }
  }
  if (isDomainZone('ua')) {
    return 'UA';
  } else if (isDomainZone('ru')) {
    return 'RU';
  } else if (isDomainZone('tj')) {
    return 'TJ';
  } else if (isDomainZone('uz')) {
    return 'UZ';
  } else if (isDomainZone('kg')) {
    return 'KG';
  } else {
    return 'TJ';
  }
};

export const getLanguage = () => {
  const lang = reactLocalStorage.get<string>(APP_LANGUAGE);
  if (!lang) {
    if (isDomainZone('uz')) {
      return 'uz';
    } else if (isDomainZone('ua')) {
      return 'ua';
    } else if (isDomainZone('kg')) {
      return 'ru';
    } else if (isDomainZone('boxbet')) {
      return 'uz';
    } else {
      return 'ru';
    }
  }
  return lang;
};

// export const isHostInKeno = () => {
//   const hostName = window.location.hostname;
//   const inbetHosts: Record<string, boolean> = config.inbetHosts;
//   if (hostName === 'localhost') {
//     return true;
//   }
//   return inbetHosts[hostName] !== undefined && inbetHosts[hostName];
// };
//
// export const isDevMode = () => {
//   const stored = reactLocalStorage.get(APP_ENABLE_DEV_CONFIG);
//   return stored === 'true';
// };

// export const apiDomainZone = () => {
//   if (isDomainZone('uz')) {
//     return 'https://api.boxbet.org';
//   } else if (isDomainZone('kg')) {
//     return 'https://api.boxbet.org';
//   } else if (isDomainZone('boxbet')) {
//     return 'https://api.boxbet.org';
//   } else if (isDomainZone('ua')) {
//     return 'https://api.epicboom.com';
//   } else if (isDomainZone('ru')) {
//     return 'https://api.formula55.ru';
//   } else {
//     return 'https://api.formula55.tj';
//   }
// };
