import { type RootState } from '@app/store';
import { APP_BANNERS_POPUP } from '@common/constants';
import { bannersApi } from '@features/banners/api/bannersApi';
import type { IBannersPopup } from '@features/banners/api/bannersApi.type';
import type { IPersonalPopupState, IPersonalPopupType } from '@features/banners/slice/personalPopupSlice.type';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import getDay from 'date-fns/getDay';
import { reactLocalStorage } from 'reactjs-localstorage';

const initialState = {
  data: {},
  show: [],
} as unknown as IPersonalPopupState;

const personalPopupSlice = createSlice({
  name: 'personalPopup',
  initialState: initialState,
  reducers: {
    openPersonalPopup: (state, action: PayloadAction<IPersonalPopupType>) => {
      if (!state.show.includes(action.payload)) {
        state.show.push(action.payload);
      }
    },
    closePersonalPopup: (state, action: PayloadAction<IPersonalPopupType>) => {
      if (state.show.includes(action.payload)) {
        const storedStatuses = reactLocalStorage.getObject(APP_BANNERS_POPUP, {}) as IBannersPopup<number>;
        reactLocalStorage.setObject(APP_BANNERS_POPUP, {
          ...storedStatuses,
          [action.payload]: getDay(new Date()),
        });
        state.show = state.show.filter((item) => item !== action.payload);
        delete state.data[action.payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(bannersApi.endpoints.fetchBanners.matchFulfilled, (state, { payload }) => {
        if (payload?.main && payload.main?.popup) {
          const storedStatuses = reactLocalStorage.getObject(APP_BANNERS_POPUP, {}) as IBannersPopup<number>;
          const { afterLogin, afterRegister, beforeRegister, user } = payload.main.popup;
          const today = getDay(new Date());
          if (afterLogin && today !== storedStatuses?.afterLogin) {
            state.data.afterLogin = afterLogin;
          }
          if (afterRegister) {
            state.data.afterRegister = afterRegister;
          }
          if (beforeRegister && today !== storedStatuses?.beforeRegister) {
            state.data.beforeRegister = beforeRegister;
          }
          if (user && today !== storedStatuses?.user) {
            state.data.user = user;
          }
        }
      });
  },
});

export const {
  openPersonalPopup,
  closePersonalPopup,
} = personalPopupSlice.actions;
export const selectActivePopup = (state: RootState) => {
  const personalPopup = state.personalPopup;
  if (personalPopup.show.length) {
    const activePopup = personalPopup.show.find((popup) => popup in personalPopup.data);
    if (activePopup) {
      return {
        data: personalPopup.data[activePopup],
        type: activePopup,
      };
    }
  }
};

export default personalPopupSlice.reducer;
