import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { FC, ReactNode } from 'react';

const WheelInnerBorder = styled(Box)`
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  width: calc(100% - 35px);
  height: calc(100% - 35px);
  border-radius: inherit;
`;

const WheelOuterBorder = styled(Box)`
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  border-radius: inherit;
`;

const WheelContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.4);

  &.standard {
    background-image: linear-gradient(0deg, #fff7bb 0%, #9b5d00 22%, #fff0a1 30%, #f0e27a 61%, #cea332 82%, #fff7bb 100%);

    & > ${WheelOuterBorder} {
      background-image: linear-gradient(0deg, #ff7f01 0%, #ff2e2a 13%, #c40000 37%, #ff302b 49%, #ff7f01 100%);

      & > ${WheelInnerBorder} {
        background-image: linear-gradient(0deg, #fff7bb 0%, #cea332 12%, #fff7bb 32%, #fff0a1 70%, #9b5d00 100%);
      }
    }
  }

  &.gold {
    background-image: linear-gradient(0deg, #fff7bb 0%, #9b5d00 22%, #fff0a1 30%, #f0e27a 61%, #cea332 82%, #fff7bb 100%);

    & > ${WheelOuterBorder} {
      background-image: linear-gradient(0deg, #ffc501 0%, #955e2a 13%, #ffc457 37%, #ac702b 49%, #f9b359 100%);

      & > ${WheelInnerBorder} {
        background-image: linear-gradient(0deg, #fff7bb 0%, #cea332 12%, #fff7bb 32%, #fff0a1 70%, #9b5d00 100%);
      }
    }
  }

  &.platinum {
    background-image: linear-gradient(45deg, #d4d5d6 0%, #696969 13%, #a4a6a8 37%, #67686b 49%, #e4e4e5 76%, #67686b 100%);

    & > ${WheelOuterBorder} {
      background-image: linear-gradient(0deg, #d4d5d6 0%, #696969 13%, #a4a6a8 37%, #67686b 49%, #e4e4e5 76%, #67686b 100%);

      & > ${WheelInnerBorder} {
        background-image: linear-gradient(-90deg, #d4d5d6 0%, #696969 13%, #a4a6a8 37%, #67686b 49%, #e4e4e5 76%, #67686b 100%);
      }
    }
  }
`;

const WheelContent = styled(Box)<WheelProps>`
  position: relative;
  overflow: hidden;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  border-radius: inherit;
  transform: rotate(${(props) => props.startRotationDegrees}deg);

  &.started-spinning {
    animation: spin-${(props) => props.classKey} ${(props) => props.startSpinningTime / 1000}s cubic-bezier(0.71,
    ${(props) => (props.disableInitialAnimation ? 0 : -0.29)},
    0.96,
    0.9) 0s 1 normal forwards running,
    continueSpin-${(props) => props.classKey} ${(props) => props.continueSpinningTime / 1000}s linear ${(props) =>
            props.startSpinningTime / 1000}s 1 normal forwards running,
    stopSpin-${(props) => props.classKey} ${(props) => props.stopSpinningTime / 1000}s cubic-bezier(0, 0, 0.35, 1.02) ${(props) =>
            (props.startSpinningTime + props.continueSpinningTime) / 1000}s 1 normal forwards running;
  }

  @keyframes spin-${(props) => props.classKey} {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes continueSpin-${(props) => props.classKey} {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes stopSpin-${(props) => props.classKey} {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => 1440 + props.finalRotationDegrees}deg);
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
    box-shadow: inset 0 0 7px 4px rgba(0, 0, 0, 0.6);
  }
`;
export const wheelColors = [ 'standard', 'gold', 'platinum' ];

interface WheelProps {
  wheelColor: number;
  startRotationDegrees: number;
  finalRotationDegrees: number;
  classKey: string;
  className: string;
  disableInitialAnimation?: boolean;
  startSpinningTime: number;
  continueSpinningTime: number;
  stopSpinningTime: number;
  children?: ReactNode;
}

const Wheel: FC<WheelProps> = (props) => {
  const className = wheelColors[props.wheelColor];

  // @ts-ignore
  return (
    <WheelContainer className={className}>
      <WheelOuterBorder>
        <WheelInnerBorder>
          <WheelContent children={props.children} {...props}/>
        </WheelInnerBorder>
      </WheelOuterBorder>
    </WheelContainer>
  );
};

export default Wheel;