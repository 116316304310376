import { RootState } from '@app/store';
import { APP_PLATFORM, APP_SESSION, USER_SESSION_TOKEN, X_CODE } from '@common/constants';
import config from '@config';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { reactLocalStorage } from 'reactjs-localstorage';

export type IValidationErrorResponse = {
  errors: Record<string, string[]>
  message: string
  status_code: 422
}

export type ICustomFetchBaseQueryError = FetchBaseQueryError | {
  data: Record<string, string[]>
  status: 'VALIDATION_ERROR'
} | {
  data: string
  status: 'VALIDATION_ERROR'
}

const platform = reactLocalStorage.get(APP_PLATFORM, 'pmw');
const xCode = reactLocalStorage.get<string | undefined>(X_CODE);
const baseQuery = fetchBaseQuery({
  baseUrl: config.host,
  timeout: 60000,
  headers: {
    'X-Platform': platform,
  },
  prepareHeaders: async (headers, api) => {
    const { app, auth } = (api.getState() as RootState);
    const extra = api.extra as { useToken: boolean } || undefined;
    if (auth.token && (extra === undefined || extra?.useToken === true)) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    }
    if (xCode) {
      headers.set('X-Code', xCode);
    }
    headers.set('Accept-Language', app.locale);
    return headers;
  },
});
const baseQueryWithJWT: BaseQueryFn<string | FetchArgs, unknown, ICustomFetchBaseQueryError> = async (args, api, extraOptions) => {
  const baseQueryApi = { ...api, extra: extraOptions };
  const result = await baseQuery(args, baseQueryApi, extraOptions);
  if (result.meta?.response) {
    const token = result.meta.response.headers.get('X-Access-Token');
    if (token) {
      reactLocalStorage.set(USER_SESSION_TOKEN, token);
      api.dispatch({ type: 'auth/setToken', payload: token });
    }
    const session = result.meta.response.headers.get('X-Access-Session');
    if (session) {
      reactLocalStorage.set(APP_SESSION, session);
    }
  }
  if (result.error) {
    if (result.error.status === 401) {
      reactLocalStorage.remove(USER_SESSION_TOKEN);
      api.dispatch({ type: 'auth/logout' });
    }
    if (result.error.status === 422) {
      let data = result.error.data as IValidationErrorResponse;
      if ('data' in data) {
        data = data.data as IValidationErrorResponse;
      }
      return {
        error: {
          data: data.errors || data.message,
          status: 'VALIDATION_ERROR',
        },
      };
    }
    return result;
  }
  if (result?.data && !Array.isArray(result.data) && Object.keys(result.data).length === 1) {
    if (typeof result.data === 'object' && 'token' in result.data) {
      return { data: result.data.token };
    }
    return result.data as { data: any };
  }
  return { data: result.data };
};
const commonApi = createApi({
  baseQuery: baseQueryWithJWT,
  reducerPath: 'api',
  endpoints: () => ({}),
});

export { commonApi, baseQuery, baseQueryWithJWT };
