import { formatOdd } from '@common/components/odds/formatUtils';
import { APP_ONE_CLICK_BET } from '@common/constants';
import { logout } from '@features/auth/slice/authSlice';
import { basketApi } from '@features/basket/api/basketApi';
import { BasketBetType, BasketStatus, IBasket } from '@features/basket/api/basketApi.type';
import { IBasketBonusCard, IBasketState, IOneClickBet } from '@features/basket/slice/basketSlice.type';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { reactLocalStorage } from 'reactjs-localstorage';

const basketCaseReducer = (state: Draft<IBasketState>, payload: IBasket) => {
  switch (payload.status) {
    case BasketStatus.EMPTY:
      state.ids = [];
      state.items = [];
      state.betType = BasketBetType.SINGLE;
      state.expressAvailable = false;
      state.expressSum = undefined;
      state.status = undefined;
      state.message = undefined;
      state.messageId = undefined;
      break;
    case BasketStatus.CONFIRMED:
      state.ids = [];
      state.items = [];
      state.betType = BasketBetType.SINGLE;
      state.expressAvailable = false;
      state.expressSum = undefined;
      state.status = payload.status;
      state.message = payload.message;
      state.messageId = payload.message ? Date.now() : undefined;
      break;
    case BasketStatus.REQUEST:
      state.status = payload.status;
      break;
    case BasketStatus.CANCELLED:
      state.ids = [];
      state.items = [];
      state.betType = BasketBetType.SINGLE;
      state.expressAvailable = false;
      state.status = payload.status;
      state.message = payload.message;
      state.messageId = payload.message ? Date.now() : undefined;
      break;
    case BasketStatus.LINE_CHANGE:
      state.ids = payload.items.map((item) => item.id);
      state.items = payload.betType === BasketBetType.EXPRESS ? payload.items.map((item) => {
        const index = state.items.findIndex((currentItem) => currentItem.id === item.id);
        if (index !== -1) {
          return {
            ...item,
            sum: state.items[index].sum,
          };
        }
        return item;
      }) : payload.items;
      state.betType = payload.betType;
      state.expressAvailable = payload.express_available;
      state.expressSum = (payload.betType === BasketBetType.EXPRESS && payload.items[0]?.sum)
        ? parseInt(payload.items[0].sum.toString())
        : state.expressSum;
      state.status = payload.status;
      state.message = payload.message;
      state.messageId = payload.message ? Date.now() : undefined;
      break;
    case BasketStatus.REQUEST_BAN:
    case BasketStatus.EMPTY_PLACE_BET:
    case BasketStatus.USER_NOT_VERIFIED:
    case BasketStatus.BONUS_CARD_LOW_RATE:
    case BasketStatus.BET_NOT_ALLOWED:
    case BasketStatus.BONUS_MIN_ITEMS:
      state.status = payload.status;
      state.message = payload.message;
      state.messageId = payload.message ? Date.now() : undefined;
      break;
    case BasketStatus.SUM_CHANGE:
      if (payload.betType === BasketBetType.SINGLE) {
        state.items = payload.items;
      } else if (payload.betType === BasketBetType.EXPRESS) {
        state.expressSum = parseInt(payload.items[0].sum.toString());
      }
      state.status = payload.status;
      state.message = payload.message;
      state.messageId = payload.message ? Date.now() : undefined;
      break;
    case BasketStatus.VALIDATION_BASKET_ADD:
      state.status = payload.status;
      state.message = payload.message;
      state.messageId = payload.message ? Date.now() : undefined;
      break;
    case BasketStatus.UNPROCESSED:
    case BasketStatus.INSUFFICIENT_FUNDS:
    default:
      const items = payload.items ?? [];
      state.ids = items.map((item) => item.id);
      state.items = items;
      state.betType = payload.betType ?? state.betType;
      state.expressAvailable = payload.express_available ?? state.expressAvailable;
      state.status = payload.status;
      state.message = payload.message;
      state.messageId = payload.message ? Date.now() : undefined;
  }
};
const oneClickBet = reactLocalStorage.getObject(APP_ONE_CLICK_BET, {
  allow: false,
  betAmount: 10,
}) as IOneClickBet;
const initialState: IBasketState = {
  ids: [],
  items: [],
  betType: BasketBetType.SINGLE,
  oneClickBet: oneClickBet,
  expressAvailable: false,
};
const basketSlice = createSlice({
  name: 'basket',
  initialState: initialState,
  reducers: {
    setBetType: (state, action: PayloadAction<BasketBetType>) => {
      state.betType = action.payload;
      delete state.bonusCard;
    },
    setExpressSum: (state, action: PayloadAction<number | ''>) => {
      if (state.items.length) {
        state.expressSum = action.payload || undefined;
      }
    },
    setBasketItemSum: (state, action: PayloadAction<{ itemId: string, sum: number | string }>) => {
      if (state.items.length) {
        const index = state.items.findIndex((item) => item.id === action.payload.itemId);
        if (index !== -1) {
          state.items[index].sum = action.payload.sum;
        }
      }
    },
    setOneClickBet: (state, action: PayloadAction<IOneClickBet>) => {
      state.oneClickBet = action.payload;
    },
    resetBasketStatus: (state) => {
      delete state.status;
      delete state.message;
    },
    setBasketBonusCard: (state, action: PayloadAction<IBasketBonusCard>) => {
      if (state.bonusCard && state.bonusCard.no === action.payload.no) {
        delete state.bonusCard;
      } else {
        state.bonusCard = action.payload;
      }
    },
    changeBasketItemValue: (state, action: PayloadAction<{
      eventId: number,
      odds: Record<string, string>,
      interest: number
    }>) => {
      if (state.items.length) {
        const index = state.items.findIndex((item) => item.event.id === action.payload.eventId);
        if (index !== -1) {
          const item = state.items[index];
          const currentValue = formatOdd(item.value);
          const newValue = formatOdd(action.payload.odds[`${item.outcomeSportId}_${item.outcomeItemId}`], action.payload.interest);
          if (currentValue !== newValue) {
            item.value = newValue;
            item.oldValue = currentValue;
          }
        }
      }
    },
    lockBasketItem: (state, action: PayloadAction<{ eventId: number, lock: boolean }>) => {
      if (state.items.length) {
        const index = state.items.findIndex((item) => item.event.id === action.payload.eventId);
        if (index !== -1) {
          state.items[index].event.lock = action.payload.lock;
        }
      }
    },
    lockBasketLiveItems: (state, action: PayloadAction<boolean>) => {
      if (state.items.length) {
        state.items.forEach((item) => {
          if (item.event.isLive) {
            item.event.lock = action.payload;
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        state.ids = [];
        state.items = [];
        state.betType = BasketBetType.SINGLE;
        state.expressAvailable = false;
        state.expressSum = undefined;
        state.status = undefined;
        state.message = undefined;
      })
      .addMatcher(basketApi.endpoints.fetchBasketItems.matchFulfilled, (state, { payload }) => {
        basketCaseReducer(state, payload);
      })
      .addMatcher(basketApi.endpoints.addOddToBasket.matchFulfilled, (state, { payload }) => {
        basketCaseReducer(state, payload);
      })
      .addMatcher(basketApi.endpoints.deleteItem.matchFulfilled, (state, { payload }) => {
        basketCaseReducer(state, payload);
      })
      .addMatcher(basketApi.endpoints.clearBasket.matchFulfilled, (state, { payload }) => {
        basketCaseReducer(state, payload);
      })
      .addMatcher(basketApi.endpoints.placeBet.matchFulfilled, (state, { payload }) => {
        basketCaseReducer(state, payload);
      });
  },
});

export const {
  setBetType,
  setExpressSum,
  setBasketItemSum,
  setOneClickBet,
  resetBasketStatus,
  setBasketBonusCard,
  changeBasketItemValue,
  lockBasketItem,
  lockBasketLiveItems,
} = basketSlice.actions;

export default basketSlice.reducer;
