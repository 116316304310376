import { liveApi } from '@features/live/api/liveApi';
import { IEventInfoLine, ILiveSport } from '@features/live/api/liveApi.type';
import { ILiveSliceState } from '@features/live/slice/liveSlice.type';
import { sortLiveEvents } from '@features/live/slice/liveSliceSelectors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ILiveSliceState = {
  tabs: [],
  events: [],
  sports: [],
  favorites: [],
  showFavorites: false,
};
const liveSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLiveSports: (state, action: PayloadAction<ILiveSport[]>) => {
      state.sports = action.payload;
    },
    setLiveEventRates: (state, action: PayloadAction<{
      id: number,
      count: number,
      mainline?: Record<string, string>,
      additional: Record<string, string>
    }>) => {
      const { id, additional, count, mainline } = action.payload;
      const index = state.events.findIndex((event) => event.id === id);
      if (index !== -1) {
        state.events[index].count = count;
        if (additional) {
          state.events[index].additional = additional;
        }
        if (mainline) {
          state.events[index].mainlines = mainline;
        }
        sortLiveEvents(state.events);
      }
    },
    setLiveEventInfoLine: (state, action: PayloadAction<{ id: number, data: IEventInfoLine }>) => {
      const { id, data } = action.payload;
      const index = state.events.findIndex((event) => event.id === id);
      if (index !== -1) {
        state.events[index].infoLine = data;
      }
    },
    showLiveEvent: (state, action: PayloadAction<number>) => {
      const index = state.events.findIndex((event) => event.id === action.payload);
      if (index !== -1) {
        state.events[index].show = true;
      }
      sortLiveEvents(state.events);
    },
    hideLiveEvent: (state, action: PayloadAction<number>) => {
      const index = state.events.findIndex((event) => event.id === action.payload);
      if (index !== -1) {
        state.events[index].show = false;
        const tabIndex = state.tabs.findIndex((tab) => tab.eventId === action.payload);
        if (tabIndex !== -1) {
          state.tabs.splice(tabIndex, 1);
        }
      }
    },
    lockLiveEvent: (state, action: PayloadAction<number>) => {
      const index = state.events.findIndex((event) => event.id === action.payload);
      if (index !== -1) {
        state.events[index].lock = true;
      }
    },
    unlockLiveEvent: (state, action: PayloadAction<number>) => {
      const index = state.events.findIndex((event) => event.id === action.payload);
      if (index !== -1) {
        state.events[index].lock = false;
      }
    },
    lockAllLiveEvent: (state) => {
      state.events.forEach((event) => {
        event.lock = true;
      });
    },
    unlockAllLiveEvent: (state) => {
      state.events.forEach((event) => {
        event.lock = false;
      });
    },
    lockAllScout: (state, action: PayloadAction<number[]>) => {
      if (Array.isArray(action.payload)) {
        state.events.forEach((event) => {
          if (action.payload.includes(event.id)) {
            event.lock = true;
          }
        });
      }
    },
    addLiveEventTab: (state, action: PayloadAction<number>) => {
      const eventExists = state.tabs.findIndex((tab) => tab.eventId === action.payload);
      if (eventExists === -1) {
        const event = state.events.find((event) => event.id === action.payload);
        if (event) {
          state.tabs.unshift({
            eventId: event.id,
            eventNo: event.no,
            sportAlias: event.alias,
          });
        }
      }
    },
    deleteLiveEventTab: (state, action: PayloadAction<number>) => {
      const index = state.tabs.findIndex((tab) => tab.eventId === action.payload);
      if (index !== -1) {
        state.tabs.splice(index, 1);
      }
    },
    livePosition: (state, action: PayloadAction<any>) => {
      if (action.payload.screens !== undefined && Object.keys(action.payload.screens).length > 0) {
        state.events.map((item, key) => {
          if (action.payload.screens[item.id] !== undefined) {
            state.events[key].position2 = action.payload.screens[item.id].online;
          }
          return null;
        });
        state.events = sortLiveEvents(state.events);
      }
    },
    setLiveFavorites: (state, action: PayloadAction<number[]>) => {
      const favorites = action.payload;
      if (Array.isArray(favorites) && favorites.length && state.events.length) {
        const eventIds = state.events.map((event) => event.id);
        state.favorites = favorites.filter((eventId) => eventIds.includes(eventId));
      }
    },
    showLiveFavorites: (state, action: PayloadAction<boolean>) => {
      state.showFavorites = action.payload;
    },
    addEventToFavorites: (state, action: PayloadAction<number>) => {
      const eventId = action.payload;
      // const eventExists = state.events.findIndex((event) => event.id === eventId);
      if (!state.favorites.includes(eventId)) {
        state.favorites.push(eventId);
      }
    },
    removeEventFromFavorites: (state, action: PayloadAction<number>) => {
      const eventId = action.payload;
      const index = state.favorites.findIndex((id) => id === eventId);
      if (index !== -1) {
        state.favorites.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(liveApi.endpoints.fetchLiveEvents.matchFulfilled, (state, { payload }) => {
        // state.events = payload.events.sort((a, b) => a.position.position - b.position.position);
        state.events = sortLiveEvents(payload.events);

        state.sports = payload.sports;
      })
      .addMatcher(liveApi.endpoints.fetchLiveEventById.matchFulfilled, (state, { payload }) => {
        if (payload) {
          state.events.push(payload);
          // state.events = state.events.sort((a, b) => a.position.position - b.position.position);
          state.events = sortLiveEvents(state.events);
        }
      });
  },
});

export const {
  setLiveEventRates,
  setLiveEventInfoLine,
  setLiveSports,
  showLiveEvent,
  hideLiveEvent,
  lockLiveEvent,
  unlockLiveEvent,
  lockAllLiveEvent,
  unlockAllLiveEvent,
  lockAllScout,
  addLiveEventTab,
  deleteLiveEventTab,
  setLiveFavorites,
  showLiveFavorites,
  addEventToFavorites,
  removeEventFromFavorites,
  livePosition,
} = liveSlice.actions;
export default liveSlice.reducer;
