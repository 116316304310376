import styled from '@emotion/styled';
import MyBonusesCountdown from '@features/my-bonuses/bonuses/components/MyBonusesCountdown';
import MyBonusesProgressBar from '@features/my-bonuses/bonuses/components/MyBonusesProgressBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { format, fromUnixTime } from 'date-fns';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IMyBonusesResults {
  bonuses: any;
  handleDelete: (id: number) => void;
}

const StyledBox = styled(Box)`
  & {
    padding: 1em;

    & > .free-spin-bonuses-item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-radius: 0.5em;
      padding: 1em;
      background-color: #1b1c1d;
      overflow: hidden;
      margin-block: 1em;

      .free-spin-bonuses-item-info {
        width: 100%;

        & > h1.free-spin-bonuses-item-title {
          margin: 0 0 20px;
          font-size: 1.5em;
          font-weight: 400;
          text-transform: uppercase;
        }

        & > div.free-spin-bonuses-item-description {
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 10px;
          justify-content: space-between;

        }
      }

      .free-spin-bonuses-item-stats {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-left: 1px solid #202020;


        div.free-spin-progress-bar-container {
          margin: 20px 0;
          width: 100%;

          div.free-spin-progress-bar {
            height: 10px;
            width: 100%;
            border-radius: 5px;
            background-color: #383b3c;
            overflow: hidden;

            & > div {
              height: inherit;
              border-radius: inherit;
              background-color: #ff7f24;
            }
          }

          div.free-spin-progress-bar-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 4px;

            & > span {
              font-size: 12px;
            }
          }
        }

        .free-spin-bonuses-item-delete-btn {
          color: red;
          cursor: pointer;
          border: 0;
          background-color: transparent;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
const MyBonusesResults: FC<IMyBonusesResults> = ({ bonuses, handleDelete }) => {

  return (
    <StyledBox className="free-spin-bonuses">
      {Array.isArray(bonuses) && !!bonuses.length && bonuses.map((item) => (
        <Box
          key={item.id}
          className="free-spin-bonuses-item"
        >
          <Box className="free-spin-bonuses-item-info">
            <Typography variant="h1" className="free-spin-bonuses-item-title">{item.title}</Typography>
            <Box className="free-spin-bonuses-item-description">
              <Box>
                <Typography>
                  <FormattedMessage id="BONUS_AMOUNT"/>
                </Typography>
                <Typography variant="body1" color="primary">{item.startingBalance}</Typography>
              </Box>
              <Box>
                <Typography>
                  <FormattedMessage id="RECEIPT_TIME"/>
                </Typography>
                <Typography variant="body1" color="primary">
                  {format(fromUnixTime(item.createdAt), 'dd.MM.yyyy, HH:mm')}
                </Typography>
              </Box>
              <Box>
                <Typography>
                  <FormattedMessage id="WAGERING"/>
                </Typography>
                <Typography variant="body1" color="primary">x{item.wager}</Typography>
              </Box>
              <Box>
                <Typography>
                  <FormattedMessage id="WAGERING_LIMIT"/>
                </Typography>
                <Typography variant="body1" color="primary">{item.maxWinSum}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="free-spin-bonuses-item-stats">
            <MyBonusesCountdown
              date={item.createdAt}
              isView
              wageringTime={item.wageringTime}
            />
            <MyBonusesProgressBar
              turnover={item.turnover}
              wagerSum={item.wagerSum}
            />
            <button
              onClick={() => handleDelete(item.id)}
              className="free-spin-bonuses-item-delete-btn"
            >
              <FormattedMessage id="CANCEL_BONUS"/>
            </button>
          </Box>
        </Box>
      ))}
    </StyledBox>
  );
};

export default MyBonusesResults;