import { commonApi } from '@common/utils/baseQuery';
import config from '@config';
import { IGetUserDepositLimitsResponse, SaveUserDepositLimitsProps } from '@features/my-limits/api/myLimitsApi.type';

export const liveApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserDepositLimits: builder.query<IGetUserDepositLimitsResponse[], void>({
      query: () => `${config.host}/payways/deposit_limits`,
    }),
    saveUserDepositLimits: builder.mutation<IGetUserDepositLimitsResponse[], SaveUserDepositLimitsProps[]>({
      query: (data) => ({
        url: `${config.host}/payways/deposit_limits`,
        body: {
          limits: data,
        },
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetUserDepositLimitsQuery, useSaveUserDepositLimitsMutation } = liveApi;