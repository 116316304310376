import Headline from '@common/components/headline/Headline';
import { useAppSelector } from '@common/hooks/store';
import styled from '@emotion/styled';
import MyBonuse from '@features/my-bonuses/bonuses/MyBonuse';
import MyFortune from '@features/my-bonuses/fortune/MyFortune';
import MyFreeBets from '@features/my-bonuses/freebets/MyFreeBets';
import MyFreeSpins from '@features/my-bonuses/freespins/MyFreeSpins';
import { getBonusesCount, getFreeSpinBonusesCount } from '@features/my-bonuses/slice/admiralUserBonusesSlice';
import { getUserFreeBetsCount } from '@features/my-bonuses/slice/userBonusCardsSlice';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const ITabPanel = styled(TabPanel)`
  padding: 0;
`;
const StyledTabList = styled(TabList)`
  & .MuiTabs-scroller {
    overflow-x: scroll !important;
  }
`;

const StyledTab = styled(Tab)``;

const StyledCountBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const StyledCount = styled(Box)`
  background: #ff3535;
  padding: 0.5em 0.5em;
  color: #fff;
  text-align: center;
  font-size: 0.8em;
  border-radius: 0.3em;
  z-index: 99;
`;

const MyBonuses = () => {
  const [ value, setValue ] = useState<string>('freebets');
  const bonusesCount = useAppSelector(getBonusesCount);
  const freeSpinBonusesCount = useAppSelector(getFreeSpinBonusesCount);
  const freeBetsCount = useAppSelector(getUserFreeBetsCount);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Fragment>

      <Headline
        title={<FormattedMessage id="my_bonuses"/>}
      />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabList onChange={handleChange}>
            <StyledTab label={
              <StyledCountBox>
                <FormattedMessage id={'FREEBETS'}/>
                {!!freeBetsCount && <StyledCount>{freeBetsCount}</StyledCount>}
              </StyledCountBox>
            } value="freebets"/>
            <StyledTab label={
              <StyledCountBox>
                <FormattedMessage id={'BONUSES'}/>
                {!!bonusesCount && <StyledCount>{bonusesCount}</StyledCount>}
              </StyledCountBox>
            } value="bonuses"/>
            <StyledTab label={
              <StyledCountBox>
                <FormattedMessage id={'FREESPINS'}/>
                {!!freeSpinBonusesCount && <StyledCount>{freeSpinBonusesCount}</StyledCount>}
              </StyledCountBox>
            } value="freespins"/>
            {/*<StyledTab label={*/}
            {/*  <StyledCountBox>*/}
            {/*    <FormattedMessage id={'FORTUNE'}/>*/}
            {/*    {!!fortuneWheelsCount && <StyledCount>{fortuneWheelsCount}</StyledCount>}*/}
            {/*  </StyledCountBox>*/}
            {/*} value="fortune-wheel"/>*/}
          </StyledTabList>
        </Box>
        <ITabPanel value="freebets"><MyFreeBets/></ITabPanel>
        <ITabPanel value="bonuses"><MyBonuse/></ITabPanel>
        <ITabPanel value="freespins"><MyFreeSpins/></ITabPanel>
        <ITabPanel value="fortune-wheel"><MyFortune/></ITabPanel>

      </TabContext>
    </Fragment>
  );
};
export default MyBonuses;