import React from 'react';
import Button from '@mui/material/Button';
import { Refresh } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

interface IsRenderResultRetryButtonProps {
  onRetry?: () => void;
  refetches: {
    isError: boolean;
    refetch: () => void;
  }[];
  retryButtonText: string;
}
  
const IsRenderResultRetryButton: React.FC<IsRenderResultRetryButtonProps> = ({ onRetry, refetches, retryButtonText }) => {
  const handleRetry = () => {
    if (onRetry) {
      onRetry();
    }
    refetches.forEach(({ isError, refetch }) => {
      if (isError) {
        refetch();
      }
    });
  };

  if (onRetry || refetches.some(({ isError }) => isError)) {
    return (
      <Button
        sx={{ mt: '10px' }}
        size="small"
        color="warning"
        variant="contained"
        onClick={handleRetry}
        startIcon={<Refresh />}
      >
        <FormattedMessage id={retryButtonText} />
      </Button>
    );
  }
  return null;
};

export default IsRenderResultRetryButton;
