import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { Link } from 'react-router-dom';

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 2em;

  section {
    width: 80%;
    font-family: 'RobotoCondensedBold', serif;

    h2 {
      margin: 0;
      font-size: 2em;
    }

    p {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }
  }

  div.success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    margin-top: 80px;
    border-radius: 20px;
    border: 1px solid #fee900;

    div.icon-container {
      margin-top: -50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 20px;
      border-radius: 20px;
      background-color: #ffc71d;
    }

    a {
      padding: 30px 0;
      color: #dedede;
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: #fee900;
      }
    }
  }
`;

const MoneyGateComplete: React.FC = () => {
  return (
    <StyledBox>
      <section>
        <Typography variant="h2" className="brand-color">
          Оплата в процессе
        </Typography>
        <Typography variant="body1">Ваш платеж обрабатывается , дождитесь зачисления средств на баланс</Typography>
      </section>
      <Box className="success">
        <Box className="icon-container">
          <CheckCircleIcon style={{ color: 'white', width: '100px', height: '100px' }}/>
        </Box>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button variant="contained" style={{ backgroundColor: '#ffc71d', color: 'white' }}>
            Вернуться на главную
          </Button>
        </Link>
      </Box>
    </StyledBox>
  );
};

export default MoneyGateComplete;
