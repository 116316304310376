import appStore from '@common/components/mobile-app-links/images/app-store.png';
import playStore from '@common/components/mobile-app-links/images/play-store.png';
import { getCountryCode } from '@common/utils/lang';
import config from '@config';
import styled from '@emotion/styled';
import React from 'react';
import Image from '../Image/Image';

const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 8px 8px;

  & > a {
    width: 50%;

    &:first-of-type {
      margin-right: 8px;
    }

    & > img {
      width: 100%;
      vertical-align: middle;
    }
  }
`;

export default function SidebarMobileAppLinks() {
  const countryCode = getCountryCode().toLowerCase();
  const links = config.appLinks[countryCode];

  return (
    <LinksContainer>
      {links[0] && (
        <a rel="noopener noreferrer" href={links[0]}>
          <Image alt="Play Store" src={playStore}/>
        </a>
      )}
      {links[1] && (
        <a rel="noopener noreferrer" href={links[1]}>
          <Image alt="App Store" src={appStore}/>
        </a>
      )}
    </LinksContainer>
  );
}
