import { useAppDispatch, useAppSelector } from '@common/hooks/store';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import { bannersApi } from '@features/banners/api/bannersApi';
import PersonalPopup from '@features/banners/personal-popup/PersonalPopup';
import { closePersonalPopup, selectActivePopup } from '@features/banners/slice/personalPopupSlice';
import type { IPersonalPopupType } from '@features/banners/slice/personalPopupSlice.type';
import { Fragment, type ReactNode, useCallback, useEffect } from 'react';
import { shallowEqual } from 'react-redux';

export interface IPersonalPopupProviderProps {
  children: ReactNode;
}

export default function PersonalPopupProvider(props: IPersonalPopupProviderProps) {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(userLoggedIn);
  const activePopup = useAppSelector(selectActivePopup, shallowEqual);
  const handleClose = useCallback((type: IPersonalPopupType) => {
    dispatch(closePersonalPopup(type));
  }, [ dispatch ]);

  useEffect(() => {
    dispatch(bannersApi.endpoints.fetchBanners.initiate());
  }, [ dispatch ]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(bannersApi.endpoints.fetchBanners.initiate());
    }
  }, [ dispatch, loggedIn ]);

  return (
    <Fragment>
      {props.children}
      {activePopup && (
        <PersonalPopup
          key={activePopup.type}
          data={activePopup.data}
          type={activePopup.type}
          onClose={handleClose}
        />
      )}
    </Fragment>
  );
}
