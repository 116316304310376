import { useAppSelector } from '@common/hooks/store';
import { useEffect, useState } from 'react';
import { useCheckTokenQuery } from './api/authApi';

export default function useCheckToken() {
  const [ checking, setChecking ] = useState(true);
  const hasToken = useAppSelector(({ auth }) => !!auth.token);
  const { isSuccess } = useCheckTokenQuery(undefined, {
    skip: !hasToken,
    pollingInterval: 60000,
    selectFromResult: (res) => ({ isSuccess: res.isSuccess }),
  });

  useEffect(() => {
    if (!hasToken || isSuccess) {
      setChecking(false);
    }
  }, [ hasToken, isSuccess ]);

  return checking;
}
