import { commonApi } from '@common/utils/baseQuery';

import { IUserStats } from '@features/auth/api/authApi.type';
import { IAddToBasket, IBasket, IPlaceBet } from '@features/basket/api/basketApi.type';

export const basketApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    fetchBasketItems: build.query<IBasket, void>({
      query: () => '/basket',
    }),
    addOddToBasket: build.mutation<IBasket, IAddToBasket>({
      query: (body) => ({
        url: '/basket',
        body,
        method: 'POST',
      }),
    }),
    placeBet: build.mutation<IBasket, IPlaceBet>({
      query: ({ items, betType, cardNo }) => ({
        url: '/basket/place',
        body: { ...items, betType, cardNo },
        method: 'POST',
      }),
    }),
    deleteItem: build.mutation<IBasket, string | number>({
      query: (oddId) => ({
        url: `/basket/${oddId}`,
        method: 'DELETE',
      }),
    }),
    clearBasket: build.mutation<IBasket, void>({
      query: () => ({
        url: '/basket',
        method: 'DELETE',
      }),
    }),
    setBasketRatio: build.mutation<void, IUserStats['basketRatio']>({
      query: (value) => ({
        url: `/basket/settings/ratio/${value}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useFetchBasketItemsQuery,
  useAddOddToBasketMutation,
  usePlaceBetMutation,
  useDeleteItemMutation,
  useClearBasketMutation,
  useSetBasketRatioMutation,
} = basketApi;
