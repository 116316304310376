import { commonApi } from '@common/utils/baseQuery';
import { ITooltips } from '@features/app/api/appApi.type';

export const appApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    fetchTooltips: build.query<ITooltips, void>({
      query: () => `${window.location.origin}/tooltips.json`,
    }),
  }),
});

export const { useFetchTooltipsQuery } = appApi;
