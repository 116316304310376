import { commonApi } from '@common/utils/baseQuery';
import {
  ISaveVerifyStep,
  IUploadIdentityDocument,
  IVerificationData,
} from '@features/verification/api/verificationApi.type';

export const verificationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchVerificationData: builder.query<IVerificationData | false, void>({
      query: () => '/users/verify',
    }),
    saveVerifyStep: builder.mutation<void, ISaveVerifyStep>({
      query: (body) => ({
        url: '/users/verify',
        body,
        method: 'POST',
      }),
    }),
    verifyConfirm: builder.mutation<void, void>({
      query: () => ({
        url: '/users/verify/confirm',
        method: 'PUT',
      }),
    }),
    uploadIdentityDocument: builder.mutation<IUploadIdentityDocument, FormData>({
      query: (body) => ({
        url: '/fileupload/manager/identity',
        body,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useFetchVerificationDataQuery,
  useSaveVerifyStepMutation,
  useVerifyConfirmMutation,
  useUploadIdentityDocumentMutation,
} = verificationApi;
