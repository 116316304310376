import useBackdrop from '@common/components/backdrop/useBackdrop';
import { useAppDispatch, useAppSelector } from '@common/hooks/store';
import { selectUserBalance } from '@features/auth/slice/authSlice';
import { freespinApi, useToggleUserBalanceMutation } from '@features/freespin/api/freespinApi';
import { selectActiveFreeSpinBonus, selectAvailableBonus } from '@features/my-bonuses/slice/admiralUserBonusesSlice';
import { FC, useEffect, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';

interface IAdmiralGameBonusesProps {
  onReload: () => void;
}

type TimerId = ReturnType<typeof setInterval>;

const MemoizedAdmiralGameBonuses: FC<IAdmiralGameBonusesProps> = ({ onReload }) => {
  const dispatch = useAppDispatch();
  const timerRef = useRef<TimerId>();
  const backdrop = useBackdrop();
  const [ toggleUserBalance, {
    isLoading: isLoadingToggleUserBalance,
  } ] = useToggleUserBalanceMutation();
  const hasBalance = useAppSelector((state) => selectUserBalance(state) >= 1, shallowEqual);

  const bonus = useAppSelector(selectAvailableBonus, (left, right) => {
    return left?.id === right?.id && left?.balance === right?.balance;
  });
  const activeBonus = useAppSelector(selectActiveFreeSpinBonus, (left, right) => {
    return left?.id === right?.id;
  });

  useEffect(() => {
    timerRef.current = setInterval(() => {
      dispatch(freespinApi.endpoints.getAdmiralUserBonuses.initiate());
    }, 5000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [ dispatch ]);

  useEffect(() => {
    if (hasBalance && activeBonus?.id) {
      backdrop(isLoadingToggleUserBalance);
      toggleUserBalance({ type: 'real', id: activeBonus.id })
        .then(() => {
          onReload();
        });
    }
  }, [ activeBonus?.id, backdrop, hasBalance, isLoadingToggleUserBalance, onReload, toggleUserBalance ]);

  useEffect(() => {
    if (activeBonus && activeBonus.balance < 1) {
      backdrop(isLoadingToggleUserBalance);
      toggleUserBalance({ type: 'real', id: activeBonus.id })
        .then(() => {
          onReload();
        });
    }
  }, [ activeBonus, backdrop, isLoadingToggleUserBalance, onReload, toggleUserBalance ]);

  useEffect(() => {
    if (!hasBalance) {
      if (bonus && bonus.isPrimary === 0) {
        backdrop(isLoadingToggleUserBalance);
        toggleUserBalance({ type: 'bonus', id: bonus.id })
          .then(() => {
            onReload();
          });
      }
    }
  }, [ backdrop, bonus, hasBalance, isLoadingToggleUserBalance, onReload, toggleUserBalance ]);
  return <></>;
};
const AdmiralGameBonuses: FC<IAdmiralGameBonusesProps> = ({ onReload }) => {
  return useMemo(() => {
    return <MemoizedAdmiralGameBonuses onReload={onReload}/>;
  }, []);
};

export default AdmiralGameBonuses;