import { useAppSelector } from '@common/hooks/store';
import React, { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import en from './locale/en.json';
import ru from './locale/ru.json';
import tj from './locale/tj.json';
import ua from './locale/ua.json';

function getLocalTranslations(locale: string) {
  switch (locale) {
    case 'en':
      return en;
    case 'ua':
      return ua;
    case 'tj':
      return tj;
    default:
      return ru;
  }
}

export default function TranslationsProvider(props: PropsWithChildren) {
  const storedLocale = useAppSelector((state) => state.app.locale);
  const translations = useAppSelector((state) => state.translations.data);
  const locale = storedLocale === 'ua' ? 'uk' : storedLocale;
  const localMessages = getLocalTranslations(storedLocale);
  const messages = { ...translations, ...localMessages };

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      defaultLocale="ru"
    >
      {props.children}
    </IntlProvider>
  );
}
