import { FC, Suspense } from 'react';
import { Img, ImgProps } from 'react-image';

const Image: FC<ImgProps> = ({ ...props }) => {
  return (
    <Suspense>
      <Img loading="lazy" {...props} />
    </Suspense>
  );
};

export default Image;
