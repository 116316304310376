import boxbetLogo from '@assets/images/logo/boxbet_logo.png';
import epicboomLogo from '@assets/images/logo/epicboom_logo.png';
import formula55Logo from '@assets/images/logo/formula55_logo.png';
import { isDomainZone } from '@common/utils/lang';
import styled from '@emotion/styled';
import React from 'react';
import Image from '../Image/Image';

const logo = isDomainZone('ua') ? epicboomLogo : isDomainZone('boxbet') ? boxbetLogo : formula55Logo;
const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 72px;
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    display: inline-block;
    border-top: 1px solid ${props => props.theme.palette.warning.main};
    border-right: 1px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export default function BackdropLoader() {
  return (
    <LoaderContainer>
      <Image alt="logo" src={logo} width="50px"/>
    </LoaderContainer>
  );
}
