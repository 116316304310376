import { commonApi } from '@common/utils/baseQuery';
import config from '@config';
import { IUser, IUserCredentials, IUserGeolocation } from '@features/auth/api/authApi.type';

export const authApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IUser, IUserCredentials>({
      query: (credentials) => ({
        url: '/auth/login',
        body: credentials,
        method: 'POST',
      }),
    }),
    checkToken: builder.query<IUser, void>({
      query: () => '/auth/me',
      // @ts-expect-error
      providesTags: [ 'Auth' ],
    }),
    updateUserGeolocation: builder.mutation<void, IUserGeolocation>({
      query: (data) => ({
        url: `${config.gpsTracker}/gps/users/add`,
        body: data,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useCheckTokenQuery,
  useUpdateUserGeolocationMutation,
} = authApi;
