import { useAppDispatch } from '@common/hooks/store';
import { campaignsApi } from '@features/campaigns/api/campaignsApi';
import React, { Fragment, PropsWithChildren, useEffect } from 'react';

export default function AppCampaignsLoader(props: PropsWithChildren) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const result = dispatch(campaignsApi.endpoints.fetchCampaigns.initiate(undefined, {
      subscriptionOptions: {
        pollingInterval: 300000,
      },
    }));
    return result.unsubscribe;
  }, [ dispatch ]);

  useEffect(() => {
    const result = dispatch(campaignsApi.endpoints.fetchCampaignsRefill.initiate(undefined, {
      subscriptionOptions: {
        pollingInterval: 300000,
      },
    }));
    return result.unsubscribe;
  }, [ dispatch ]);

  return <Fragment>{props.children}</Fragment>;
}
