import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface SidebarContextProps {
  open: boolean;
  toggleSidebar: () => void;
}

export const SidebarContext = createContext<SidebarContextProps>({
  open: false,
  toggleSidebar: () => void 0,
});

export default function SidebarProvider(props: PropsWithChildren) {
  const location = useLocation();
  const [ open, setOpen ] = useState(false);
  const toggleSidebar = useCallback(() => {
    setOpen((open) => {
      if (!location.pathname.includes('/live')) {
        if (!open) {
          window.AndroidBridge?.layoutRefreshCommand(0);
        } else {
          window.AndroidBridge?.layoutRefreshCommand(1);
        }
      }
      return !open;
    });
  }, [ location ]);

  return (
    <SidebarContext.Provider value={{ open, toggleSidebar }}>
      {props.children}
    </SidebarContext.Provider>
  );
}
