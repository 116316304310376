import Image from '@common/components/Image/Image';
import { IGetAdmiralFortuneWheelPrize } from '@features/freespin/api/freespin.type';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IMyFortunePrizeModal {
  data: IGetAdmiralFortuneWheelPrize;
  open: boolean,
  onCloseModal: () => void
}

const MyFortunePrizeModal: FC<IMyFortunePrizeModal> = ({ data, open, onCloseModal }) => {
  if (!data) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={onCloseModal}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <FormattedMessage id="fortuna_result"/>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon/>
      </IconButton>
      <DialogContent dividers sx={{ paddingX: 2 }}>
        <Box textAlign={'center'}>
          {data.prize.type === 'bonus' &&
						<Image src={'/casino/fortuna/bonus.png'} alt={'bonus'} width={200} height={200}/>}
          {data.prize.type === 'cash' &&
						<Image src={'/casino/fortuna/cash.png'} alt={'cash'} width={200} height={200}/>}
          {data.prize.type === 'freeSpin' &&
						<Image src={'/casino/fortuna/my-bonuses.png'} alt={'freeSpin'} width={200} height={200}/>}

          <Typography fontSize={'1.5em'} color="warning.main" textTransform="uppercase" marginTop={3}>
            <FormattedMessage id={'accrued'} values={{ sum: data.prize.sum }}/>
          </Typography>

        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MyFortunePrizeModal;