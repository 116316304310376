import { RootState } from '@app/store';
import { ILiveEvent } from '@features/live/api/liveApi.type';
import lodashFindIndex from 'lodash/findIndex';
import lodashGroupBy from 'lodash/groupBy';
import lodashOrderBy from 'lodash/orderBy';

export const filterLiveEvents = (sportAlias?: string) => (state: RootState) => {
  const { events, showFavorites, favorites } = state.live;

  const sortedEvents = [ ...events ].sort((a, b) => {
    if (a.position2.groupPosition !== b.position2.groupPosition) {
      return a.position2.groupPosition - b.position2.groupPosition;
    } else {
      return a.position2.position - b.position2.position;
    }
  });
  const filteredEvents = sortedEvents.filter((event) => {
    if (!event.show || (showFavorites && !favorites.includes(event.id))) {
      return false;
    }
    if (sportAlias) {
      return event.alias === sportAlias;
    }
    return true;
  });
  return filteredEvents.map((item) => item.id);
};
export const selectLiveEventById = (eventId: number) => (state: RootState) => {
  if (!isNaN(eventId)) {
    return state.live.events.find((event) => event.id === eventId);
  }
};
export const selectAllLiveEvent = () => (state: RootState) => {
  return state.live;
};
export const liveEventInFavorites = (eventId: number) => (state: RootState) => {
  return state.live.favorites.includes(eventId);
};
export const sortLiveEvents = (events: ILiveEvent[]) => {
  const grouped = lodashGroupBy(lodashOrderBy(events, 'position2.position'), 'position2.groupId');

  const aliases: { id: number; position: number; }[] = events // Указываем явно тип
    .reduce((result: { id: number; position: number; }[], item) => { // Указываем явно тип
      const index = lodashFindIndex(result, [ 'id', item.position2.groupId ]);
      if (index === -1) {
        result.push({
          id: item.position2.groupId,
          position: item.position2.groupPosition,
        });
      }
      return result;
    }, []);
  return lodashOrderBy(aliases, 'position')
    .reduce((result: ILiveEvent[], item) => { // Указываем явно тип
      if (item.id in grouped) {
        result.push(...grouped[item.id]);
      }
      return result;
    }, []);
};
