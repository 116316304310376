import { formatFetchBaseQueryError } from '@common/components/Form/formatFieldError';
import { ICustomFetchBaseQueryError } from '@common/utils/baseQuery';
import { SerializedError } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

export default function useAppForm<TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>,
  errors?: ICustomFetchBaseQueryError | SerializedError,
): UseFormReturn<TFieldValues, TContext> {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm(props);

  useEffect(() => {
    if (errors) {
      const formattedErrors = formatFetchBaseQueryError(errors);
      if (typeof formattedErrors === 'string') {
        enqueueSnackbar(formattedErrors, { variant: 'error' });
      } else {
        formattedErrors.forEach(([ field, message ]) => {
          form.setError(field, { type: 'custom', message });
        });
      }
    }
  }, [ enqueueSnackbar, errors, form ]);

  return form;
}
