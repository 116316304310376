import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledButton = styled(Button)({
  color: 'white',
  borderRadius: 0,
  minHeight: '36px',
  justifyContent: 'start',
  paddingLeft: '16px',
  paddingRight: '16px',
});

export default function ClickableHeadline(props: { children: ReactNode, onClick: () => void }) {
  return (
    <StyledButton
      startIcon={<ArrowBackIosIcon/>}
      onClick={props.onClick}
    >
      {props.children}
    </StyledButton>
  );
}
