import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/MenuList';

export const MenuList = styled(Menu)`
  .MuiMenuItem-root {
    min-height: 42px;
  }

  .MuiMenuItem-root > .MuiListItemIcon-root {
    color: #fee900;
  }

  .MuiMenuItem-root > .MuiTypography-root {
    white-space: normal;
    flex-grow: 1;
  }
`;

export const FlipText = styled.span`
  display: block;
  animation: 1s flip ease infinite;

  @keyframes flip {
    0% {
      opacity: 0;
      transform: rotateX(90 def);
    }

    50% {
      opacity: 1;
      transform: rotateX(720deg);
    }

    100% {
      opacity: 1;
      transform: rotateX(720deg);
    }
  }
`;

export const CampaignChip = styled(Chip)`
  height: auto;
  font-size: 10px;
  border-radius: 3px;
  animation: pulse 2s infinite;

  & > span {
    padding: 0 4px;
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
      box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
      box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
      box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
    }
  }
`;
