import { baseQueryWithJWT } from '@common/utils/baseQuery';
import type { ISendSurveyParams, ISurvey } from '@features/survey/api/surveyApi.type';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const surveyApi = createApi({
  baseQuery: baseQueryWithJWT,
  reducerPath: 'surveyApi',
  tagTypes: [ 'Survey' ],
  endpoints: (builder) => ({
    fetchAllSurvey: builder.query<ISurvey[], void>({
      query: () => '/survey?filter=all',
      providesTags: [ 'Survey' ],
      transformResponse: (response: Omit<ISurvey, 'userAnswered'>[]) => {
        if (Array.isArray(response) && response.length) {
          return response.map((item) => ({
            ...item,
            userAnswered: item.questions.some((question) => question.userAnswered),
          }));
        }
        return [];
      },
    }),
    fetchSurvey: builder.query<ISurvey, void>({
      query: () => '/survey',
    }),
    sendSurveyResult: builder.mutation<void, ISendSurveyParams>({
      query: ({ id, ...body }) => ({
        url: `/survey/${id}`,
        body,
        method: 'POST',
      }),
      invalidatesTags: [ 'Survey' ],
    }),
  }),
});

export const {
  useFetchAllSurveyQuery,
  useFetchSurveyQuery,
  useSendSurveyResultMutation,
} = surveyApi;
