import styled from '@emotion/styled';
import MoneyGateStepperIcon from '@features/deposit/screens/money-gate/components/stepper/MoneyGateStepperIcon';
import { Step, StepConnector, stepConnectorClasses, StepLabel, Stepper } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';

interface IMoneyGateStepperProps {
  activeStep: number;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242 210 33) 0%,rgb(198 233 64) 50%,rgb(56 138 35) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242 210 33) 0%,rgb(198 233 64) 50%,rgb(56 138 35) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));
const MoneyGateStepper: FC<IMoneyGateStepperProps> = ({ activeStep }) => {
  const steps = [ 'Выбор банка', 'Процесс оплаты', 'Успешный перевод' ];
  return (
    <Box mt={2.5}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={MoneyGateStepperIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );

};

export default MoneyGateStepper;