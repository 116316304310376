import { useAppSelector } from '@common/hooks/store';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

export default function useAppPlatform() {
  const platform = useAppSelector((state) => state.app.platform, shallowEqual);

  return useMemo(() => ({
    platform,
    isAndroid: platform === 'pma',
    isIos: platform === 'pmi',
    isMobile: platform === 'pma' || platform === 'pmi',
    isWeb: platform !== 'pma' && platform !== 'pmi',
  }), [ platform ]);
}
