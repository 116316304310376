import styled from '@emotion/styled';
import { FC, RefObject, useEffect, useRef } from 'react';
import { clamp } from './utils';

const StyledCanvas = styled('canvas')`
  height: 100%;
  width: 100%;
`;

const gradients = [
  [
    [ 0, '#ff7f01' ],
    [ 1, '#ff4022' ],
  ],
  [
    [ 0, '#ffc01f' ],
    [ 0.5, '#f3a245' ],
    [ 0.8, '#ffc457' ],
    [ 1, '#f3a245' ],
  ],
  [
    [ 0, '#919191' ],
    [ 0.5, '#cecece' ],
    [ 0.8, '#b7b7b7' ],
    [ 1, '#c5c5c5' ],
  ],
];

const drawRadialBorder = (
  ctx: CanvasRenderingContext2D,
  centerX: number,
  centerY: number,
  insideRadius: number,
  outsideRadius: number,
  angle: number,
) => {
  ctx.beginPath();
  ctx.moveTo(
    centerX + (insideRadius + 1) * Math.cos(angle),
    centerY + (insideRadius + 1) * Math.sin(angle),
  );
  ctx.lineTo(
    centerX + (outsideRadius - 1) * Math.cos(angle),
    centerY + (outsideRadius - 1) * Math.sin(angle),
  );
  ctx.closePath();
  ctx.stroke();
};

const drawWheel = (canvasRef: RefObject<HTMLCanvasElement>, data: any[], color: number) => {
  const bonuse = new Image();
  const freespin = new Image();
  const cash = new Image();
  const red_dots = new Image();
  const white_dots = new Image();

  bonuse.src = '/casino/fortuna/bonus.png';
  freespin.src = '/casino/fortuna/my-bonuses.png';
  cash.src = '/casino/fortuna/cash.png';
  red_dots.src = '/casino/fortuna/red_dot.png';
  white_dots.src = '/casino/fortuna/white_dot.png';

  const canvas = canvasRef.current;

  if (canvas?.getContext('2d')) {
    const ctx = canvas.getContext('2d')!;
    ctx.clearRect(0, 0, 500, 500);
    ctx.strokeStyle = 'transparent';
    ctx.lineWidth = 0;
    let startAngle = 0;
    const outsideRadius = canvas.width / 2;
    const clampedContentDistance = clamp(0, 100, 60);
    const contentRadius = (outsideRadius * clampedContentDistance) / 100;
    const clampedInsideRadius = clamp(0, 100, 0);
    const insideRadius = (outsideRadius * clampedInsideRadius) / 100;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    for (let i = 0; i < data.length; i++) {
      const arc = (2 * Math.PI) / data.length;
      const endAngle = startAngle + arc;

      if (i % 2 === 0) {
        ctx.fillStyle = '#4c4c4c';

      } else {
        const gradient = ctx.createRadialGradient(300, 300, 0, 300, 300, 300);
        gradients[color].forEach(([ offset, rgb ]) => {
          if (typeof offset === 'number') {
            if (typeof rgb === 'string') {
              gradient.addColorStop(offset, rgb);
            }
          }
        });
        ctx.fillStyle = gradient;
      }

      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius, startAngle, endAngle, false);
      ctx.arc(centerX, centerY, insideRadius, endAngle, startAngle, true);
      ctx.stroke();
      ctx.fill();
      ctx.save();

      if (color === 2) {
        ctx.strokeStyle = '#5f5f5f';
      } else {
        ctx.strokeStyle = '#fff0a1';

      }

      ctx.lineWidth = 4;
      ctx.save();

      drawRadialBorder(ctx, centerX, centerY, insideRadius, outsideRadius, startAngle);

      if (i === data.length - 1) {
        drawRadialBorder(ctx, centerX, centerY, insideRadius, outsideRadius, endAngle);
      }

      ctx.translate(
        centerX + Math.cos(startAngle + arc / 2) * contentRadius,
        centerY + Math.sin(startAngle + arc / 2) * contentRadius,
      );

      let contentRotationAngle = startAngle + arc / 2;
      ctx.rotate(contentRotationAngle);

      const text = data[i].option;
      ctx.font = `bold 20px Helvetica`;
      ctx.fillStyle = '#fff';

      if (text === 'BONUS') {
        ctx.rotate(Math.PI / 2);
        ctx.fillText(
          text,
          -ctx.measureText(text).width / 2,
          55,
        );
        ctx.drawImage(bonuse, -40, -60, 85, 85);
        ctx.rotate(-Math.PI / 2);

      }
      if (text === 'CASH') {
        ctx.rotate(Math.PI / 2);
        ctx.fillText(
          text,
          -ctx.measureText(text).width / 2,
          55,
        );
        ctx.drawImage(cash, -30, -60, 85, 85);
        ctx.rotate(-Math.PI / 2);
      }

      if (text === 'FREESPIN') {
        ctx.rotate(Math.PI / 2);
        ctx.drawImage(freespin, -40, -60, 85, 85);
        ctx.rotate(-Math.PI / 2);

      }

      ctx.restore();
      startAngle = endAngle;
    }
  }
};

interface WheelCanvasProps {
  data: any[];
  color: number;
  width: number;
  height: number;
}

const WheelCanvas: FC<WheelCanvasProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    drawWheel(canvasRef, props.data, props.color);
  }, [ canvasRef, props ]);

  return (
    <StyledCanvas
      ref={canvasRef}
      width={props.width}
      height={props.height}
    />
  );
};

export default WheelCanvas;
