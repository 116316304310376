import { commonApi } from '@common/utils/baseQuery';
import config from '@config';
import {
  IUpdateMoneyGateOrderBankResponse,
  MoneyGateBank,
} from '@features/deposit/screens/money-gate/api/moneyGate.type';

export const moneyGateApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getMoneyGateBanks: builder.query<MoneyGateBank[], { orderId: string }>({
      query: ({ orderId }): any => ({
        url: `${config.billingHost}/payways/mgate/banks/${orderId}`,
        responseHandler: async (response: any) => {
          const data = await response.json();
          return data.banks;
        },
      }),
    }),
    updateMoneyGateOrderBank: builder.mutation<any, {
      orderId: string,
      bank: string,
      type: string,
    }>({
      query: ({ orderId, bank, type }) => ({
        url: `${config.billingHost}/payways/mgate/update/${orderId}`,
        method: 'PUT',
        body: {
          bank,
          type,
          successUrl: `${window.location.origin}/account-history`,
        },
      }),

    }),
    checkMoneyGateOrderStatus: builder.query<IUpdateMoneyGateOrderBankResponse, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `${config.billingHost}/payways/mgate/status/${orderId}`,
        responseHandler: async (response: any) => {
          const data = await response.json();
          return data.data;
        },
      }),

    }),
    confirmMoneyGateOrder: builder.mutation<any, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `${config.billingHost}/payways/mgate/confirm/${orderId}`,
        method: 'PUT',
      }),

    }),
  }),
});

export const {
  // useCheckMoneyGateOrderStatusQuery,
  useConfirmMoneyGateOrderMutation,
  useGetMoneyGateBanksQuery,
  useLazyCheckMoneyGateOrderStatusQuery,
  useLazyGetMoneyGateBanksQuery,
  useUpdateMoneyGateOrderBankMutation,
} = moneyGateApi;