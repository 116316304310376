import MaintenanceSvg from '@assets/images/maintenance/maintenance.svg';
import Image from '@common/components/Image/Image';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function Maintenance() {
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={MaintenanceSvg} width="320" height={400}/>
      <Typography variant="overline" marginTop={1} px={2}
                  textAlign="center" fontSize="0.85em">
        <FormattedMessage id="typo.maintenance"/>
      </Typography>
    </Box>
  );
}
