import { IHeadlineProps } from '@common/components/headline/Headline.type';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import React from 'react';

const HeadlineContainer = styled(Paper)`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 8px 16px;
  border-radius: 0;

  &.pb-0 {
    padding-bottom: 0;
  }

  &.bg-none {
    background-color: transparent;
    background-image: none;
    box-shadow: none;
  }

  & > .title {
    flex: 1 1 auto;
    font-size: 13px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: initial;

    & > * {
      display: inline-block;
    }
  }
`;

export default function Headline({ title, actions, children, gutterBottom, disableBackground }: IHeadlineProps) {
  const classNames = cx('headline', {
    'pb-0': !!gutterBottom,
    'bg-none': !!disableBackground,
  });

  return (
    <HeadlineContainer className={classNames}>
      <Typography className="title" variant="overline">
        {title}
        {children}
      </Typography>
      {actions && (
        <Box className="actions">
          {actions}
        </Box>
      )}
    </HeadlineContainer>
  );
}
