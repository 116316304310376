import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DynamicPlaceKey = `place${number}`;

export interface PlaceData {
  [key: DynamicPlaceKey]: {
    isSelected?: boolean;
    icon: string;
    name: string;
  };
}

export interface ForecastState {
  groupsPlaces: Record<string, number>;
  final_1_8: Record<string, PlaceData | any>;
  final_1_4: Record<string, PlaceData | any>;
  final_1_2: Record<string, PlaceData | any>;
  final: {
    [key: DynamicPlaceKey]: {
      isSelected?: boolean;
      icon: string;
      name: string;
    };
    team1: any;
    team2: any;
    result1: number;
    result2: number;
  };
  thirdPlace: any;
  thirdPlaceCount: number;
  hasForecast: boolean,

  setFinal_1_8(data: Record<string, PlaceData>): void;

  setFinal_1_4(data: Record<string, PlaceData>): void;

  setFinal_1_2(data: Record<string, PlaceData>): void;

  setGroupsPlaces(data: Record<string, number>): void;

  setThirdPlace(data: any): void;

  setFinal(data: {
    team1: any;
    team2: any;
    result1: number;
    result2: number;
  }): void;

  setHasForecast(data: boolean): void;

  setThirdPlaceCount(): void;

  clearData(): void;

  setForecastState(data: any): void
}

const initialState: ForecastState = {
  groupsPlaces: {
    'A': 0,
    'B': 0,
    'C': 0,
    'D': 0,
    'E': 0,
    'F': 0,
  },
  final_1_8: {
    '1': { place1: {}, place2: {} },
    '2': { place1: {}, place2: {} },
    '3': { place1: {}, place2: {} },
    '4': { place1: {}, place2: {} },
    '5': { place1: {}, place2: {} },
    '6': { place1: {}, place2: {} },
    '7': { place1: {}, place2: {} },
    '8': { place1: {}, place2: {} },
  },
  final_1_4: {
    '1': { place1: {}, place2: {} },
    '2': { place1: {}, place2: {} },
    '3': { place1: {}, place2: {} },
    '4': { place1: {}, place2: {} },
  },
  final_1_2: {
    '1': { place1: {}, place2: {} },
    '2': { place1: {}, place2: {} },
  },
  final: {
    team1: {},
    team2: {},
    result1: 0,
    result2: 0,
  },
  thirdPlace: {},
  thirdPlaceCount: 0,
  hasForecast: false,
  setFinal_1_8(data: Record<string, PlaceData>) {
  },
  setFinal_1_4(data: Record<string, PlaceData>) {
  },
  setFinal_1_2(data: Record<string, PlaceData>) {
  },
  setGroupsPlaces(data: Record<string, number>) {
  },
  setThirdPlace(data: any) {
  },
  setFinal(data: { team1: any; team2: any; result1: number; result2: number }) {
  },
  setHasForecast(data: boolean) {
  },
  setThirdPlaceCount() {
  },
  clearData() {
  },
  setForecastState(data: any) {
  },

};

const forecastSlice = createSlice({
  name: 'forecast',
  initialState,
  reducers: {
    setFinal_1_8(state, action: PayloadAction<Record<string, PlaceData>>) {
      state.final_1_8 = action.payload;
    },
    setFinal_1_4(state, action: PayloadAction<Record<string, PlaceData>>) {
      state.final_1_4 = action.payload;
    },
    setFinal_1_2(state, action: PayloadAction<Record<string, PlaceData>>) {
      state.final_1_2 = action.payload;
    },
    setGroupsPlaces(state, action: PayloadAction<Record<string, number>>) {
      state.groupsPlaces = action.payload;
    },
    setThirdPlace(state, action: PayloadAction<any>) {
      state.thirdPlace = action.payload;
    },
    setThirdPlaceValue(state, action: PayloadAction<any>) {
      const { key, newValue } = action.payload;
      state.thirdPlace[key] = newValue;
    },
    setFinal(state, action: PayloadAction<{
      team1: any;
      team2: any;
      result1: number;
      result2: number;
    }>) {
      state.final = action.payload;
    },
    setHasForecast(state, action: PayloadAction<boolean>) {
      state.hasForecast = action.payload;
    },
    setThirdPlaceCount(state) {
      state.thirdPlaceCount += 1;
    },
    clearData() {
      return initialState;
    },
    setForecastState(state, action: PayloadAction<any>) {
      return action.payload;
    },
  },
});

export const {
  setFinal_1_8,
  setFinal_1_4,
  setFinal_1_2,
  setGroupsPlaces,
  setThirdPlace,
  setThirdPlaceValue,
  setHasForecast,
  setFinal,
  setThirdPlaceCount,
  clearData,
  setForecastState,
} = forecastSlice.actions;

export default forecastSlice.reducer;
