import { commonApi } from '@common/utils/baseQuery';
import config from '@config';
import {
  IGetAdmiralCampaigns,
  IGetAdmiralFortuneWheelPrize,
  IGetAdmiralUserBonuses,
  IToggleUserBalanceReqest,
} from '@features/freespin/api/freespin.type';

export const freespinApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdmiralCampaigns: builder.query<IGetAdmiralCampaigns, void>({
      query: () => `${config.host}/casinobonus/campaigns`,
    }),
    getUserBonusCards: builder.query<any, void>({
      query: () => `${config.host}/bonuscard`,
    }),
    getAdmiralUserBonuses: builder.query<IGetAdmiralUserBonuses[], void>({
      query: () => `${config.host}/casinobonus/userbonuses`,
    }),
    toggleUserBalance: builder.mutation<void, IToggleUserBalanceReqest>({
      query: ({ type, id }) => ({
        url: `${config.admiral.host}/bonuses/change`,
        body: {
          account: type,
          id,
        },
        method: 'POST',
      }),
    }),
    getAdmiralFortuneWheelPrize: builder.mutation<IGetAdmiralFortuneWheelPrize, { id: number }>({
      query: ({ id }) => ({
        url: `${config.host}/casinobonus/fortunewheel/${id}/prize`,
        method: 'POST',
      }),
    }),
    deleteFreeSpinBonus: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${config.host}/casinobonus/userbonuses/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});
export const {
  useGetAdmiralCampaignsQuery,
  useLazyGetAdmiralCampaignsQuery,
  useGetAdmiralUserBonusesQuery,
  useGetUserBonusCardsQuery,
  useToggleUserBalanceMutation,
  useGetAdmiralFortuneWheelPrizeMutation,
  useDeleteFreeSpinBonusMutation,
} = freespinApi;
