import useBackdrop from '@common/components/backdrop/useBackdrop';
import { MoneyGateBank } from '@features/deposit/screens/money-gate/api/moneyGate.type';
import {
  useConfirmMoneyGateOrderMutation,
  useUpdateMoneyGateOrderBankMutation,
} from '@features/deposit/screens/money-gate/api/moneyGateApi';
import MoneyGateBanks from '@features/deposit/screens/money-gate/components/MoneyGateBanks';
import MoneyGateComplete from '@features/deposit/screens/money-gate/components/MoneyGateComplete';
import MoneyGateError from '@features/deposit/screens/money-gate/components/MoneyGateError';
import MoneyGateRequisites from '@features/deposit/screens/money-gate/components/MoneyGateRequisites';
import MoneyGateStepper from '@features/deposit/screens/money-gate/components/stepper/MoneyGateStepper';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';

interface IMoneyGateContainerProps {
  moneyGate: MoneyGateBank[];
  orderId: string;
  defaultBank?: string | null;
  defaultType?: string | null;
}

interface IHandleChangeActiveStep {
  step: number;
}

interface IHandleSelectBank {
  bank: string;
  orderId: string;
  type: string;
}

const MoneyGateContainer: FC<IMoneyGateContainerProps> = ({ moneyGate, defaultBank, defaultType, orderId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const backdrop = useBackdrop();

  const [ activeStep, setActiveStep ] = useState<number>(0);
  const [ updateMoneyGateOrderBank, { data } ] = useUpdateMoneyGateOrderBankMutation();
  const [ confirmMoneyGateOrder ] = useConfirmMoneyGateOrderMutation();

  const handleChangeActiveStep = useCallback(({ step }: IHandleChangeActiveStep) => {
    setActiveStep(step);
  }, [ setActiveStep ]);

  const handleSelectBank = useCallback(({ type, bank, orderId }: IHandleSelectBank) => {
    updateMoneyGateOrderBank({ type, bank, orderId }).unwrap()
      .catch((e) => {
        enqueueSnackbar(e.data, { variant: 'error' });
      });
  }, [ enqueueSnackbar, updateMoneyGateOrderBank ]);

  const handleOnConfirm = useCallback(() => {
    confirmMoneyGateOrder({ orderId }).then(() => {
      handleChangeActiveStep({ step: 2 });
    });
  }, [ confirmMoneyGateOrder, handleChangeActiveStep, orderId ]);

  useEffect(() => {
    if (orderId && defaultBank && defaultType) {
      backdrop(true);
      handleSelectBank({ orderId, bank: defaultBank, type: defaultType });
      setActiveStep(1);
      backdrop(false);
    }
  }, [ backdrop, defaultBank, defaultType, handleSelectBank, orderId ]);

  return (
    <Fragment>
      <MoneyGateStepper activeStep={activeStep}/>

      {activeStep === 0 && (
        <MoneyGateBanks banks={moneyGate}
                        disabled={!!(defaultBank && defaultType)}
                        onSelectBank={handleSelectBank} orderId={orderId}
                        handleChangeActiveStep={handleChangeActiveStep}/>
      )}

      {activeStep === 1 && (
        <MoneyGateRequisites data={data} handleOnConfirm={handleOnConfirm}
        />
      )}

      {activeStep === 2 && (
        <MoneyGateComplete/>
      )}

      {activeStep === 3 && (
        <MoneyGateError/>
      )}
    </Fragment>
  );
};

export default MoneyGateContainer;