import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IMyBonusesProgressBar {
  turnover: number,
  wagerSum: number
}

const MyBonusesProgressBar: FC<IMyBonusesProgressBar> = ({ turnover, wagerSum }) => {

  const value = turnover * 100 / wagerSum;

  return (
    <Box className="free-spin-progress-bar-container">
      <Box className="free-spin-progress-bar">
        <Box style={{ width: `${value}%` }}/>
      </Box>
      <Box className="free-spin-progress-bar-info">
        <FormattedMessage id="WON_BACK"/>
        <Typography variant={'overline'}>{turnover}/{wagerSum}</Typography>
      </Box>
    </Box>
  );
};
export default MyBonusesProgressBar;