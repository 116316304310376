import { RootState } from '@app/store';
import { freespinApi } from '@features/freespin/api/freespinApi';
import { createSlice } from '@reduxjs/toolkit';

interface IUserBonusCardsInitialState {
  data: any,
}

const initialState: IUserBonusCardsInitialState = {
  data: [],
};

const userBonusCardsSlice = createSlice({
  name: 'userBonusCards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(freespinApi.endpoints.getUserBonusCards.matchFulfilled, (state, { payload }) => {
        state.data = payload;
      });

  },
});

export const selectUserBonusCards = (state: RootState) => state.userBonusCards.data;
export const getUserFreeBetsCount = (state: RootState): number => state.userBonusCards.data.length;
export default userBonusCardsSlice.reducer;
