import useBackdrop from '@common/components/backdrop/useBackdrop';
import IsRenderResult from '@common/components/IsRenderResult/IsRenderResult';
import { useAppSelector } from '@common/hooks/store';
import { useDeleteFreeSpinBonusMutation, useGetAdmiralUserBonusesQuery } from '@features/freespin/api/freespinApi';
import MyBonusesResults from '@features/my-bonuses/bonuses/components/MyBonusesResults';
import { selectFreeSpinBonuses } from '@features/my-bonuses/slice/admiralUserBonusesSlice';
import { useCallback, useEffect } from 'react';

const MyBonuse = () => {
  const backdrop = useBackdrop();
  const {
    data: admiralUserBonuses,
    isFetching,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchGetAdmiralUserBonuses,
  } = useGetAdmiralUserBonusesQuery();
  const data = useAppSelector(selectFreeSpinBonuses);
  const [ deleteFreeSpinBonus, {
    isLoading: isLoadingToggleUserBalance,
  } ] = useDeleteFreeSpinBonusMutation();

  const handleDelete = useCallback((id: number) => {
    deleteFreeSpinBonus({ id }).then(async () => {
      await refetchGetAdmiralUserBonuses();
    });
  }, [ deleteFreeSpinBonus, refetchGetAdmiralUserBonuses ]);

  useEffect(() => {
    backdrop(isLoading || isLoadingToggleUserBalance);
  }, [ backdrop, isLoading, isLoadingToggleUserBalance ]);

  return (
    <IsRenderResult
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      isError={isError}
      data={admiralUserBonuses}
      onRetry={refetchGetAdmiralUserBonuses}
    >
      {data && <MyBonusesResults bonuses={data} handleDelete={handleDelete}/>}
    </IsRenderResult>
  );
};
export default MyBonuse;