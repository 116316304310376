import Image from '@common/components/Image/Image';
import config from '@config';
import styled from '@emotion/styled';
import type { IBannersPopupProps } from '@features/banners/api/bannersApi.type';
import type { IPersonalPopupType } from '@features/banners/slice/personalPopupSlice.type';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IPersonalPopupProps {
  data: IBannersPopupProps;
  type: IPersonalPopupType;
  onClose: (type: IPersonalPopupType) => void;
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    position: inherit;
  }

  img.banner {
    width: 100%;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
  }

  .MuiIconButton-root.close-button {
    position: absolute;
    bottom: 40px;
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;

    .MuiSvgIcon-root {
      font-size: 60px;
    }
  }
`;

export default function PersonalPopup({ data, type, onClose }: IPersonalPopupProps) {
  const timer = useRef<NodeJS.Timer>();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    onClose(type);
  }, [ onClose, type ]);
  const handleClick = useCallback(() => {
    navigate(data.url);
    handleClose();
  }, [ data.url, handleClose, navigate ]);

  useEffect(() => {
    let delay = data.delay >= 10 ? data.delay : 10;
    delay = delay * 10000;
    timer.current = setTimeout(() => {
      handleClose();
    }, delay);
    return () => clearTimeout(timer.current);
  }, [ data.delay, handleClose ]);

  return (
    <StyledDialog
      open={true}
      onClose={onClose}
      className="banner-popup"
      PaperComponent={Box}
    >
      <Image
        alt="Banner"
        src={`${config.cdn}/banners/${data.img}`}
        onClick={handleClick}
        className="banner"
      />
      <IconButton onClick={handleClose} className="close-button">
        <HighlightOffIcon/>
      </IconButton>
    </StyledDialog>
  );
}
