import useBackdrop from '@common/components/backdrop/useBackdrop';
import Headline from '@common/components/headline/Headline';
import IsRenderResult from '@common/components/IsRenderResult/IsRenderResult';
import { useAppSelector } from '@common/hooks/store';
import { useGetAdmiralCampaignsQuery } from '@features/freespin/api/freespinApi';
import FreespinCampaignPreview from '@features/freespin/components/FreespinCampaignPreview';
import {
  selectAdmiralDepositCampaigns,
  selectAdmiralFortuneWheelCampaigns,
} from '@features/freespin/slice/admiralCampaignsSlice';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

const FreespinCampaigns = () => {
  const { data, isFetching, isError, isLoading, isSuccess, refetch } = useGetAdmiralCampaignsQuery();
  const fortuneWheelCampaigns = useAppSelector(selectAdmiralFortuneWheelCampaigns);
  const depositCampaigns = useAppSelector(selectAdmiralDepositCampaigns);

  const backdrop = useBackdrop();

  useEffect(() => {
    backdrop(isLoading);
  }, [ backdrop, isLoading ]);

  return (
    <Box>
      <Headline title={<FormattedMessage id="promotions_bonuses"/>}/>
      <IsRenderResult
        isLoading={isLoading}
        isFetching={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        data={data}
        onRetry={refetch}
        renderResult={(res) => (
          <Box mt={2} px={1.5} gridTemplateColumns="1fr 1fr" display="grid" gap={1}>
            {depositCampaigns.data && depositCampaigns.data.map((item) => (
              <FreespinCampaignPreview campaign={item} key={item.id} type="deposit"/>
            ))}
            {fortuneWheelCampaigns.data && fortuneWheelCampaigns.data.map((item) => (
              <FreespinCampaignPreview campaign={item} key={item.id} type="fortune"/>
            ))}
          </Box>
        )}
      />
    </Box>
  );
};

export default FreespinCampaigns;