import { ruRU } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

const themeBoxBet = createTheme(
  {
    palette: {
      mode: 'dark',
      background: {
        default: '#003299',
        paper: '#0465f2',
      },
      primary: {
        main: '#fff',
      },
      warning: {
        main: '#fee900',
      },
      secondary: {
        main: '#021734',
      },
      success: {
        main: '#00b260',
      },
      info: {
        main: '#3f5791',
      },
      white: {
        main: '#fff',
      },
      midnightBlue: {
        main: '#011c40',
        dark: '#021734',
      },
    },
    gradient: {
      allJackpot: {
        main: 'linear-gradient(to left, #0049c7 0%, #0465f2 100%)',
      },
    },
    mixins: {
      toolbar: {
        height: '56px',
      },
    },
    typography: {
      htmlFontSize: 18,
      button: {
        fontWeight: 'bold',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.no-shadow': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          color: 'warning',
          variant: 'filled',
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.disableUnderline && {
              borderRadius: theme.shape.borderRadius,
            }),
          }),
          input: ({ ownerState }) => ({
            ...(ownerState.endAdornment && {
              '&:-webkit-autofill': {
                borderBottomLeftRadius: 'inherit',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
            }),
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.no-shadow': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-selected': {
              color: theme.palette.warning.main,
            },
          }),
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: ({ theme }) => ({
            backgroundColor: theme.palette.warning.main,
          }),
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            '& > .MuiMenuItem-root:last-child': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            margin: '8px',
            width: 'calc(100% - 16px)',
            maxHeight: 'calc(100% - 16px)',
            maxWidth: 'calc(100% - 16px)',
          },
          paperWidthSm: {
            width: '600px',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
    },
  },
  ruRU
);

export default themeBoxBet;
