import DialogPopup from '@common/components/PopupAlert/DialogPopup';
import type { DialogProps } from '@mui/material/Dialog';
import type { PropsWithChildren, ReactNode } from 'react';
import React, { createContext, useCallback, useState } from 'react';

export interface DialogState extends Pick<DialogProps, 'open' | 'classes' | 'disableEscapeKeyDown' | 'scroll' | 'sx' | 'fullScreen'> {
  icon?: 'info' | 'success' | 'warning';
  timer?: number;
  title?: ReactNode;
  content?: ReactNode;
  showCloseButton?: boolean;
  showConfirmButton?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

export const PopupAlertContext = createContext<[ (options: Omit<DialogState, 'open'>) => void, () => void ]>([
  () => void 0,
  () => void 0,
]);
const initialState: DialogState = {
  open: false,
  timer: 2000,
};

export default function PopupAlertProvider(props: PropsWithChildren) {
  const [ state, setState ] = useState<DialogState>(initialState);
  const show = useCallback((options: Omit<DialogState, 'open'>) => {
    setState((state) => ({
      ...state,
      ...options,
      open: true
    }));
  }, []);
  const hide = useCallback(() => {
    setState(initialState);
  }, []);

  return (
    <PopupAlertContext.Provider value={[ show, hide ]}>
      <DialogPopup
        {...state}
        onClose={hide}
        onPopupClose={state.onClose}
      />
      {props.children}
    </PopupAlertContext.Provider>
  );
}
