import styled from '@emotion/styled';
import React from 'react';
import pointer from './assets/pointer.png';

const PointerImg = styled('img')`
  position: absolute;
  top: 0;
  width: 28px;
  z-index: 100;
`;

const SpinWheelPointer: React.FC = () => {
  return <PointerImg src={pointer} alt="Wheel Pointer"/>;
};

export default SpinWheelPointer;
