import useBackdrop from '@common/components/backdrop/useBackdrop';
import FroalaTypography from '@common/components/FroalaTypography';
import Headline from '@common/components/headline/Headline';
import Image from '@common/components/Image/Image';
import { useAppSelector } from '@common/hooks/store';
import config from '@config';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import { useLazyGetAdmiralCampaignsQuery } from '@features/freespin/api/freespinApi';
import FreespinCampaignCountdown from '@features/freespin/components/FreespinCampaignCountdown';
import {
  selectAdmiralDepositCampaignById,
  selectAdmiralFortuneWheelCampaignById,
} from '@features/freespin/slice/admiralCampaignsSlice';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { Fragment, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

const FreespinCampaignView = () => {
  const { id, type } = useParams();
  const backdrop = useBackdrop();
  const loggedIn = useAppSelector(userLoggedIn);
  const navigate = useNavigate();
  const [ admiralCampaigns, { isLoading } ] = useLazyGetAdmiralCampaignsQuery();
  const { data } = useAppSelector((type === 'fortune')
    ? selectAdmiralFortuneWheelCampaignById(id)
    : selectAdmiralDepositCampaignById(id),
  );

  const handleGetBonus = useCallback(() => {
    if (loggedIn) {
      navigate('/deposit');
    } else {
      navigate('/sign-in');
    }
  }, [ navigate, loggedIn ]);

  useEffect(() => {
    backdrop(isLoading);
    if (!data) {
      admiralCampaigns();
    }
  }, [ backdrop, isLoading, data, admiralCampaigns ]);

  if (!data) return null;

  return (
    <Fragment>
      <Headline title={<FormattedMessage id="CAMPAIGNS"/>}/>

      <Box px={1} mt={2}>
        <Box>
          <Box display="flex" justifyContent="center">
            <Image
              width={100}
              height={100}
              alt={data.title}
              src={`${config.cdn}/epicboom/${data.image}`}
            />
          </Box>
          <Box mt={2}>
            <Box>
              <Typography variant="h5" fontSize="1.4em" fontWeight={700} textAlign="center">
                {data.title}
              </Typography>
              <Typography textAlign="center" mt={1} fontSize="1em">
                {data.brief}
              </Typography>
            </Box>
            <Box display="grid" justifyItems="center">
              <FreespinCampaignCountdown date={new Date(data.endTime * 1000)} isView/>
              <Box mt={3}>
                <Button onClick={handleGetBonus} variant="contained">
                  <FormattedMessage id="get_bonus"/>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={5}>
          <FroalaTypography>
            {data.description}
          </FroalaTypography>
        </Box>
      </Box>

    </Fragment>
  );
};

export default FreespinCampaignView;