import NewYearBoxbet from '@assets/images/new-year/boxbet.webp';
import NewYearEpicboom from '@assets/images/new-year/epicboom.webp';
import NewYearF55RU from '@assets/images/new-year/f55_ru.webp';
import NewYearF55TJ from '@assets/images/new-year/f55_tj.webp';
import NewYearF55UZ from '@assets/images/new-year/f55_uz.webp';

import Image from '@common/components/Image/Image';
import { isDomainZone } from '@common/utils/lang';
import config from '@config';
import NewYearCountDown from '@features/new-year/NewYearCountDown';
import Box from '@mui/material/Box';
import { FC } from 'react';

interface INewYear {
  setIsNewYear: (value: boolean) => void;
}

const NewYear: FC<INewYear> = ({ setIsNewYear }) => {
  const countDownDate = new Date(config.newYearDate).getTime();
  const newYearImage =
    isDomainZone('ua') ? NewYearEpicboom :
      isDomainZone('boxbet') ? NewYearBoxbet :
        isDomainZone('tj') ? NewYearF55TJ :
          isDomainZone('ru') ? NewYearF55RU : NewYearF55UZ;

  return (
    <Box position="relative">
      <Box position="absolute" bottom={isDomainZone('boxbet') || isDomainZone('ua') ? '8rem' : '6rem'} left={0}
           right={0}>
        <NewYearCountDown date={countDownDate} setIsNewYear={setIsNewYear}/>
      </Box>
      <Image src={newYearImage} style={{ objectFit: 'cover' }} width="100%" height="100%"/>
    </Box>
  );
};

export default NewYear;