import { RootState } from '@app/store';
import { APP_PLATFORM } from '@common/constants';
import { getLanguage } from '@common/utils/lang';
import { appApi } from '@features/app/api/appApi';
import { IAppState } from '@features/app/slice/appSlice.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reactLocalStorage } from 'reactjs-localstorage';

const initialState = {
  locale: getLanguage(),
  platform: reactLocalStorage.get(APP_PLATFORM),
} as IAppState;
const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setAppLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setAppPlatform: (state, action: PayloadAction<IAppState['platform']>) => {
      state.platform = action.payload;
    },
    setAppPlatformVersion: (state, action: PayloadAction<string>) => {
      state.platformVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(appApi.endpoints.fetchTooltips.matchFulfilled, (state, { payload }) => {
        state.tooltips = payload;
      });
  },
});

export const selectAppLocale = (state: RootState) => state.app.locale;
export const selectAppPlatform = (state: RootState) => state.app.platform;
export const selectAppTooltips = (state: RootState) => state.app.tooltips;
export const { setAppLocale, setAppPlatform, setAppPlatformVersion } = appSlice.actions;
export default appSlice.reducer;
