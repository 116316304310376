import styled from '@emotion/styled';
import { FreeSpinGame, IGetAdmiralUserBonuses } from '@features/freespin/api/freespin.type';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { addHours } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FreeSpinCountdown from './MyFreeSpinCountdown';

const ICard = styled(Box)`
  &:hover {
    background-color: #202224;
  }
`;

interface FreeSpinCardProps {
  data: IGetAdmiralUserBonuses;
  onClick: (data: { bonusData: IGetAdmiralUserBonuses; games: FreeSpinGame[] }) => void;
  onDelete: (id: number) => void;
}

const FreeSpinCard: React.FC<FreeSpinCardProps> = ({ data, onClick, onDelete }) => {

  const [ completed, setCompleted ] = useState(false);
  const countDownDate = addHours(new Date(data.createdAt * 1000), data.wageringTime);

  const handleCompleted = useCallback(() => {
    setCompleted(true);
  }, []);

  const handleClick = useCallback(() => {
    onClick({
      bonusData: data,
      games: data.freeSpinGames,
    });
  }, [ onClick, data ]);

  const handleDelete = useCallback(() => {
    onDelete(data.id);
  }, [ onDelete, data.id ]);

  if (completed) {
    return null;
  }

  return (
    <ICard textAlign="center" bgcolor="#1b1c1d" pt={3} pb={2} borderRadius={2}>
      <Typography variant="h5" className="freespin-card-title mtb">
        {data.title}
      </Typography>
      <Box mt={1}>
        <Typography fontSize={'1em'}>
          <FormattedMessage id="NUMBER_OF_FREE_SPINS"/>
        </Typography>
        <Typography fontWeight={500} fontSize={'2em'} color="primary">
          {data.freeSpinCount}
        </Typography>
      </Box>
      <FreeSpinCountdown handleDisabled={handleCompleted} date={countDownDate} isView/>
      <Box mt={2}>
        <Button
          onClick={handleClick}
          disabled={completed}
          className="play"
          variant="contained"
          fullWidth
          color="primary"
        >
          <FormattedMessage id="PLAY"/>
        </Button>

        <Box mt={2}>
          <Button
            onClick={handleDelete}
            variant="text"
            color="info"
          >
            <FormattedMessage id="REFUSE"/>
          </Button>
        </Box>
      </Box>
    </ICard>
  );
};

export default FreeSpinCard;
