import IsRenderResult from '@common/components/IsRenderResult/IsRenderResult';
import { useLazyGetMoneyGateBanksQuery } from '@features/deposit/screens/money-gate/api/moneyGateApi';
import MoneyGateContainer from '@features/deposit/screens/money-gate/components/MoneyGateContainer';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface IMoneyGateResultProps {
  orderId: string;
}

const MoneyGateResult: FC<IMoneyGateResultProps> = ({ orderId }) => {
  const [ searchParams ] = useSearchParams();

  const [ getMoneyGateBanks, {
    data,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } ] = useLazyGetMoneyGateBanksQuery();

  const refetch = () => {
    getMoneyGateBanks({ orderId });
  };

  useEffect(() => {
    getMoneyGateBanks({ orderId });
  }, [ getMoneyGateBanks, orderId ]);
  return (
    <IsRenderResult
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      isError={isError}
      data={data}
      onRetry={refetch}
    >
      {data &&
				<MoneyGateContainer
					defaultBank={searchParams.get('bank')}
					defaultType={searchParams.get('type')}
					moneyGate={data} orderId={orderId}/>
      }
    </IsRenderResult>
  );
};
export default MoneyGateResult;