import TransitionZoom from '@common/components/Transitions/TransitionZoom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const AuthDialogContext = createContext<(show: boolean) => void>(null!);

export default function AuthDialogProvider(props: PropsWithChildren) {
  const navigate = useNavigate();
  const [ open, setOpen ] = useState(false);
  const toggleDialog = useCallback((show: boolean) => {
    setOpen(show);
  }, []);
  const handleClickActions = (to: string) => () => {
    navigate(to);
    setOpen(false);
  };

  return (
    <AuthDialogContext.Provider value={toggleDialog}>
      {props.children}
      <Dialog
        open={open}
        onClose={() => setOpen(true)}
        TransitionComponent={TransitionZoom}
      >
        <DialogContent sx={{ textAlign: 'center' }}>
          <FormattedMessage id="AUTH_REQUIRED"/>
        </DialogContent>
        <DialogActions>
          <Button
            color="warning"
            variant="contained"
            onClick={handleClickActions('sign-in')}
            fullWidth
          >
            <FormattedMessage id="SIGNIN"/>
          </Button>
          <Button
            color="warning"
            variant="outlined"
            onClick={handleClickActions('sign-up')}
            fullWidth
          >
            <FormattedMessage id="SIGNUP"/>
          </Button>
        </DialogActions>
      </Dialog>
    </AuthDialogContext.Provider>
  );
}
