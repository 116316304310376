import { commonApi } from '@common/utils/baseQuery';
import appReducer from '@features/app/slice/appSlice';
import authReducer from '@features/auth/slice/authSlice';
import personalPopupReducer from '@features/banners/slice/personalPopupSlice';
import basketReducer from '@features/basket/slice/basketSlice';
import campaignsReducer from '@features/campaigns/slice/campaignsSlice';
import forecastReducer from '@features/forecast-euro/slice/forecastSlice';
import admiralCampaignsReducer from '@features/freespin/slice/admiralCampaignsSlice';

import liveReducer from '@features/live/slice/liveSlice';
import messagesReducer from '@features/messages/slice/messagesSlice';
import admiralUserBonusesReducer from '@features/my-bonuses/slice/admiralUserBonusesSlice';
import userBonusCardsReducer from '@features/my-bonuses/slice/userBonusCardsSlice';
import oddsReducer from '@features/odds-templates/slice/oddsTemplatesSlice';
import { surveyApi } from '@features/survey/api/surveyApi';
import translationsReducer from '@features/translations/slice/translationsSlice';
import verificationReducer from '@features/verification/slice/verificationSlice';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    basket: basketReducer,
    campaigns: campaignsReducer,
    forecast: forecastReducer,
    admiralCampaigns: admiralCampaignsReducer,
    admiralUserBonuses: admiralUserBonusesReducer,
    userBonusCards: userBonusCardsReducer,
    live: liveReducer,
    messages: messagesReducer,
    oddsSchema: oddsReducer,
    personalPopup: personalPopupReducer,
    translations: translationsReducer,
    verification: verificationReducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [surveyApi.reducerPath]: surveyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      commonApi.middleware,
      surveyApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
