import { BackdropContext } from '@common/components/backdrop/BackdropContext';
import BackdropLoader from '@common/components/backdrop/BackdropLoader';
import Backdrop from '@mui/material/Backdrop';
import type { PropsWithChildren } from 'react';
import React, { useCallback, useState } from 'react';

export default function BackdropProvider(props: PropsWithChildren) {
  const [ statusPooling, setStatusPooling ] = useState<Record<string, boolean>>({});
  const open = Object.values(statusPooling).some((status) => status);
  const toggleBackdrop = useCallback((open: boolean, key: string) => {
    setStatusPooling((state) => {
      if (open) {
        state[key] = open;
      } else {
        if (key in state) {
          delete state[key];
        }
      }
      return { ...state };
    });
  }, []);

  return (
    <BackdropContext.Provider value={toggleBackdrop}>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={open}
      >
        <BackdropLoader/>
      </Backdrop>
      {props.children}
    </BackdropContext.Provider>
  );
}
