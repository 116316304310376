import styled from '@emotion/styled';
import React from 'react';
import { IconContainer } from './styled';

const Icon = styled(IconContainer)`
  display: flex;
  border-color: #a5dc86;
  color: #a5dc86;

  [class^=circular-line] {
    position: absolute;
    width: 3.75em;
    height: 7.5em;
    transform: rotate(45deg);
    border-radius: 50%;
  }

  [class^=circular-line][class$=left] {
    top: -0.4375em;
    left: -2.0635em;
    transform: rotate(-45deg);
    transform-origin: 3.75em 3.75em;
    border-radius: 7.5em 0 0 7.5em;
  }

  [class^=circular-line][class$=right] {
    top: -0.6875em;
    left: 1.875em;
    transform: rotate(-45deg);
    transform-origin: 0 3.75em;
    border-radius: 0 7.5em 7.5em 0;
  }

  .ring {
    position: absolute;
    z-index: 2;
    top: -0.25em;
    left: -0.25em;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    border: 0.25em solid rgba(165, 220, 134, 0.3);
    border-radius: 50%;
  }

  .fix {
    position: absolute;
    z-index: 1;
    top: 0.5em;
    left: 1.625em;
    width: 0.4375em;
    height: 5.625em;
    transform: rotate(-45deg);
  }

  [class^=line] {
    display: block;
    position: absolute;
    z-index: 2;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #a5dc86;
  }

  [class^=line][class$=tip] {
    top: 2.875em;
    left: 0.8125em;
    width: 1.5625em;
    transform: rotate(45deg);
  }

  [class^=line][class$=long] {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
    transform: rotate(-45deg);
  }

  .line-tip {
    animation: animate-line-tip 0.75s;
  }

  .line-long {
    animation: animate-line-long 0.75s;
  }

  .circular-line-right {
    animation: rotate-circular-line 4.25s ease-in;
  }

  @keyframes animate-line-tip {
    0% {
      top: 1.1875em;
      left: 0.0625em;
      width: 0;
    }
    54% {
      top: 1.0625em;
      left: 0.125em;
      width: 0;
    }
    70% {
      top: 2.1875em;
      left: -0.375em;
      width: 3.125em;
    }
    84% {
      top: 3em;
      left: 1.3125em;
      width: 1.0625em;
    }
    100% {
      top: 2.8125em;
      left: 0.8125em;
      width: 1.5625em;
    }
  }

  @keyframes animate-line-long {
    0% {
      top: 3.375em;
      right: 2.875em;
      width: 0;
    }
    65% {
      top: 3.375em;
      right: 2.875em;
      width: 0;
    }
    84% {
      top: 2.1875em;
      right: 0;
      width: 3.4375em;
    }
    100% {
      top: 2.375em;
      right: 0.5em;
      width: 2.9375em;
    }
  }

  @keyframes rotate-circular-line {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }
`;

export default function SuccessIcon({ bgColor = '#121212' }: { bgColor?: string }) {
  return (
    <Icon>
      <div className="circular-line-left" style={{ backgroundColor: bgColor }}/>
      <span className="line-tip"/> <span className="line-long"/>
      <div className="ring"/>
      <div className="fix" style={{ backgroundColor: bgColor }}/>
      <div className="circular-line-right" style={{ backgroundColor: bgColor }}/>
    </Icon>
  );
}
