import { translationsApi } from '@features/translations/api/translationsApi';
import { createSlice } from '@reduxjs/toolkit';
import { ITranslationsState } from './translationsSlice.type';

const initialState: ITranslationsState = {
  data: {},
};
const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(translationsApi.endpoints.fetchTranslations.matchFulfilled, (state, { payload }) => {
        state.data = payload;
      });
  },
});

export default translationsSlice.reducer;
