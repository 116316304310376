import SpinWheelPin from '@common/components/SpinWheel/SpinWheelPin';
import SpinWheelPointer from '@common/components/SpinWheel/SpinWheelPointer';
import Wheel from '@common/components/SpinWheel/Wheel';
import WheelCanvas from '@common/components/SpinWheel/WheelCanvas';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CONTINUE_SPINNING_TIME,
  DEFAULT_SPIN_DURATION,
  DISABLE_INITIAL_ANIMATION,
  START_SPINNING_TIME,
  STARTED_SPINNING,
  STOP_SPINNING_TIME,
  WHEEL_HEIGHT,
  WHEEL_WIDTH,
} from './constants';
import { getQuantity, getRotationDegrees, makeClassKey } from './utils';

const SpinWheelContainer = styled(Box)<{ width: number; height: number }>`
  user-select: none;
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SpinWheelProps {
  data: any[];
  width?: number;
  height?: number;
  color?: number;
  prizeNumber: number;
  mustStartSpinning?: boolean;
  onStopSpinning?: () => void;
  spinDuration?: number;
  startingOptionIndex?: number;
  disableInitialAnimation?: boolean;
}

const SpinWheel: React.FC<SpinWheelProps> = ({
  data,
  width = WHEEL_WIDTH,
  height = WHEEL_HEIGHT,
  color = 0,
  prizeNumber,
  mustStartSpinning,
  onStopSpinning = () => null,
  spinDuration = DEFAULT_SPIN_DURATION,
  startingOptionIndex = -1,
  disableInitialAnimation = DISABLE_INITIAL_ANIMATION,
}) => {
  const [ wheelData, setWheelData ] = useState([ ...data ]);
  const [ prizeMap, setPrizeMap ] = useState([ [ 0 ] ]);
  const [ startRotationDegrees, setStartRotationDegrees ] = useState(0);
  const [ finalRotationDegrees, setFinalRotationDegrees ] = useState(0);
  const [ hasStartedSpinning, setHasStartedSpinning ] = useState(false);
  const [ hasStoppedSpinning, setHasStoppedSpinning ] = useState(false);
  const [ isCurrentlySpinning, setIsCurrentlySpinning ] = useState(false);
  const [ isDataUpdated, setIsDataUpdated ] = useState(false);
  const mustStopSpinning = useRef(false);
  const classKey = makeClassKey(5);
  const normalizedSpinDuration = Math.max(0.01, spinDuration);
  const startSpinningTime = START_SPINNING_TIME * normalizedSpinDuration;
  const continueSpinningTime = CONTINUE_SPINNING_TIME * normalizedSpinDuration;
  const stopSpinningTime = STOP_SPINNING_TIME * normalizedSpinDuration;
  const totalSpinningTime = startSpinningTime + continueSpinningTime + stopSpinningTime;

  const startSpinning = useCallback(() => {
    setHasStartedSpinning(true);
    setHasStoppedSpinning(false);
    mustStopSpinning.current = true;
    setTimeout(() => {
      if (mustStopSpinning.current) {
        mustStopSpinning.current = false;
        setHasStartedSpinning(false);
        setHasStoppedSpinning(true);
        onStopSpinning();
      }
    }, totalSpinningTime);
  }, [ onStopSpinning, totalSpinningTime ]);

  const setStartingOption = useCallback(
    (optionIndex: number, optionMap: number[][]) => {
      if (startingOptionIndex >= 0) {
        const idx = Math.floor(optionIndex) % optionMap?.length;
        const startingOption = optionMap[idx][Math.floor(optionMap[idx]?.length / 2)];
        setStartRotationDegrees(getRotationDegrees(startingOption, getQuantity(optionMap), false));
      }
    },
    [ startingOptionIndex ],
  );

  const getRouletteClass = () => {
    if (hasStartedSpinning) {
      return STARTED_SPINNING;
    }
    return '';
  };

  useEffect(() => {
    let initialMapNum: number = 0; // Explicitly specify the type as number
    const auxPrizeMap: number[][] = [];
    const dataLength = data?.length || 0;
    const wheelDataAux: { option: string }[] = [ { option: '' } ]; // Specify the type of wheelDataAux
    for (let i = 0; i < dataLength; i++) {
      wheelDataAux[i] = { ...data[i] };
      auxPrizeMap.push([]);
      for (let j = 0; j < 1; j++) {
        auxPrizeMap[i][j] = initialMapNum++;
      }
    }
    setWheelData([ ...wheelDataAux ]);
    setPrizeMap(auxPrizeMap);
    setStartingOption(startingOptionIndex, auxPrizeMap);
    setIsDataUpdated(true);
  }, [ data, setStartingOption, startingOptionIndex ]);

  useEffect(() => {
    if (mustStartSpinning && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true);
      startSpinning();
      const selectedPrizes = prizeMap[prizeNumber];

      // Check if selectedPrizes is defined
      if (selectedPrizes && selectedPrizes.length > 0) {
        const randomIndex = Math.floor(Math.random() * selectedPrizes.length);
        const selectedPrize = selectedPrizes[randomIndex];
        const finalRotationDegreesCalculated = getRotationDegrees(
          selectedPrize,
          getQuantity(prizeMap),
        );
        setFinalRotationDegrees(finalRotationDegreesCalculated);
      }
    }
  }, [ mustStartSpinning, prizeMap, prizeNumber, isCurrentlySpinning, startSpinning ]);

  useEffect(() => {
    if (hasStoppedSpinning) {
      setIsCurrentlySpinning(false);
      setStartRotationDegrees(finalRotationDegrees);
    }
  }, [ hasStoppedSpinning ]);

  if (!isDataUpdated) {
    return null;
  }

  return (
    <SpinWheelContainer width={width} height={height}>
      <SpinWheelPointer/>
      <Wheel
        wheelColor={color}
        classKey={classKey}
        className={getRouletteClass()}
        stopSpinningTime={stopSpinningTime}
        startSpinningTime={startSpinningTime}
        continueSpinningTime={continueSpinningTime}
        startRotationDegrees={startRotationDegrees}
        finalRotationDegrees={finalRotationDegrees}
        disableInitialAnimation={disableInitialAnimation}>
        <WheelCanvas data={wheelData} color={color} width={width} height={height}/>
      </Wheel>
      <SpinWheelPin/>
    </SpinWheelContainer>
  );
};

export default SpinWheel;
