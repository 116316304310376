import styled from '@emotion/styled';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import React, { ElementType } from 'react';

const Typography = styled(MuiTypography)`
  * {
    font-family: inherit !important;
    font-size: 14px !important;
    color: white !important;
    background-color: inherit !important;
  }

  a {
    color: ${props => props.theme.palette.info.dark};
  }

  p {
    margin-top: 0;
    margin-bottom: 0.35em;

    &[data-f-id=pbf] {
      display: none;
    }
  }

  &.content {
    strong {
      color: ${props => props.theme.palette.warning.main};
    }

    ul, ol {
      list-style: none;
    }

    hr {
      border: 0;
      border-top: 1px solid rgba(255, 255, 255, .1);
      margin: 22px 0;
    }

    a {
      color: #cccccc;

      &:hover {
        color: ${props => props.theme.palette.warning.main};
      }
    }
  }

  &.campaign {
    p {
      margin-bottom: 0;
    }

    span {
      font-size: inherit !important;
    }

    strong {
      color: ${props => props.theme.palette.warning.main};
    }

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        background: #545454;
        color: #dadada
      }

      tbody {
        background: #353535
      }

      td, th {
        border: 1px solid #4a4a4a;
        padding: 4px 0;
        width: 20%;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default function FroalaTypography<C extends ElementType>(props: TypographyProps<C>) {
  return <Typography color="#ccc" variant="body1" {...props}/>;
}
