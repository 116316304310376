import styled from '@emotion/styled';
import React from 'react';
import { IconContainer } from './styled';

const Icon = styled(IconContainer)`
  color: #fee900;
  animation: animate-error-icon 0.5s;

  @keyframes animate-error-icon {
    0% {
      transform: rotateX(100deg);
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  font-size: 3.75em;
  animation: animate-i-mark 0.5s;

  @keyframes animate-i-mark {
    0% {
      transform: rotateZ(45deg);
      opacity: 0;
    }
    25% {
      transform: rotateZ(-25deg);
      opacity: 0.4;
    }
    50% {
      transform: rotateZ(15deg);
      opacity: 0.8;
    }
    75% {
      transform: rotateZ(-5deg);
      opacity: 1;
    }
    100% {
      transform: rotateX(0);
      opacity: 1;
    }
  }
`;

export default function InfoIcon() {
  return (
    <Icon>
      <Content>!</Content>
    </Icon>
  );
}
