import { formatFieldError } from '@common/components/Form/formatFieldError';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { ReactNode } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';

export interface IFormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TFieldName> {
  type?: TextFieldProps['type'];
  label?: ReactNode;
  disabled?: TextFieldProps['disabled'];
  placeholder?: TextFieldProps['placeholder'];
  TextFieldProps?: Omit<TextFieldProps, 'name' | 'inputRef' | 'value' | 'defaultValue'>;
}

export default function FormTextField<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>
({ type, label, disabled, placeholder, TextFieldProps, ...controllerProps }: IFormTextFieldProps<TFieldValues, TFieldName>) {
  const { field, fieldState } = useController(controllerProps);
  const value = (field.value === undefined || field.value === null) ? '' : field.value;
  const error = fieldState.error?.message && formatFieldError(fieldState.error.message);

  return (
    <TextField
      sx={{ minHeight: '78px' }}
      type={type}
      name={field.name}
      value={value}
      label={label}
      error={!!error}
      onBlur={field.onBlur}
      onChange={field.onChange}
      inputRef={field.ref}
      fullWidth
      helperText={error}
      placeholder={placeholder}
      disabled={disabled}
      {...TextFieldProps}
    />
  );
}
