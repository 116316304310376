import SpinWheel from '@common/components/SpinWheel/SpinWheel';
import styled from '@emotion/styled';
import { IFortuneWheelCampaign } from '@features/freespin/api/freespin.type';
import MyFortuneWheelSelector from '@features/my-bonuses/fortune/components/MyFortuneWheelSelector';
import Button from '@mui/material/Button';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';

interface FortuneWheelProps {
  data: IFortuneWheelCampaign[];
  mustSpin: boolean;
  prizeNumber?: any;
  onStartSpinning: (wheel: IFortuneWheelCampaign) => void;
  onStopSpinning: () => void;
}

const StyledButton = styled(Button)`

  && {
    transform: scale(1.5);
    margin-block: 4em;
    height: 32px;
    padding: 0 15px;
    color: #0b0b0b;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    text-transform: uppercase;
    background: #ff7f24;

    &:hover {
      background: #b65510;
    }
  }
`;

const MyFortuneWheel: FC<FortuneWheelProps> = ({
  data = [],
  mustSpin,
  prizeNumber,
  onStartSpinning,
  onStopSpinning,
}) => {
  const [ wheel, setWheel ] = useState<IFortuneWheelCampaign | undefined>();

  const handleSelectWheel = useCallback((value: IFortuneWheelCampaign) => {
    setWheel(value);
  }, []);

  useEffect(() => {
    if (wheel === undefined && data.length) {
      setWheel(data[0]);
    }
  }, [ data, wheel ]);

  if (wheel === undefined) {
    return null;
  }

  const wheelOptions = wheel.prizes.map((item) => ({ option: item.type.toUpperCase() }));

  return (
    <Fragment>

      <MyFortuneWheelSelector data={data} value={wheel.id} disabled={mustSpin} onChange={handleSelectWheel}/>

      {prizeNumber !== undefined && (

        <SpinWheel
          data={wheelOptions}
          color={wheel.type}
          width={500}
          height={500}
          prizeNumber={prizeNumber}
          onStopSpinning={onStopSpinning}
          mustStartSpinning={mustSpin}
        />
      )}
      {!!wheel?.total && (
        <StyledButton onClick={() => onStartSpinning(wheel)} disabled={mustSpin}>
          Крутить
        </StyledButton>
      )}

    </Fragment>
  );
};

export default MyFortuneWheel;