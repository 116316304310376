import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: { id: 'REQUIRED' },
  },
  string: {
    min: ({ min }) => ({ id: 'MIN_LENGTH', values: { min } }),
    max: ({ max }) => ({ id: 'MAX_LENGTH', values: { max } }),
    length: ({ length }) => ({ id: 'EXACT_LENGTH', values: { length } }),
    email: { id: 'IS_EMAIL_ERROR' },
    matches: { id: 'INVALID_CHARACTERS' },
  },
});
